import { css } from '@emotion/react';
import { themed } from '../utils/styling/themed';
import { margins } from '../utils/styling/utilityClasses';
import { cornerRadii, paddings } from '../utils/styling/utilityTokens';

export const base = themed(
  ({ palette }) => css`
    background-color: ${palette.surface.inverse};
    box-shadow: ${palette.shadow.componentShadow};
    ${cornerRadii.modalStandard}
    width: auto;
  `,
);

export const toast_base = themed(
  ({ palette, typography }) => css`
    background-color: ${palette.surface.inverse};
    ${paddings.y.comfortable}
    ${paddings.x.comfortable}
      position: relative;
    min-width: 320px;
    max-width: 790px;

    border: none;
    ${cornerRadii.modalStandard}

    .MuiAlertTitle-root {
      color: ${palette.text.inverse};
      ${typography.body1.default}
      ${typography.body1.semibold}
      /** Override Mui's default margin-top: -2px **/
      ${margins.y.m0}
    }

    .BlocksToast-message {
      color: ${palette.text.inverse};
      ${typography.body1.default}
    }

    .MuiAlert-icon {
      display: inline-block;
      ${paddings.y.none}
    }

    .MuiAlert-action {
      ${paddings.yt.none}
    }

    .MuiAlert-message {
      ${paddings.y.none}
    }
  `,
);

export const fade_in = css`
  animation: toast-fadein 0.1s ease-out forwards;

  @keyframes toast-fadein {
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const fade_out = css`
  animation: toast-fadeout 0.1s ease-in forwards;

  @keyframes toast-fadeout {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.9);
      opacity: 0;
    }
  }
`;

export const warning_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.warningInverse};
    }
  `,
);

export const error_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.errorInverse};
    }
  `,
);

export const success_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.successInverse};
    }
  `,
);

export const info_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.infoLinkInverse};
    }
  `,
);

export const subtle_toast = themed(
  ({ palette }) => css`
    .MuiAlert-icon {
      color: ${palette.icon.subtleInverse};
    }

    .BlocksToast-icon {
      animation: toast-spin 1s linear infinite;

      @keyframes toast-spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  `,
);

export const progress_container = (duration: number, variant: string) =>
  themed(({ palette }) => {
    const variantColors: Record<string, string> = {
      warning: palette.border.warningInverse,
      error: palette.border.errorInverse,
      success: palette.border.successInverse,
      info: palette.border.infoInverse,
      subtle: palette.border.inverseSubtle,
      default: palette.border.infoInverse, // Fallback color
    };

    const backgroundColor = variantColors[variant] || variantColors.default;

    return css`
      width: calc(100% - 12px);
      left: 6px;
      position: absolute;
      height: 4px;
      z-index: 2;
      clip-path: inset(0 0 calc(100% - 10px) 0 round 16px 0 0 16px);
      background: ${backgroundColor};
      animation: shrinkBorder ${duration}ms linear forwards;
      &:hover::before {
        animation-play-state: paused;
      }
      @keyframes shrinkBorder {
        0% {
          width: 0%;
        }
        90% {
          clip-path: inset(0 0 calc(100% - 10px) 0 round 16px 0 0 16px);
        }
        100% {
          width: calc(100% - 12px);
          clip-path: inset(0 0 calc(100% - 10px) 0 round 16px);
        }
      }
    `;
  });
