import first from 'lodash/first';
import last from 'lodash/last';
// import { AirItemType, TransactionAncillaryType, TransactionType } from '@spotnana/types/openapi/models';
import type { AirFlight } from '@spotnana/types/openapi/models/air-flight';
import type { AirItem } from '@spotnana/types/openapi/models/air-item';
import type { TransactionItem } from '@spotnana/types/openapi/models/transaction-item';
import type { FlightDetailInformation } from '@spotnana/types/openapi/models/flight-detail-information';
import type { ScheduleChangeInfo } from '@spotnana/types/openapi/models/schedule-change-info';
import { AirItemType } from '@spotnana/types/openapi/models/air-item-type';
import { TransactionAncillaryType } from '@spotnana/types/openapi/models/transaction-ancillary-type';
import { TransactionType } from '@spotnana/types/openapi/models/transaction-type';

import uniq from 'lodash/uniq';
import type { TicketAncillaryAncillaryTypeEnum } from '../../../types/api/v2/obt/model/ticket-ancillary';
import { defineCommonMessage } from '../../../translations/defineMessage';

import { PassengerType } from '../../../types/api/v2/obt/model/passenger-type';
import type { Air } from '../../../types/api/v2/obt/model/air';
import type { PnrV3ManagerProps } from '../PnrV3Manager';
import { PnrV3Manager } from '../PnrV3Manager';
import { createUserNameFromFullName, formatTerminalName, getNameStringFromName } from '../../../utils/common';
import { TicketStatusEnum, TicketTicketTypeEnum } from '../../../types/api/v2/obt/model/ticket';
import { getAirlineNameFromAirlineCode, getLocationDetailsFromAirportCode } from '../../../utils/Flights';
import minutesToDurationString from '../../../date-utils/minutesToDurationString';
import getDateTimeDiff from '../../../date-utils/getDateTimeDiff';
import getDurationString from '../../../date-utils/getDurationString';
import getDurationMinutes from '../../../date-utils/getDurationMinutes';
import type { Flight } from '../../../types/api/v2/obt/model/flight';
import type { PnrDataAdditionalMetadata } from '../../../types/api/v2/obt/model/pnr-data-additional-metadata';
import type { LocationInfo } from './types';
import type { TravelerInfo } from '../../../types/api/v2/obt/model/traveler-info';
import type { Ticket } from '../../../types/api/v2/obt/model/ticket';
import { MoneyUtil } from '../../../utils/Money';
import { AncillaryType } from '../../../types/api/v2/obt/model/ancillary-type';
import type { Transaction } from '../../../types/api/v2/obt/model/transaction';
import { UserFacingStatus } from '../../../types/api/v2/obt/model/user-facing-status';
import type { Cabin } from '../../../types/api/v2/obt/model/cabin';
import convertDateFormat from '../../../date-utils/convertDateFormat';
import { dateFormats } from '../../../constants/common';
import { getDiff, getTodayDateTimeInTimezone } from '../../../date-utils';
import type { PnrTransaction } from '../types';

export class AirPnrV3Manager extends PnrV3Manager {
  airPnr?: Air;

  constructor({ pnrData, pnrId }: PnrV3ManagerProps) {
    super({ pnrData, pnrId });
    this.airPnr = this.pnrData.airPnr;
  }

  private getOriginLocationInfo(
    flight: Flight,
    flightAirlineMetadata: PnrDataAdditionalMetadata | undefined,
  ): LocationInfo {
    const airportInfo = getLocationDetailsFromAirportCode(flight.origin, flightAirlineMetadata?.airportInfo ?? []);
    return {
      ...airportInfo,
      date: flight.departureDateTime,
      terminal: formatTerminalName(flight.departureGate?.terminal),
    };
  }

  private getDestinationLocationInfo(
    flight: Flight,
    flightAirlineMetadata: PnrDataAdditionalMetadata | undefined,
  ): LocationInfo {
    const airportInfo = getLocationDetailsFromAirportCode(flight.destination, flightAirlineMetadata?.airportInfo ?? []);
    return {
      ...airportInfo,
      date: flight.arrivalDateTime,
      terminal: formatTerminalName(flight.arrivalGate?.terminal),
    };
  }

  private destinationCityName() {
    const { airPnr } = this;

    const lastFlightoftheLeg = last(last(airPnr?.legs)?.flights);
    const airportCode = lastFlightoftheLeg?.destination ?? '';

    const airportInfo = getLocationDetailsFromAirportCode(
      airportCode,
      this.pnrData.additionalMetadata?.airportInfo ?? [],
    );

    return airportInfo.cityName;
  }

  public sortedLegs() {
    return this.pnrData.airPnr?.legs.sort((leg) => leg.sortingPriority || 0) ?? [];
  }

  public getInitialDepartureDateTimeISO() {
    return first(first(this.sortedLegs())?.flights)?.departureDateTime.iso8601 || '';
  }

  public cancelationNumbers() {
    const travelerInfos = this.airPnr?.travelerInfos ?? [];
    const cancelationNumbers: string[] = [];
    travelerInfos.forEach((travelerInfo) => {
      const tickets = travelerInfo.tickets ?? [];
      tickets.forEach((ticket) => {
        if (ticket.vendorCancellationId) {
          cancelationNumbers.push(ticket.vendorCancellationId);
        }
      });
    });
    return cancelationNumbers;
  }

  public airTravelerDetails() {
    const travelers = this.pnrData.pnrTravelers ?? [];
    return travelers.map((travelerInfo, travelerInd) => {
      const airPnrTravelerInfo = this.airPnr?.travelerInfos ? this.airPnr?.travelerInfos[travelerInd] : undefined;
      const tickets = airPnrTravelerInfo?.tickets;
      return {
        name: getNameStringFromName(travelerInfo.personalInfo?.name),
        preferredName: getNameStringFromName(travelerInfo.personalInfo?.name, { usePreferredName: true }),
        travelerInfo,
        airPnrTravelerInfo,
        tsaNumber:
          travelerInfo.personalInfo?.identityDocs?.find((doc) => Object.keys(doc).includes('ktn'))?.ktn?.number ?? '-',
        id: travelerInfo.userId.id,
        loyaltyId:
          travelerInfo.loyalties
            ?.map((l) => {
              return `${l.issuedBy} #${l.id}`;
            })
            .join(', ') ?? '',
        tickets,
        otherServiceInfos: this.pnrData.airPnr?.otherServiceInfos,
        /**
         * @deprecated
         * user 'tickets' property
         */
        ticketNumbers: tickets?.map((ticket) => ticket.ticketNumber),
      };
    });
  }

  public hasTicketForLegIndex(legIndex: number): boolean {
    const primaryTravelerInfo = first(this.airPnr?.travelerInfos);
    const tickets = primaryTravelerInfo?.tickets || [];
    const flightTickets = tickets.filter((ticket) => ticket.ticketType === TicketTicketTypeEnum.Flight);

    if (flightTickets.length === 0) {
      return false;
    }

    return flightTickets.some((ticket) => {
      return (
        ticket.flightCoupons?.some((coupon) => coupon.legIdx === legIndex) && ticket.status === TicketStatusEnum.Issued
      );
    });
  }

  public getPnrScheduleSummary() {
    const legs = this.sortedLegs();
    const firstLeg = first(legs);
    const lastLeg = last(legs);

    const dateTimeStart = first(firstLeg?.flights)?.departureDateTime.iso8601;
    const dateTimeEnd = last(lastLeg?.flights)?.arrivalDateTime.iso8601;

    const originInfo = this.getOriginLocationInfo(first(firstLeg?.flights)!, this.pnrData.additionalMetadata);
    const destinationInfo = this.getDestinationLocationInfo(last(lastLeg?.flights)!, this.pnrData.additionalMetadata);

    return {
      dateTimeStart,
      dateTimeEnd,
      originInfo,
      destinationInfo,
    };
  }

  public getIssuedTicketNumbers(traveler: TravelerInfo, flightIdx: number) {
    return (
      traveler.tickets
        ?.filter(
          (ticket) =>
            ticket.status === TicketStatusEnum.Issued &&
            Boolean(ticket.flightCoupons?.find((coupon) => coupon.flightIdx === flightIdx)),
        )
        ?.map((ticket) => ticket.ticketNumber) || []
    );
  }

  public getPassengerDetails(flightIdx: number, legIdx: number) {
    const travelerInfos = this.pnrData.airPnr?.travelerInfos ?? [];
    const pnrTravelers = this.pnrData.pnrTravelers ?? [];
    return travelerInfos.map((traveler) => {
      const userId = traveler.userId?.id;
      const requiredPnrTraveler = pnrTravelers.find((pnrTraveler) => pnrTraveler.userId.id === userId);
      const seat = traveler.booking.seats?.find((seat) => seat.legIdx === legIdx && seat.flightIdx === flightIdx);
      const seatNumber = seat?.number;
      const travelerName = getNameStringFromName(requiredPnrTraveler?.personalInfo?.name);
      const ticketNumbers = this.getIssuedTicketNumbers(traveler, flightIdx);

      const frequentFlyer =
        requiredPnrTraveler?.loyalties?.map((loyalty) => ({
          id: loyalty.id,
          issuedBy: loyalty.issuedBy,
        })) ?? [];

      const tsaNumber = requiredPnrTraveler?.personalInfo?.identityDocs?.find((doc) => Object.keys(doc).includes('ktn'))
        ?.ktn?.number;

      const requiredSsr = traveler.specialServiceRequestInfos?.filter((ssr) => ssr.flightIndex === flightIdx);
      const specialServiceRequests =
        requiredSsr?.map((ssr) => ({
          name: ssr.code, // We need name here
          status: ssr.status,
          description: ssr.customText,
        })) ?? [];

      return {
        userId,
        seat: {
          number: seat?.number,
          status: seat?.status,
          sourceStatus: seat?.sourceStatus,
        },
        /**
         * @deprecated, use seat.number
         */
        seatNumber,
        ticketNumbers,
        travelerName,
        frequentFlyer,
        tsaNumber,
        specialServiceRequests,
      };
    });
  }

  private getLayoverInfo(sortedLegIdx: number, flightIdx: number) {
    const leg = this.sortedLegs()[sortedLegIdx];
    const flight = leg.flights[flightIdx];
    const flightAirlineMetadata = this.pnrData.additionalMetadata;
    if (flightIdx < leg.flights.length - 1) {
      const durationTime = getDateTimeDiff(
        leg.flights[flightIdx + 1].departureDateTime.iso8601,
        flight.arrivalDateTime.iso8601,
      );
      return {
        code: getLocationDetailsFromAirportCode(flight.destination, flightAirlineMetadata?.airportInfo ?? []).code,
        city: getLocationDetailsFromAirportCode(flight.destination, flightAirlineMetadata?.airportInfo ?? []).cityName,
        duration: minutesToDurationString(durationTime),
        durationTime,
      };
    }
    return undefined;
  }

  private getAircraftDetails(sortedLegIdx: number, flightIdx: number) {
    const leg = this.sortedLegs()[sortedLegIdx];
    const flight = leg.flights[flightIdx];
    const flightAirlineMetadata = this.pnrData.additionalMetadata;
    return {
      marketingAirline:
        getAirlineNameFromAirlineCode(flight.marketing.airlineCode, flightAirlineMetadata?.airlineInfo ?? []) ?? '',
      airlineNumber: flight.marketing.num ?? '',
      operatingAirline:
        getAirlineNameFromAirlineCode(flight.operating.airlineCode, flightAirlineMetadata?.airlineInfo ?? []) ?? '',
      confirmationNumber: flight.vendorConfirmationNumber ?? '',
      bookingId: this.pnrId,
      cabin: this.cabinTypeToName(flight.cabin),
      aircraft: flight.equipment?.name ?? '',
      co2Emissions: (flight.co2EmissionDetail?.emissionValue || 0) * 1000,
      bookingCode: flight.bookingCode ?? '',
    };
  }

  private getLegCo2EmissionsInKg(sortedLegIdx: number) {
    const leg = this.sortedLegs()[sortedLegIdx];
    const co2EmissionsPerFlight = leg.flights.map((flight) => flight.co2EmissionDetail?.emissionValue);
    const co2Sum = co2EmissionsPerFlight.reduce((acc, curr) => (acc || 0) + (curr || 0), 0);
    const co2EmissionsInKg =
      !co2Sum || co2EmissionsPerFlight.some((co2EmissionValue) => co2EmissionValue === undefined)
        ? undefined
        : co2Sum * 1000;
    return co2EmissionsInKg ? Math.round(co2EmissionsInKg) : undefined;
  }

  private getFlightCo2EmissionsInKg(sortedLegIdx: number, flightIdx: number) {
    const flight = this.sortedLegs()[sortedLegIdx].flights[flightIdx];
    const co2Emissions = flight.co2EmissionDetail?.emissionValue ?? 0;
    const co2EmissionsInKg = co2Emissions * 1000;
    return Math.round(co2EmissionsInKg);
  }

  public getTotalCo2EmissionsInKg(legIdx: number, flightIdx?: number) {
    const totalTravelerForCo2Emission = (this.airPnr?.travelerInfos ?? []).filter(
      (traveler) => traveler.paxType !== PassengerType.InfantOnLap,
    ).length;

    const co2EmissionsInKg =
      flightIdx !== undefined ? this.getFlightCo2EmissionsInKg(legIdx, flightIdx) : this.getLegCo2EmissionsInKg(legIdx);

    return co2EmissionsInKg ? totalTravelerForCo2Emission * co2EmissionsInKg : undefined;
  }

  public airlineReference() {
    const allAirlineReferences = this.airPnr?.legs?.flatMap((leg) =>
      leg.flights.map((flight) => flight.vendorConfirmationNumber),
    );
    return [...new Set(allAirlineReferences)].join(', ');
  }

  public airDetails() {
    const flightAirlineMetadata = this.pnrData.additionalMetadata;

    const legsDetails = this.sortedLegs().map((leg, legIdx) => {
      const startDate = first(leg.flights)?.departureDateTime;
      const endDate = first(leg.flights)?.arrivalDateTime;
      let durationMinutes = 0;
      const scheduleChangeDetails = leg.scheduleChangeInfo;

      const flightDetails = leg.flights.map((flight, flightIdx) => {
        const aircraftDetails = this.getAircraftDetails(legIdx, flightIdx);
        const passengerDetails = this.getPassengerDetails(flightIdx, legIdx);
        durationMinutes += getDurationMinutes(flight.duration?.iso8601 ?? '');

        const layover = this.getLayoverInfo(legIdx, flightIdx);

        if (layover) {
          durationMinutes += getDateTimeDiff(
            leg.flights[flightIdx + 1].departureDateTime.iso8601,
            flight.arrivalDateTime.iso8601,
          );
        }
        const originalFlight: FlightDetailInformation | undefined = this.getOriginalFlight(
          scheduleChangeDetails,
          flightIdx,
        );

        const co2EmissionsInKg = this.getTotalCo2EmissionsInKg(legIdx, flightIdx);

        const flightTicket = this.getFlightTicket({ legIdx, flightIdx });

        const isRefundable = flightTicket?.refundPolicy?.isRefundable;
        const isExchangeable = flightTicket?.exchangePolicy?.isExchangeable;
        const exchangePenalty = flightTicket?.exchangePolicy?.exchangePenalty;

        return {
          aircraftDetails,
          duration: getDurationString(flight.duration?.iso8601 ?? ''),
          airlineCode: flight.marketing.airlineCode,
          airlineNumber: flight.marketing.num,
          layover,
          origin: this.getOriginLocationInfo(flight, flightAirlineMetadata),
          destination: this.getDestinationLocationInfo(flight, flightAirlineMetadata),
          passengerDetails,
          originalFlight,
          co2EmissionsInKg,
          bookingStatus: leg.legStatus,
          isPnrOutOfPolicy: this.isPnrOutOfPolicy(),
          legId: leg?.legId,
          flightId: flight.flightId,
          isRefundable,
          isExchangeable,
          exchangePenalty,
          cabinType: flight.cabin,
          travelerResrictions: leg.travelerRestrictions,
          preferences: leg.preferences,
          preferredTypes: leg.preferredTypes,
          fareOffers: leg.fareOffers,
        };
      });

      const allLegCabins = flightDetails.map((segment) => segment.aircraftDetails.cabin);

      const isMixedCabin = this.getIsMixedCabin(allLegCabins);

      const stops = (flightDetails.length ?? 1) - 1;

      let originName = '';
      let originCityName = '';
      let destinationName = '';
      let destinationCityName = '';
      let originCode = '';
      let destinationCode = '';

      if (flightDetails.length > 0) {
        originName = flightDetails[0].origin.airportName;
        originCityName = flightDetails[0].origin.cityName;
        destinationName = flightDetails[flightDetails.length - 1].destination.airportName;
        destinationCityName = flightDetails[flightDetails.length - 1].destination.cityName;
        originCode = flightDetails[0].origin.code;
        destinationCode = flightDetails[flightDetails.length - 1].destination.code;
      }

      return {
        originName,
        originCityName,
        destinationName,
        destinationCityName,
        originCode,
        destinationCode,
        startDate,
        endDate,
        flightDetails,
        duration: minutesToDurationString(durationMinutes),
        stops,
        isMixedCabin,
        fareType: leg.rateType || '',
        legId: leg?.legId,
      };
    });

    return {
      legsDetails,
      travelerDetails: this.airTravelerDetails(),
      pnrId: this.pnrId,
    };
  }

  private getOriginalFlight(scheduleChangeDetails: ScheduleChangeInfo | undefined, flightIndex: number) {
    if (
      !scheduleChangeDetails ||
      !scheduleChangeDetails.flightIndices ||
      !scheduleChangeDetails.disruptedFlightIndices ||
      !this.airPnr?.disruptedFlightDetails
    ) {
      return undefined;
    }

    const scheduleChangeIndex = scheduleChangeDetails.flightIndices.indexOf(flightIndex);
    if (scheduleChangeIndex === -1) {
      return undefined;
    }

    const disruptedFlightIndex = scheduleChangeDetails.disruptedFlightIndices[scheduleChangeIndex];
    if (disruptedFlightIndex === undefined) {
      return undefined;
    }
    return this.airPnr?.disruptedFlightDetails[disruptedFlightIndex];
  }

  public transactionDate() {
    const travelerInfos = this.airPnr?.travelerInfos;
    if (travelerInfos && travelerInfos[0].tickets && travelerInfos[0].tickets.length > 0) {
      return travelerInfos[0].tickets[0].issuedDateTime;
    }
    return undefined;
  }

  public vendorName() {
    const { legsDetails } = this.airDetails();
    const isSpotnanaMerchant = this.isSpotnanaMerchant();

    const allFlightSegments = legsDetails.flatMap((legDetails) => legDetails.flightDetails);

    const allAirCraftDetails = allFlightSegments.map((flightSegment) => flightSegment.aircraftDetails);

    const allAirlines = allAirCraftDetails.map((airCraftDetail) => airCraftDetail.marketingAirline);

    const filteredAirlines = Array.from(new Set(allAirlines));

    return isSpotnanaMerchant ? 'Spotnana' : filteredAirlines.join(', ');
  }

  public paymentDetailPerTraveler() {
    const travelerInfos = this.airPnr?.travelerInfos ?? [];

    const pnrTravelers = this.pnrData.pnrTravelers ?? [];

    const paymentDetailPerTraveler = travelerInfos.map((traveler, travelerIdx) => {
      const fare = traveler.booking.itinerary?.totalFlightsFare?.base;
      const tax = traveler.booking.itinerary?.totalFlightsFare?.tax;
      const totalfare = traveler.booking.itinerary?.totalFare?.base;
      const totalTax = traveler.booking.itinerary?.totalFare?.tax;
      const airlineFees = traveler.booking.itinerary?.totalAirlineFees;

      const travelerTotalFare = MoneyUtil.convertV2MoneyToMoneyUtil(totalfare).add(
        MoneyUtil.convertV2MoneyToMoneyUtil(totalTax),
      );

      const wifiAncillaryFare = traveler.booking.itinerary?.otherAncillaryFares?.find(
        (ancillaryFare) => ancillaryFare.type === AncillaryType.Wifi,
      )?.totalFare;
      const earlyBirdAncillaryFare = traveler.booking.itinerary?.otherAncillaryFares?.find(
        (ancillaryFare) => ancillaryFare.type === AncillaryType.EarlyBird,
      )?.totalFare;
      const carbonOffsetAncillaryFare = traveler.booking.itinerary?.otherAncillaryFares?.find(
        (ancillaryFare) => ancillaryFare.type === AncillaryType.CarbonOffset,
      )?.totalFare;

      const otherCharges = MoneyUtil.convertV2MoneyToMoneyUtil(traveler.booking.itinerary?.otherCharges);

      const wifiAncillaryFareBase = MoneyUtil.convertV2MoneyToMoneyUtil(wifiAncillaryFare?.base);
      const wifiAncillaryFareTax = MoneyUtil.convertV2MoneyToMoneyUtil(wifiAncillaryFare?.tax);

      const earlyBirdAncillaryFareBase = MoneyUtil.convertV2MoneyToMoneyUtil(earlyBirdAncillaryFare?.base);
      const earlyBirdAncillaryFareTax = MoneyUtil.convertV2MoneyToMoneyUtil(earlyBirdAncillaryFare?.tax);

      const carbonOffsetAncillaryFareBase = MoneyUtil.convertV2MoneyToMoneyUtil(carbonOffsetAncillaryFare?.base);
      const carbonOffsetAncillaryFareTax = MoneyUtil.convertV2MoneyToMoneyUtil(carbonOffsetAncillaryFare?.tax);

      const refundInfo = this.refundInfo(travelerIdx);
      const refundPenaltyBase = MoneyUtil.convertV2MoneyToMoneyUtil(refundInfo?.penalty?.base);
      const refundPenaltyTax = MoneyUtil.convertV2MoneyToMoneyUtil(refundInfo?.penalty?.tax);
      const refundPenalty = refundPenaltyBase.add(refundPenaltyTax);

      // We are not showing the ticket number which is exchanged as it is replaced by the new ticket.
      const allTravelerTickets = traveler.tickets?.filter(
        (ticket) => ticket.ticketType === TicketTicketTypeEnum.Flight && ticket.status !== TicketStatusEnum.Exchanged,
      );
      const allTicketNumbers = allTravelerTickets?.map((ticket) => ticket.ticketNumber).join(', ') ?? '';

      return {
        name: getNameStringFromName(pnrTravelers[travelerIdx].personalInfo?.name, { usePreferredName: true }),
        flightFare: MoneyUtil.convertV2MoneyToMoneyUtil(fare),
        feesAndTaxes: MoneyUtil.convertV2MoneyToMoneyUtil(tax),
        travelerTotalFare,
        seatFare: MoneyUtil.convertV2MoneyToMoneyUtil(traveler.booking.itinerary?.totalSeatFare),
        luggageFare: MoneyUtil.convertV2MoneyToMoneyUtil(traveler.booking.itinerary?.totalLuggageFare),
        wifiAncillaryFare: wifiAncillaryFareBase.add(wifiAncillaryFareTax),
        earlyBirdAncillaryFare: earlyBirdAncillaryFareBase.add(earlyBirdAncillaryFareTax),
        carbonOffsetAncillaryFare: carbonOffsetAncillaryFareBase.add(carbonOffsetAncillaryFareTax),
        airlineFees: MoneyUtil.convertV2MoneyToMoneyUtil(airlineFees),
        otherCharges,
        refundPenalty,
        allTicketNumbers,
      };
    });

    return paymentDetailPerTraveler;
  }

  public refundInfo(travelerIndex: number) {
    const travelerInfos = this.airPnr?.travelerInfos ?? [];
    const tickets = travelerInfos[travelerIndex].tickets ?? [];

    const refundedTicket = tickets.find((ticket) => ticket.status === TicketStatusEnum.Refunded);

    return refundedTicket?.refundInfo;
  }

  public cabinTypeToName(cabin: Cabin | undefined) {
    if (!cabin) {
      return '';
    }
    const filteredCabin = cabin.replace(/_/g, ' ');
    return filteredCabin.charAt(0).toUpperCase() + filteredCabin.slice(1).toLowerCase();
  }

  public getIsMixedCabin = (allCabins: string[]): boolean => {
    const cabinSet = new Set();
    allCabins.forEach((cabinType) => cabinSet.add(cabinType));
    return cabinSet.size > 1;
  };

  public isDelayedInvoicing() {
    return !!this.pnrData.invoiceDelayedBooking;
  }

  public getHoldInfo() {
    const holdDeadline = this.airPnr?.holdDeadline;

    if (!holdDeadline) {
      return { timeRemainingToAutoCancelHold: '', holdDeadlineTime: '' };
    }

    const { holdDeadline: holdDeadlineInfo } = holdDeadline;

    const formattedHoldDeadline = convertDateFormat(
      holdDeadlineInfo.iso8601,
      dateFormats.ISO,
      dateFormats.DAY_DATE_HR12_TIME,
    );

    const localCurrentDateTime = getTodayDateTimeInTimezone('UTC', dateFormats.ISO);

    const diffInHours = getDiff(
      localCurrentDateTime,
      holdDeadlineInfo.iso8601,
      'hours',
      dateFormats.ISO,
      dateFormats.ISO,
    );

    const diffInMinutes = getDiff(
      localCurrentDateTime,
      holdDeadlineInfo.iso8601,
      'minutes',
      dateFormats.ISO,
      dateFormats.ISO,
    );

    let timeRemainingToAutoCancelHold = '';
    let holdDeadlineTime = '';

    if (diffInHours >= 1) {
      timeRemainingToAutoCancelHold = `${diffInHours + 1} hours`;
    } else if (diffInHours === 0 && diffInMinutes > 0) {
      timeRemainingToAutoCancelHold = `${diffInMinutes} minutes`;
    }

    holdDeadlineTime = `${formattedHoldDeadline} UTC`;

    return { timeRemainingToAutoCancelHold, holdDeadlineTime };
  }

  public isPnrStatusCancelled() {
    return this.pnrData.bookingStatus === UserFacingStatus.CancelledStatus;
  }

  public hasExchangedTickets() {
    const { airPnr } = this;

    if (!airPnr) {
      return false;
    }

    const travelerInfos = airPnr.travelerInfos ?? [];

    const allTickets = travelerInfos.flatMap((travelerInfo) => travelerInfo.tickets ?? []);

    if (allTickets.length <= 0) {
      return false;
    }

    const allTicketNumbers = allTickets.map((ticket) => ticket.ticketNumber);

    const ticketNumbers = new Set(allTicketNumbers);

    return allTickets.some((ticket) => {
      const originalTicketnumber = ticket.exchangeInfo?.originalTicketNumber;

      if (!originalTicketnumber) {
        return false;
      }

      return ticketNumbers.has(originalTicketnumber);
    });
  }

  public pnrTitle() {
    const destinationCityName = this.destinationCityName();
    const pnrTitle = defineCommonMessage('Flight to {{destinationCityName}}');
    pnrTitle.values = {
      destinationCityName,
    };
    return pnrTitle;
  }

  public flightSavings() {
    const priceOptimizationMetadata = this?.airPnr?.airPriceOptimizationMetadata;
    const priceDrop = MoneyUtil.convertV2MoneyToMoneyUtil(priceOptimizationMetadata?.priceDrop);
    const penalty = MoneyUtil.convertV2MoneyToMoneyUtil(priceOptimizationMetadata?.penaltyPrice);
    const savings = priceDrop.subtract(penalty);
    return savings;
  }

  public getFlightTicket({ legIdx, flightIdx }: { legIdx: number; flightIdx: number }): Ticket | undefined {
    const tickets = this.airPnr?.travelerInfos?.[0]?.tickets ?? [];

    const ticketsForFlight =
      tickets.filter(
        (ticket) =>
          ticket.ticketType === TicketTicketTypeEnum.Flight &&
          ticket.flightCoupons?.find((coupon) => coupon.legIdx === legIdx && coupon.flightIdx === flightIdx),
      ) ?? [];

    return ticketsForFlight[0];
  }

  public getIsTravelerCheckedIn() {
    /**
     * @followup implement this with help from Air pod
     */
    return false;
  }

  public getTicketType(
    ticketNumber: string,
  ): { ticketType: TicketTicketTypeEnum | undefined; ancillaryTypes: TicketAncillaryAncillaryTypeEnum[] } | undefined {
    const tickets = this.airPnr?.travelerInfos?.[0]?.tickets ?? [];

    const correspondingTicket = tickets.find((ticket) => ticket.ticketNumber === ticketNumber);
    if (!correspondingTicket) {
      return undefined;
    }
    const { ticketType } = correspondingTicket;
    const ancillaryTypes = correspondingTicket.ancillaries?.map((ancillary) => ancillary.ancillaryType) ?? [];

    return {
      ticketType,
      ancillaryTypes,
    };
  }

  private getTransactionDetails(transactionType: TransactionType | undefined, items: TransactionItem[]) {
    if (transactionType === TransactionType.TripServiceFee) {
      return {
        description: defineCommonMessage('Fee'),
      };
    }
    const airItems = items.filter((item) => item.itemType === 'AIR_ITEM') as AirItem[];
    const flights: AirFlight[] = [];
    const flightDescriptions: string[] = [];
    const transactionItems: (AirItemType | TransactionAncillaryType)[] = [];

    const flightAirlineMetadata = this.pnrData.additionalMetadata;

    airItems.forEach((item) => {
      const itemFlightDescriptions = (item.flights || []).map((flight) => {
        const airlineName =
          getAirlineNameFromAirlineCode(
            flight.marketing?.airlineCode || '',
            flightAirlineMetadata?.airlineInfo ?? [],
          ) ?? '';
        return `${airlineName} ${flight.marketing?.airlineCode}${flight.marketing?.num} (${flight.origin?.airportCode} - ${flight.destination?.airportCode})`;
      });
      flightDescriptions.push(...itemFlightDescriptions);
      flights.push(...(item.flights || []));
      if (item.airItemType) {
        transactionItems.push(item.airItemType);
        if (item.airItemType !== AirItemType.Flight) {
          transactionItems.push(...(item.ancillaryTypes || []));
        }
      }
    });

    let description = defineCommonMessage('Flight booking');

    if (transactionItems.includes(AirItemType.Flight)) {
      if (transactionType === TransactionType.AirTicketIssued) {
        description = defineCommonMessage('Flight booking');
      }
      if (
        transactionType === TransactionType.AirTicketRefunded ||
        transactionType === TransactionType.AirTicketVoided
      ) {
        description = defineCommonMessage('Flight cancellation');
      }
      if (transactionType === TransactionType.AirTicketExchanged) {
        description = defineCommonMessage('Flight exchange');
      }
    } else if (transactionItems.includes(TransactionAncillaryType.Seat)) {
      if (transactionType === TransactionType.AirTicketIssued) {
        description = defineCommonMessage('Seat purchase');
      }
      if (
        transactionType === TransactionType.AirTicketRefunded ||
        transactionType === TransactionType.AirTicketVoided
      ) {
        description = defineCommonMessage('Seat cancellation');
      }
    } else if (transactionItems.includes(AirItemType.Ancillary)) {
      if (transactionType === TransactionType.AirTicketIssued) {
        description = defineCommonMessage('Ancillary purchase');
      }
      if (
        transactionType === TransactionType.AirTicketRefunded ||
        transactionType === TransactionType.AirTicketVoided
      ) {
        description = defineCommonMessage('Ancillary cancellation');
      }
    }

    return {
      description,
      flights: uniq(flights),
      flightDescriptions: uniq(flightDescriptions),
    };
  }

  public getTransactions(): PnrTransaction[] {
    const travelerNamesMap: Record<string, string> = {};
    this.pnrData.pnrTravelers?.forEach((pnrTraveler) => {
      const travelerName = createUserNameFromFullName(pnrTraveler.personalInfo?.name);
      travelerNamesMap[pnrTraveler.userId.id] = travelerName;
    });

    const transactions: Transaction[] = this.pnrData?.transactions || [];

    // This is to be removed once service fee transactions are handled in the backend
    const serviceFeeTransactions: Transaction[] = this.getServiceFeeTransactions();

    return [...transactions, ...serviceFeeTransactions].flatMap((transaction) => {
      return (transaction.itemGroups || []).map((itemGroup) => {
        const travelerNames = itemGroup.userId?.id
          ? [travelerNamesMap[itemGroup.userId.id]]
          : Object.values(travelerNamesMap);
        return {
          ...itemGroup,
          ctc: transaction.ctc || [],
          customerCharges: (transaction.ctc || []).map(this.getCustomerCharges),
          priceBreakdown: this.getTransactionPriceBreakdown(itemGroup),
          transactionDetails: this.getTransactionDetails(itemGroup.transactionType, itemGroup.items || []),
          pnrId: this.pnrId,
          travelerNames,
        };
      });
    });
  }
}
