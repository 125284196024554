import type { CounterLocation } from '../../types/api/v1/obt/car/car_common';
import { FareType, CarType, EngineType, CarExtraType } from '../../types/api/v1/obt/car/car_common';
import { CarExtraType as CarExtraTypeV2 } from '../../types/api/v2/obt/model/car-extra-type';
import type { IKeyLabelPair } from '../../types/common';
import type {
  ICarFilterState,
  ICarTypeKeyLabelPair,
  ISearchParams,
  ICarSearchState,
  ICarSearchErrorState,
  ICarFilterStateV2,
} from '../../types/car';
import type { Int32Range } from '../../types/api/v1/common/range';
import {
  CarFiltersEngineTypeEnum,
  FiltersTransmissionTypeEnum,
  SortOptionSortByEnum,
  SortOptionSortOrderEnum,
} from '../../types/api/v1/obt/car/car_search_request';
import type { ICarSuggestion } from '../../types';
import { FareTypeEnum } from '../../types';
import { RateComponentType } from '../../types/api/v1/obt/car/car_search_response';
import { defineMessage } from '../../translations/defineMessage';
import type { CarAdditionalDetailType } from '../../types/api/v2/obt/model/car-additional-detail-type';
import { CarRateDetailsFareTypeEnum } from '../../types/api/v2/obt/model/car-rate-details';

export const carVendors: Record<string, string> = {
  AC: 'ACE',
  AD: 'ADVANTAGE',
  AL: 'ALAMO',
  AO: 'ACO',
  BV: 'BLUU',
  DF: 'DRVFORCE',
  DS: 'DISCOUNT',
  EM: 'EUROMOBIL',
  EP: 'EUROPCAR',
  ES: 'EASIRENT',
  ET: 'ENTERPRISE',
  EY: 'ECONOMY',
  EZ: 'EZ',
  FF: 'FIREFLY',
  FR: 'FIRST',
  FX: 'FOX',
  GM: 'GREENMOTION',
  IM: 'EUROPCAR SA',
  LL: 'LOCALIZA',
  MG: 'MAGGIORE',
  MO: 'MOVIDA',
  MW: 'MIDWAY',
  MX: 'MEX',
  NC: 'NEXT',
  NU: 'NU',
  PR: 'PRICELESS',
  RO: 'ROUTES',
  RW: 'RENT A WRECK',
  SV: 'USAVE',
  SX: 'SIXT',
  TI: 'TEMPEST',
  UN: 'UNIDAS',
  WF: 'WOODFORD',
  XX: 'OTHER',
  ZA: 'PAYLESS',
  ZD: 'BUDGET',
  ZE: 'HERTZ',
  ZI: 'AVIS',
  ZL: 'NATIONAL',
  ZR: 'DOLLAR',
  ZT: 'THRIFTY',
  '1C': 'CAR TRAWLER',
};

export const daysOfWeek: string[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const carTranslationKeys = {
  SUMMARY: 'SUMMARY',
  SUPPLIER: 'SUPPLIER',
  RENTAL_COMPANIES: 'CAR_RENTAL_COMPANIES',
  CAR_CLASS: 'CAR_CLASS',
  TYPE: 'TYPE',
  CAPACITY: 'CAPACITY',
  RATING: 'RATING',
  RELEVANCE: 'RELEVANCE',
  PRICE: 'PRICE',
  DISTANCE: 'DISTANCE',
  OTHER: 'OTHER',
  ECONOMY: 'ECONOMY',
  COMPACT: 'COMPACT',
  MID_SIZE: 'MID_SIZE',
  STANDARD: 'STANDARD',
  FULL_SIZE: 'FULL_SIZE',
  PREMIUM: 'PREMIUM',
  LUXURY: 'LUXURY',
  CONVERTIBLE: 'CONVERTIBLE',
  MINIVAN: 'MINIVAN',
  MINI: 'MINI',
  SUV: 'SUV',
  VAN: 'VAN',
  PICKUP: 'PICKUP',
  SPORTS: 'SPORTS',
  SPECIAL: 'SPECIAL',
  RECREATIONAL_VEHICLE: 'RECREATIONAL_VEHICLE',
  PASSENGERS_2_5: 'PASSENGERS_2_5',
  PASSENGERS_6: 'PASSENGERS_6',
  DROP_OFF: 'DROP_OFF',
  PICK_UP: 'PICK_UP',
  SAME_AS_PICK_UP: 'SAME_AS_PICK_UP',
  LOCATION_OR_AIRPORT: 'LOCATION_OR_AIRPORT',
  DATE: 'DATE',
  TIME: 'TIME',
  SEARCH_CARS: 'SEARCH_CARS',
  BOOK: 'BOOK',
  SORT_BY: 'SORT_BY',
  MORE_SORTING_OPTIONS: 'MORE_SORTING_OPTIONS',
  MORE_INFO: 'MORE_INFO',
  APPROX_COST: 'APPROX_COST',
  CAR_SUMMARY: 'CAR_SUMMARY',
  TRAVELER_INFO: 'TRAVELER_INFO',
  CONFIRMATION: 'CONFIRMATION',
  BOOK_CAR: 'BOOK_CAR',
  BOOKING_CAR: 'BOOKING_CAR',
  RENTAL_COMPANY: 'RENTAL_COMPANY',
  CAR_CATEGORY: 'CAR_CATEGORY',
  SELECT: 'SELECT',
  ALL_AGENCIES: 'ALL_AGENCIES',
  ALL_TYPES: 'ALL_TYPES',
  TOTAL_COST: 'TOTAL_COST',
  TOTAL_TAX: 'TOTAL_TAX',
  TOTAL_BASE_RATE: 'TOTAL_BASE_RATE',
  TOTAL: 'TOTAL',
  TAXES_FEES: 'TAXES_FEES',
  DAILY_RATE: 'DAILY_RATE',
  INCLUDED_IN_PRICE: 'INCLUDED_IN_PRICE',
  MUST_PRESENT_CARD: 'MUST_PRESENT_CARD',
  PRICE_BREAKDOWN: 'PRICE_BREAKDOWN',
  NO_RESULTS_FOUND: 'NO_RESULTS_FOUND',
  NO_RESULTS_FOUND_DESCRIPTION: 'NO_RESULTS_FOUND_DESCRIPTION',
  COMPANY_RECOMMENDED: 'COMPANY_RECOMMENDED',
  COMPANY_PREFERRED: 'COMPANY_PREFERRED',
  PRICE_PER_DAY: 'PRICE_PER_DAY',
  AUTO_TRANSMISSION: 'AUTO_TRANSMISSION',
  AUTOMATIC_TRANSMISSION: 'AUTOMATIC_TRANSMISSION',
  MANUAL_TRANSMISSION: 'MANUAL_TRANSMISSION',
  TRANSMISSION: 'TRANSMISSION',
  FROM: 'FROM',
  NON_AIRPORT_LOCATION: 'NON_AIRPORT_LOCATION',
  IN_TERMINAL: 'IN_TERMINAL',
  OFF_AIRPORT_RENTAL_SHUTTLE: 'OFF_AIRPORT_RENTAL_SHUTTLE',
  IN_TERMINAL_RENTAL_SHUTTLE: 'IN_TERMINAL_RENTAL_SHUTTLE',
  ON_AIRPORT_RENTAL_SHUTTLE: 'ON_AIRPORT_RENTAL_SHUTTLE',
  ON_AIRPORT_AIRPORT_SHUTTLE: 'ON_AIRPORT_AIRPORT_SHUTTLE',
  CALL_FOR_SHUTTLE: 'CALL_FOR_SHUTTLE',
  TWO_SHUTTLES_AIRPORT_AND_RENTAL: 'TWO_SHUTTLES_AIRPORT_AND_RENTAL',
  AWAY: 'AWAY',
};

export const capacities: { labelKey: string; range: Int32Range }[] = [
  { labelKey: carTranslationKeys.PASSENGERS_2_5, range: { min: 2, max: 5 } },
  { labelKey: carTranslationKeys.PASSENGERS_6, range: { min: 6, max: 100 } },
];

export const morePassangerCapacityrRange: Int32Range = { min: 6, max: 100 };
export const transmissions: IKeyLabelPair[] = [
  { backendKey: FiltersTransmissionTypeEnum.MANUAL, labelKey: carTranslationKeys.MANUAL_TRANSMISSION },
  { backendKey: FiltersTransmissionTypeEnum.AUTOMATIC, labelKey: carTranslationKeys.AUTOMATIC_TRANSMISSION },
];

export const transmissionKeys = [FiltersTransmissionTypeEnum.MANUAL, FiltersTransmissionTypeEnum.AUTOMATIC];

export const defaultCarsSortType = SortOptionSortByEnum.UNRECOGNIZED;

export const carsSortTypeOptions: IKeyLabelPair[] = [
  { backendKey: SortOptionSortByEnum.UNRECOGNIZED, labelKey: carTranslationKeys.RELEVANCE },
  { backendKey: SortOptionSortByEnum.PRICE, labelKey: carTranslationKeys.PRICE },
  { backendKey: SortOptionSortByEnum.DISTANCE, labelKey: carTranslationKeys.DISTANCE },
];

/**
 * @deprecated
 */
export const carTypes: ICarTypeKeyLabelPair[] = [
  { backendKey: CarType.ECONOMY, labelKey: defineMessage('Economy') },
  { backendKey: CarType.MINI, labelKey: defineMessage('Mini') },
  { backendKey: CarType.COMPACT, labelKey: defineMessage('Compact') },
  { backendKey: CarType.MID_SIZE, labelKey: defineMessage('Mid Size') },
  { backendKey: CarType.STANDARD, labelKey: defineMessage('Standard') },
  { backendKey: CarType.FULL_SIZE, labelKey: defineMessage('Full Size') },
  { backendKey: CarType.PREMIUM, labelKey: defineMessage('Premium') },
  { backendKey: CarType.LUXURY, labelKey: defineMessage('Luxury') },
  { backendKey: CarType.CONVERTIBLE, labelKey: defineMessage('Convertible') },
  { backendKey: CarType.MINIVAN, labelKey: defineMessage('Mini Van') },
  { backendKey: CarType.SUV, labelKey: defineMessage('Suv') },
  { backendKey: CarType.VAN, labelKey: defineMessage('Van') },
  { backendKey: CarType.PICKUP, labelKey: defineMessage('Pickup') },
  { backendKey: CarType.SPORTS, labelKey: defineMessage('Sports') },
  { backendKey: CarType.SPECIAL, labelKey: defineMessage('Special') },
  { backendKey: CarType.RECREATIONAL_VEHICLE, labelKey: defineMessage('Recreational Vehicle') },
  { backendKey: CarType.WAGON, labelKey: defineMessage('Wagon') },
  { backendKey: CarType.OTHER, labelKey: defineMessage('Other') },
];

export const emptyICarSuggestion: ICarSuggestion = {
  type: '',
  name: '',
  location: '',
  state: '',
  country: '',
  data: '',
  sessionToken: '',
};

/**
 * @deprecated
 */
export const defaultCarFilters: ICarFilterState = {
  vehicleType: [],
  vendors: [],
  capacity: [],
  excludeTransmissions: transmissionKeys,
  preferredOnly: false,
};

export const defaultCarFiltersV2: ICarFilterStateV2 = {
  vehicleType: [],
  vendors: [],
  excludeTransmissions: transmissionKeys,
  engineType: [],
  rates: [0, 1000],
  hasAirConditioning: false,
  hasUnlimitedMileage: false,
  carExtraType: [],
  numPassengers: false,
  preferredOnly: false,
  virtualPaymentOnly: false,
};

export const emptyCarSearchRequestParams: ISearchParams = {
  dateTime: { from: '', to: '' },
  pickup: emptyICarSuggestion,
  dropOff: emptyICarSuggestion,
  userOrgIds: [],
};

export const defaultCarSearchState: ICarSearchState = {
  carSearchResponse: null,
  loading: false,
  carCreatePnrResponse: null,
  carId: '',
  pickupLocation: '',
  dropOffLocation: '',
};

export const emptyErrorState: ICarSearchErrorState = {
  pickup: '',
  dropOff: '',
  from: '',
  to: '',
};

export const IRateComponentTypeEnum = RateComponentType;
export const ICarSortOptionSortOrderEnum = SortOptionSortOrderEnum;

export const carFareTypeToCommonFareType: Record<FareType, FareTypeEnum> = {
  [FareType.CORPORATE]: FareTypeEnum.CORPORATE,
  [FareType.PUBLISHED]: FareTypeEnum.PUBLISHED,
  [FareType.SPOTNANA]: FareTypeEnum.PRIVATE,
  [FareType.UNRECOGNIZED]: FareTypeEnum.UNRECOGNIZED,
};

export const carRateDetailsFareTypeEnumToCommonFareType: Record<CarRateDetailsFareTypeEnum, FareTypeEnum> = {
  [CarRateDetailsFareTypeEnum.Corporate]: FareTypeEnum.CORPORATE,
  [CarRateDetailsFareTypeEnum.Published]: FareTypeEnum.PUBLISHED,
  [CarRateDetailsFareTypeEnum.Spotnana]: FareTypeEnum.PRIVATE,
  [CarRateDetailsFareTypeEnum.Unknown]: FareTypeEnum.UNRECOGNIZED,
};

export const carTypeToNameMapper = (carType?: string): string | undefined => {
  switch (carType) {
    case 'COMPACT':
      return 'Compact';
    case 'CONVERTIBLE':
      return 'Convertible';
    case 'ECONOMY':
      return 'Economy';
    case 'FULL_SIZE':
      return 'Full size';
    case 'LUXURY':
      return 'Luxury';
    case 'MID_SIZE':
      return 'Mid size';
    case 'MINI':
      return 'Mini';
    case 'MINIVAN':
      return 'Minivan';
    case 'OTHER':
      return 'Other';
    case 'PICKUP':
      return 'Pickup';
    case 'PREMIUM':
      return 'Premium';
    case 'RECREATIONAL_VEHICLE':
      return 'Recreational vehicle';
    case 'SPECIAL':
      return 'Special';
    case 'SPORTS':
      return 'Sports';
    case 'STANDARD':
      return 'Standard';
    case 'SUV':
      return 'Suv';
    case 'VAN':
      return 'Van';
    case 'WAGON':
      return 'Wagon';
    default:
      return undefined;
  }
};

export interface IEngineTypeKeyLabelPair {
  backendKey: EngineType[];
  labelKey: string;
}

export const alternativeEngineTypes = [EngineType.HYDROGEN, EngineType.MULTI_FUEL, EngineType.CNG, EngineType.ETHANOL];
export const alternativeEngineLabel = defineMessage('Alternative');

export const engineTypes: IEngineTypeKeyLabelPair[] = [
  { backendKey: [EngineType.HYBRID], labelKey: defineMessage('Hybrid') },
  { backendKey: [EngineType.ELECTRIC], labelKey: defineMessage('Electric') },
  { backendKey: [EngineType.PETROL], labelKey: defineMessage('Gasoline/Petrol') },
  { backendKey: [EngineType.DIESEL], labelKey: defineMessage('Diesel') },
  {
    backendKey: alternativeEngineTypes,
    labelKey: alternativeEngineLabel,
  },
  { backendKey: [EngineType.UNRECOGNIZED], labelKey: defineMessage('Unspecified') },
];

export const mapEngineTypeToFilterEngineType = (engineType: EngineType): CarFiltersEngineTypeEnum => {
  switch (engineType) {
    case EngineType.HYBRID:
      return CarFiltersEngineTypeEnum.HYBRID;
    case EngineType.ELECTRIC:
      return CarFiltersEngineTypeEnum.ELECTRIC;
    case EngineType.PETROL:
      return CarFiltersEngineTypeEnum.PETROL;
    case EngineType.DIESEL:
      return CarFiltersEngineTypeEnum.DIESEL;
    case EngineType.CNG:
    case EngineType.HYDROGEN:
    case EngineType.ETHANOL:
    case EngineType.MULTI_FUEL:
      return CarFiltersEngineTypeEnum.ALTERNATIVE;
    case EngineType.UNKNOWN_ENGINE:
      return CarFiltersEngineTypeEnum.UNSPECIFIED;
    default:
      return CarFiltersEngineTypeEnum.UNSPECIFIED;
  }
};

export const engineTypeLabelsMap: Record<CarFiltersEngineTypeEnum, string> = {
  [CarFiltersEngineTypeEnum.HYBRID]: defineMessage('Hybrid'),
  [CarFiltersEngineTypeEnum.ELECTRIC]: defineMessage('Electric'),
  [CarFiltersEngineTypeEnum.PETROL]: defineMessage('Gasoline'),
  [CarFiltersEngineTypeEnum.DIESEL]: defineMessage('Diesel'),
  [CarFiltersEngineTypeEnum.ALTERNATIVE]: defineMessage('Alternative'),
  [CarFiltersEngineTypeEnum.UNSPECIFIED]: defineMessage('Unspecified'),
};

export const engineTypeKeyLabel: { value: CarFiltersEngineTypeEnum; label: string; icon: string }[] = [
  {
    value: CarFiltersEngineTypeEnum.HYBRID,
    label: engineTypeLabelsMap[CarFiltersEngineTypeEnum.HYBRID],
    icon: 'CarEngineHybrid',
  },
  {
    value: CarFiltersEngineTypeEnum.ELECTRIC,
    label: engineTypeLabelsMap[CarFiltersEngineTypeEnum.ELECTRIC],
    icon: 'CarEngineElectric',
  },
  {
    value: CarFiltersEngineTypeEnum.PETROL,
    label: engineTypeLabelsMap[CarFiltersEngineTypeEnum.PETROL],
    icon: 'CarEngineGasoline',
  },
  {
    value: CarFiltersEngineTypeEnum.DIESEL,
    label: engineTypeLabelsMap[CarFiltersEngineTypeEnum.DIESEL],
    icon: 'CarEngineGasoline',
  },
  {
    value: CarFiltersEngineTypeEnum.ALTERNATIVE,
    label: engineTypeLabelsMap[CarFiltersEngineTypeEnum.ALTERNATIVE],
    icon: 'CarEngineEco',
  },
  {
    value: CarFiltersEngineTypeEnum.UNSPECIFIED,
    label: engineTypeLabelsMap[CarFiltersEngineTypeEnum.UNSPECIFIED],
    icon: 'CarEngine',
  },
];

export const specialEquipmentKeyLabel: { value: CarExtraType; label: string }[] = [
  { value: CarExtraType.BICYCLE_RACK, label: defineMessage('Bike rack') },
  { value: CarExtraType.CHILD_SEAT_BOOSTER, label: defineMessage('Child seat (booster)') },
  { value: CarExtraType.CHILD_SEAT_INFANT, label: defineMessage('Child seat (infant)') },
  { value: CarExtraType.CHILD_SEAT_TODDLER, label: defineMessage('Child seat (toddler)') },
  { value: CarExtraType.SATELLITE_RADIO, label: defineMessage('Satellite radio') },
  { value: CarExtraType.LUGGAGE_RACK, label: defineMessage('Luggage rack') },
  { value: CarExtraType.NAVIGATIONAL_SYSTEM, label: defineMessage('Navigation system') },
  { value: CarExtraType.SKI_EQUIPPED_VEHICLE, label: defineMessage('Ski equipped') },
  { value: CarExtraType.SNOW_CHAINS, label: defineMessage('Snow chains') },
  { value: CarExtraType.WHEEL_CHAIR_ACCESS_RAMP, label: defineMessage('Wheelchair access ramp') },
];

export const carExtrasLabelMap: { [CarExtraType.UNKNOWN_TYPE]: string } & Partial<Record<CarExtraType, string>> = {
  [CarExtraType.UNKNOWN_TYPE]: defineMessage('Unspecified'),
  [CarExtraType.BICYCLE_RACK]: defineMessage('Bike rack'),
  [CarExtraType.CHILD_SEAT_BOOSTER]: defineMessage('Child seat (booster)'),
  [CarExtraType.CHILD_SEAT_INFANT]: defineMessage('Child seat (infant)'),
  [CarExtraType.CHILD_SEAT_TODDLER]: defineMessage('Child seat (toddler)'),
  [CarExtraType.SATELLITE_RADIO]: defineMessage('Satellite radio'),
  [CarExtraType.LUGGAGE_RACK]: defineMessage('Luggage rack'),
  [CarExtraType.NAVIGATIONAL_SYSTEM]: defineMessage('Navigation system'),
  [CarExtraType.SKI_EQUIPPED_VEHICLE]: defineMessage('Ski equipped'),
  [CarExtraType.SNOW_CHAINS]: defineMessage('Snow chains'),
  [CarExtraType.WHEEL_CHAIR_ACCESS_RAMP]: defineMessage('Wheelchair access ramp'),
};

export const carExtrasV2LabelMap: Partial<Record<CarExtraTypeV2, string>> = {
  [CarExtraTypeV2.BicycleRack]: defineMessage('Bike rack'),
  [CarExtraTypeV2.ChildSeatBooster]: defineMessage('Child seat (booster)'),
  [CarExtraTypeV2.ChildSeatInfant]: defineMessage('Child seat (infant)'),
  [CarExtraTypeV2.ChildSeatToddler]: defineMessage('Child seat (toddler)'),
  [CarExtraTypeV2.SatelliteRadio]: defineMessage('Satellite radio'),
  [CarExtraTypeV2.LuggageRack]: defineMessage('Luggage rack'),
  [CarExtraTypeV2.NavigationalSystem]: defineMessage('Navigation system'),
  [CarExtraTypeV2.SkiEquippedVehicle]: defineMessage('Ski equipped'),
  [CarExtraTypeV2.SnowChains]: defineMessage('Snow chains'),
  [CarExtraTypeV2.WheelChairAccessRamp]: defineMessage('Wheelchair access ramp'),
};

export const carRateAdditionalInfoTypeLabelMap: Record<CarAdditionalDetailType, string> = {
  AGE_INFORMATION: defineMessage('Age Information'),
  DRIVER_INFORMATION: defineMessage('Driver Information'),
  DROP_OFF_INFORMATION: defineMessage('Drop Off Information'),
  FEES_INFORMATION: defineMessage('Fees Information'),
  FUEL_INFORMATION: defineMessage('Fuel Information'),
  GEOGRAPHIC_RESTRICTIONS_INFORMATION: defineMessage('Geographic Restrictions Information'),
  INSURANCE_INFORMATION: defineMessage('Insurance Information'),
  LICENSE_INFORMATION: defineMessage('License Information'),
  SHUTTLE_INFORMATION: defineMessage('Shuttle Information'),
  OTHER_INFORMATION: defineMessage('Unrecognized'),
};

export const counterLocationLabelMap: Record<keyof typeof CounterLocation, string> = {
  UNKNOWN_COUNTER: defineMessage('Follow signs to counter'),
  CALL_FOR_SHUTTLE: defineMessage('Shuttle to counter and car'),
  IN_TERMINAL: defineMessage('In terminal'),
  IN_TERMINAL_RENTAL_SHUTTLE: defineMessage('In terminal shuttle'),
  NON_AIRPORT_LOCATION: defineMessage('Counter located outside airport'),
  OFF_AIRPORT_RENTAL_SHUTTLE: defineMessage('Shuttle to counter and car'),
  ON_AIRPORT_AIRPORT_SHUTTLE: defineMessage('Shuttle to counter and car'),
  ON_AIRPORT_RENTAL_SHUTTLE: defineMessage('Shuttle to counter and car'),
  TWO_SHUTTLES_AIRPORT_AND_RENTAL: defineMessage('Shuttle to counter and car'),
  UNRECOGNIZED: defineMessage('Follow signs to counter'),
};

export const weekDayLabelMap: Record<string, string> = {
  SUN: defineMessage('Sunday'),
  MON: defineMessage('Monday'),
  TUE: defineMessage('Tuesday'),
  WED: defineMessage('Wednesday'),
  THU: defineMessage('Thrushday'),
  FRI: defineMessage('Friday'),
  SAT: defineMessage('Saturday'),
};

export const availableLogoCarVendors: string[] = [
  'AC',
  'AL',
  'BG',
  'CT',
  'DS',
  'EM',
  'ES',
  'EX',
  'FD',
  'FR',
  'GM',
  'HT',
  'MG',
  'NA',
  'QF',
  'SC',
  'SX',
  'WF',
  'ZE',
  'ZR',
  'AD',
  'AT',
  'BV',
  'DF',
  'EC',
  'EN',
  'ET',
  'EY',
  'FF',
  'FX',
  'HE',
  'IM',
  'ML',
  'NU',
  'RO',
  'SV',
  'TH',
  'ZA',
  'ZI',
  'ZT',
  'AF',
  'AV',
  'CP',
  'DO',
  'ED',
  'EP',
  'EU',
  'EZ',
  'FO',
  'GL',
  'HO',
  'LL',
  'MO',
  'PR',
  'RR',
  'SW',
  'UN',
  'ZD',
  'ZL',
];
