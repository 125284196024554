/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Type of ancillary
 * @export
 * @enum {string}
 */

export const TransactionAncillaryType = {
    Seat: 'SEAT',
    Luggage: 'LUGGAGE',
    EarlyBird: 'EARLY_BIRD',
    Wifi: 'WIFI',
    CarbonOffset: 'CARBON_OFFSET'
} as const;

export type TransactionAncillaryType = typeof TransactionAncillaryType[keyof typeof TransactionAncillaryType];



