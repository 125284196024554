import type { BookingHistoryStatus, INewTripValidationState, ListTripActivityType } from '../types/trip';
import { GroupTripCategoryPath, TripCategoryPath } from '../types/trip';
import { TripActivityType } from '../types/api/v2/obt/model/trip-activity-type';
import { PnrBookingHistoryBookingStatus, UserFacingStatus } from '../types/api/v1/obt/pnr/pnr';
import { AirAncillariesResponseAncillaryAncillaryType } from '../types/api/v1/obt/air/air_ancillaries';
import { defineMessage } from '../translations/defineMessage';
import { AncillaryType } from '../types/api/v2/obt/model/ancillary-type';

export const TRIPS_PER_PAGE = 10;

export const emptyNewTripErrorState: INewTripValidationState = {
  errors: {
    name: '',
  },
  isValid: null,
};

export const tripTypeUrlParams = [TripCategoryPath.Upcoming, TripCategoryPath.Past, TripCategoryPath.Cancelled];

export const groupTripTypeUrlParams = [
  GroupTripCategoryPath.Upcoming,
  GroupTripCategoryPath.Completed,
  GroupTripCategoryPath.Cancelled,
];

export const tripsSummaryTranslationKeys = {
  SHOWING_RESULTS_FOR: 'SHOWING_RESULTS_FOR',
  UPCOMING: 'UPCOMING',
  UPCOMING_TRIPS: 'UPCOMING_TRIPS',
  PAST: 'PAST',
  NEW_TRIP: 'NEW_TRIP',
  VIEW_RECEIPT: 'VIEW_RECEIPT',
  TRIPS: 'TRIPS',
  TRIP_ID: 'TRIP_ID',
  BOOK_A_TRIP: 'BOOK_A_TRIP',
  NO_UPCOMING_TRIPS: 'NO_UPCOMING_TRIPS',
  THERE_ARE_NO_UPCOMING_TRIPS: 'THERE_ARE_NO_UPCOMING_TRIPS',
  PAST_TRIPS: 'PAST_TRIPS',
  NO_PAST_TRIPS: 'NO_PAST_TRIPS',
  NO_CANCELLED_TRIPS: 'NO_CANCELLED_TRIPS',
  THERE_ARE_NO_PAST_TRIPS: 'THERE_ARE_NO_PAST_TRIPS',
  SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
  ADD_FLIGHT: 'ADD_FLIGHT',
  ADD_HOTEL: 'ADD_HOTEL',
  ADD_CAR: 'ADD_CAR',
  ADD_RAIL: 'ADD_RAIL',
  ADD_LIMO: 'ADD_LIMO',
  ADD_MISC: 'ADD_MISC',
  OTHER: 'OTHER',
  OUTBOUND_FLIGHT: 'OUTBOUND_FLIGHT',
  RETURN_FLIGHT: 'RETURN_FLIGHT',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  VOIDED: 'VOIDED',
  CONFIRMED: 'CONFIRMED',
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  REFUNDED: 'REFUNDED',
  GET_STARTED_ON_YOUR_NEXT_TRIP: 'GET_STARTED_ON_YOUR_NEXT_TRIP',
  READY_TO_GO: 'READY_TO_GO',
  NEW_BOOKING: 'NEW_BOOKING',
  NOTHING_TO_SEE: 'NOTHING_TO_SEE',
  MANUALLY_ADD_BOOKINGS: 'MANUALLY_ADD_BOOKINGS',
  SEARCH_FLIGHT: 'SEARCH_FLIGHT',
  SEARCH_HOTEL: 'SEARCH_HOTEL',
  SEARCH_CAR: 'SEARCH_CAR',
  SEARCH_RAIL: 'SEARCH_RAIL',
  SEARCH_LIMO: 'SEARCH_LIMO',
  OUTSIDE_BOOKING: 'OUTSIDE_BOOKING',
  ENTER_DETAILS_MANUALLY: 'ENTER_DETAILS_MANUALLY',
  CREATE_A_NEW_TRIP: 'CREATE_A_NEW_TRIP',
  TRIP: 'TRIP',
  ADD_TO_EXISTING_TRIP: 'ADD_TO_EXISTING_TRIP',
  SELECT_A_TRIP: 'SELECT_A_TRIP',
  TITLE_OF_TRIP: 'TITLE_OF_TRIP',
  DESCRIPTION_OF_TRIP: 'DESCRIPTION_OF_TRIP',
  SERVICE_FEE: 'SERVICE_FEE',
  TOTAL_AMOUNT_PAYABLE: 'TOTAL_AMOUNT_PAYABLE',
  TOTAL: 'TOTAL',
  FLIGHT_DETAILS: 'FLIGHT_DETAILS',
  THIRD_PARTY_SUPPLIER_NAME: 'THIRD_PARTY_SUPPLIER_NAME',
  THIRD_PARTY_CONFIRMATION_NUMBER: 'THIRD_PARTY_CONFIRMATION_NUMBER',
  WHERE_FROM: 'WHERE_FROM',
  WHERE_TO: 'WHERE_TO',
  DEPARTURE_DATE_AND_TIME: 'DEPARTURE_DATE_AND_TIME',
  ARRIVAL_DATE_AND_TIME: 'ARRIVAL_DATE_AND_TIME',
  MARKETING_AIRLINES: 'MARKETING_AIRLINES',
  MARKETING_AIRLINES_FLIGHT_NUMBER: 'MARKETING_AIRLINES_FLIGHT_NUMBER',
  OPERATING_AIRLINES: 'OPERATING_AIRLINES',
  OPERATING_AIRLINES_FLIGHT_NUMBER: 'OPERATING_AIRLINES_FLIGHT_NUMBER',
  CONFIRMATION_NUMBER: 'CONFIRMATION_NUMBER',
  BRANDED_FARE: 'BRANDED_FARE',
  SEAT_NUMBER: 'SEAT_NUMBER',
  CABIN_CLASS: 'CABIN_CLASS',
  ADD_SEGMENT: 'ADD_SEGMENT',
  ADD_DESTINATION: 'ADD_DESTINATION',
  TICKET_DETAILS: 'TICKET_DETAILS',
  ENTER_ALPHANUMERIC: 'ENTER_ALPHANUMERIC',
  BASE_FARE: 'BASE_FARE',
  TAXES: 'TAXES',
  ADD_EXTRAS: 'ADD_EXTRAS',
  TYPE: 'TYPE',
  AMOUNT: 'AMOUNT',
  FULL_AMOUNT: 'FULL_AMOUNT',
  LOWER_FARE_DECLINED: 'LOWER_FARE_DECLINED',
  OUT_OF_POLICY: 'OUT_OF_POLICY',
  SELECT_REASON: 'SELECT_REASON',
  CHARGE_TRAVELER: 'CHARGE_TRAVELER',
  SELECT_CARD: 'SELECT_CARD',
  TICKET_NUMBER: 'TICKET_NUMBER',
  TICKET_NUMBERS_AND_COST: 'TICKET_NUMBERS_AND_COST',
  SPLIT_COST: 'SPLIT_COST',
  COMBINE_COST: 'COMBINE_COST',
  COST_PER_PERSON: 'COST_PER_PERSON',
  TOTAL_AMOUNT: 'TOTAL_AMOUNT',
  TRIP_TYPE: 'TRIP_TYPE',
  UNKNOWN_STATUS: 'UNKNOWN_STATUS',
  REFUNDED_STATUS: 'REFUNDED_STATUS',
  UNRECOGNIZED: 'UNRECOGNIZED',
  DELAYED: 'DELAYED',
  REQUESTED: 'REQUESTED',
  GATE_CHANGED: 'GATE_CHANGED',
  TERMINAL_CHANGED: 'TERMINAL_CHANGED',
  CONNECTION_RISK: 'CONNECTION_RISK',
  REINSTATED: 'REINSTATED',
  MULTIPLE_UPDATES: 'MULTIPLE_UPDATES',
  STARTS: 'STARTS',
  STARTED: 'STARTED',
  BOOKED: 'BOOKED',
  ENDS: 'ENDS',
  ENDED: 'ENDED',
  FLIGHT_DELAYED: 'FLIGHT_DELAYED',
  FLIGHT_GATE_CHANGED: 'FLIGHT_GATE_CHANGED',
  FLIGHT_CONNECTION_RISK: 'FLIGHT_CONNECTION_RISK',
  FLIGHT_DIVERTED: 'FLIGHT_DIVERTED',
  FLIGHT_CANCELLED: 'FLIGHT_CANCELLED',
  FLIGHT_REINSTATED: 'FLIGHT_REINSTATED',
  FLIGHT_MULTIPLE_UPDATES: 'FLIGHT_MULTIPLE_UPDATES',
  PERSONAL_TRIP: 'PERSONAL_TRIP',
};
export const tripDetailsTranslationKeys = {
  CANCEL_ALL_TICKETS_WARNING: 'CANCEL_ALL_TICKETS_WARNING',
  TOTAL: 'TOTAL',
  TRAIN_TO: 'TRAIN_TO',
  RAIL_INFO: 'RAIL_INFO',
  FLIGHT_INFO: 'FLIGHT_INFO',
  UPDATE: 'UPDATE',
  PAYMENT_DETAILS: 'PAYMENT_DETAILS',
  SHARE_VIA_EMAIL: 'SHARE_VIA_EMAIL',
  SHARE_THIS_TRIP: 'SHARE_THIS_TRIP',
  WHERE_TO_RECEIVE_EMAIL: 'WHERE_TO_RECEIVE_EMAIL',
  SEND_EMAIL: 'SEND_EMAIL',
  BACK_TO_TRIPS: 'BACK_TO_TRIPS',
  TRIP_NOT_FOUND: 'TRIP_NOT_FOUND',
  CONFIRMATION: 'CONFIRMATION',
  ITEM_CONFIRMATION_NUMBER: 'ITEM_CONFIRMATION_NUMBER',
  BOOKING_ID: 'BOOKING_ID',
  BOOKING_ID_LABEL: 'BOOKING_ID_LABEL',
  HOTEL_REFERENCE: 'HOTEL_REFERENCE',
  HOTEL_REFERENCE_LABEL: 'HOTEL_REFERENCE_LABEL',
  AIRLINE_REFERENCE: 'AIRLINE_REFERENCE',
  AIRLINE_REFERENCE_LABEL: 'AIRLINE_REFERENCE_LABEL',
  SPOTNANA_REFERENCE_LABEL: 'SPOTNANA_REFERENCE_LABEL',
  SPOTNANA_REFERENCE: 'SPOTNANA_REFERENCE',
  COLLECTION_REFERENCE: 'COLLECTION_REFERENCE',
  TRIP_STARTS: 'TRIP_STARTS',
  TRIP_ENDS: 'TRIP_ENDS',
  CANCEL: 'CANCEL',
  CHANGE: 'CHANGE',
  ADD_ITEM: 'ADD_ITEM',
  READ_MORE: 'READ_MORE',
  VIEW_MORE_AMENITIES: 'VIEW_MORE_AMENITIES',
  BOOKING_HISTORY: 'BOOKING_HISTORY',
  EXCHANGED_ON: 'EXCHANGED_ON',
  BOOKED_ON: 'BOOKED_ON',
  CANCELLED_ON: 'CANCELLED_ON',
  UPDATED_ON: 'UPDATED_ON',
  BOOKING_HISTORY_NAME: 'BOOKING_HISTORY_NAME',
  // flights
  FLIGHTS: 'FLIGHTS',
  REFUND_DETAILS: 'REFUND_DETAILS',
  CONFIRM_CANCELLATION: 'CONFIRM_CANCELLATION',
  FARE_INFORMATION: 'FARE_INFORMATION',
  PRICE_BREAKDOWN: 'PRICE_BREAKDOWN',
  REFUND_AMOUNT: 'REFUND_AMOUNT',
  ORIGINAL_FARE: 'ORIGINAL_FARE',
  PAID_SEAT: 'PAID_SEAT',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  GRAND_TOTAL: 'GRAND_TOTAL',
  FLIGHT_TO: 'FLIGHT_TO',
  FLIGHTS_TO: 'FLIGHTS_TO',
  FLIGHT_TO_DESTINATION: 'FLIGHT_TO_DESTINATION',
  ARRIVAL: 'ARRIVAL',
  DEPARTURE: 'DEPARTURE',
  DURATION: 'DURATION',
  LAYOVER: 'LAYOVER',
  OPERATED_BY: 'OPERATED_BY',
  OPERATED_BY_OPERATOR: 'OPERATED_BY_OPERATOR',
  FLIGHT_DETAILS: 'FLIGHT_DETAILS',
  TRAVELLER_DETAILS: 'TRAVELLER_DETAILS',
  TRAVELERS: 'TRAVELERS',
  FLIGHT: 'FLIGHT',
  TERMINAL: 'TERMINAL',
  GATE: 'GATE',
  ONLINE_CHECK_IN: 'ONLINE_CHECK_IN',
  CANCEL_FLIGHT: 'CANCEL_FLIGHT',
  DOWNLOAD_RECEIPT: 'DOWNLOAD_RECEIPT',
  TSA_NUMBER: 'TSA_NUMBER',
  REDRESS_NUMBER: 'REDRESS_NUMBER',
  SEAT: 'SEAT',
  TICKET_NUMBER: 'TICKET_NUMBER',
  LOYALTY_INFORMATION: 'LOYALTY_INFORMATION',
  SEAT_SELECTION: 'SEAT_SELECTION',
  SEATS_SELECTION: 'SEATS_SELECTION',
  BASE_FARE: 'BASE_FARE',
  TAX_FARE: 'TAX_FARE',
  TOTAL_FARE: 'TOTAL_FARE',
  TOTAL_TRIP_FARE: 'TOTAL_TRIP_FARE',
  FARE_RULES_AND_RESTRICTIONS: 'FARE_RULES_AND_RESTRICTIONS',
  STOP_NUMBERS: 'STOP_NUMBERS',
  ORIGINAL_FLIGHT_COST: 'ORIGINAL_FLIGHT_COST',
  ALL_TICKETS_PART_OF_SAME_BOOKING: 'ALL_TICKETS_PART_OF_SAME_BOOKING',
  TO_CANCEL_INDIVIDUAL_TICKET: 'TO_CANCEL_INDIVIDUAL_TICKET',
  CANCEL_ALL_TICKETS: 'CANCEL_ALL_TICKETS',
  // cars
  PICK_UP: 'PICK_UP',
  DROP_OFF: 'DROP_OFF',
  CANCEL_CAR: 'CANCEL_CAR',
  NUMBER_OF_DAYS: 'NUMBER_OF_DAYS',
  DAYS_CAR_RENTAL: 'DAYS_CAR_RENTAL',
  DURATION_CAR_RENTAL: 'DURATION_CAR_RENTAL',
  CAR_TYPE: 'CAR_TYPE',
  SEAT_BELTS: 'SEAT_BELTS',
  TOTAL_BAGS: 'TOTAL_BAGS',
  SMALL_BAGS: 'SMALL_BAGS',
  LARGE_BAGS: 'LARGE_BAGS',
  RENTAL_CONDITIONS: 'RENTAL_CONDITIONS',
  IMPORTANT_INFORMATION: 'IMPORTANT_INFORMATION',
  // hotels
  CHANGE_HOTEL: 'CHANGE_HOTEL',
  CANCEL_HOTEL: 'CANCEL_HOTEL',
  CHECK_IN: 'CHECK_IN',
  CHECK_OUT: 'CHECK_OUT',
  LOCATION: 'LOCATION',
  HOTEL_AMENITIES: 'HOTEL_AMENITIES',
  HOTEL_PHONE_NUMBER: 'HOTEL_PHONE_NUMBER',
  ROOM_AMENITIES: 'ROOM_AMENITIES',
  ROOM_DETAILS: 'ROOM_DETAILS',
  MORE_AMENITIES: 'MORE_AMENITIES',
  SHOW_MORE: 'SHOW_MORE',
  SEE_MORE: 'SEE_MORE',
  VIEW_MAP: 'VIEW_MAP',
  CANCELLATION_TERMS: 'CANCELLATION_TERMS',
  TRIP_NAME_UPDATED: 'TRIP_NAME_UPDATED',
  // Pnr Statuses
  UNKNOWN: 'UNKNOWN',
  INITIATED: 'INITIATED',
  CANCELLED: 'CANCELLED',
  CONFIRMED: 'CONFIRMED',
  GROUP_BOOKING_ON_REQUEST: 'GROUP_BOOKING_ON_REQUEST',
  WAITLISTED: 'WAITLISTED',
  PENDING: 'PENDING',
  AIRLINE_UPGRADE: 'AIRLINE_UPGRADE',
  WAITLIST_CONFIRMED: 'WAITLIST_CONFIRMED',
  BOOKING_DENIED_CONTACT_SUPPORT: 'BOOKING_DENIED_CONTACT_SUPPORT',
  NO_SHOW: 'NO_SHOW',
  CONTACT_SUPPORT: 'CONTACT_SUPPORT',
  STATUS_CHANGED_CONTACT_SUPPORT: 'STATUS_CHANGED_CONTACT_SUPPORT',
  SCHEDULE_CHANGE: 'SCHEDULE_CHANGE',
  SEGMENT_REQUEST: 'SEGMENT_REQUEST',
  SCHEDULE_CHANGE_WAITLISTED_BOOKING: 'SCHEDULE_CHANGE_WAITLISTED_BOOKING',
  REQUEST_PENDING: 'REQUEST_PENDING',
  WAITLISTED_NOT_CONFIRMED: 'WAITLISTED_NOT_CONFIRMED',
  SCHEDULE_CHANGE_NOT_CONFIRMED: 'SCHEDULE_CHANGE_NOT_CONFIRMED',
  SCHEDULE_CHANGE_PENDING_STATUS: 'SCHEDULE_CHANGE_PENDING_STATUS',
  MIS_CONNECTION: 'MIS_CONNECTION',
  REQUESTED: 'REQUESTED',
  TICKETED: 'TICKETED',
  VOIDED: 'VOIDED',
  CANCELLED_BY_VENDOR: 'CANCELLED_BY_VENDOR',
  CANCELLATION_IN_PROGRESS: 'CANCELLATION_IN_PROGRESS',
  UNRECOGNIZED: 'UNRECOGNIZED',
  NON_REFUNDABLE: 'NON_REFUNDABLE',
  NO_CHANGES_REFUND: 'NO_CHANGES_REFUND',
  UNTIL: 'UNTIL',
  FREE_CANCELLATION: 'FREE_CANCELLATION',
  LIMO_SERVICE: 'LIMO_SERVICE',
  DRIVER_DETAILS: 'DRIVER_DETAILS',
  NO_OF_DAYS: 'NO_OF_DAYS',
  OTHER_TRAVEL_ITEM: 'OTHER_TRAVEL_ITEM',
  START_DATE: 'START_DATE',
  END_DATE: 'END_DATE',
  DESCRIPTION: 'DESCRIPTION',
  CONTACT_AGENTS: 'CONTACT_AGENTS',
  CANCELLATION_AND_EXCHANGE_INFORMATION: 'CANCELLATION_AND_EXCHANGE_INFORMATION',
  TRAVELER_INFORMATION: 'TRAVELER_INFORMATION',
  REFUND: 'REFUND',
  YES: 'YES',
  NO: 'NO',
  REFUND_TYPE: 'REFUND_TYPE',
  PARTIAL_REFUND: 'PARTIAL_REFUND',
  FULL_REFUND: 'FULL_REFUND',
  ORIGINAL_PRICE: 'ORIGINAL_PRICE',
  CURRENCY: 'CURRENCY',
  AMOUNT: 'AMOUNT',
  REFUND_AMOUNT_ERROR_MSG: 'REFUND_AMOUNT_ERROR_MSG',
  FREE_CANCELLATION_MSG: 'FREE_CANCELLATION_MSG',
  CANCEL_CAR_RESERVATION: 'CANCEL_CAR_RESERVATION',
  CANCEL_LIMO_RESERVATION: 'CANCEL_LIMO_RESERVATION',
  YOUR_CURRENT_RESERVATION: 'YOUR_CURRENT_RESERVATION',
  BACK_TO_TRIPS_OVERVIEW: 'BACK_TO_TRIPS_OVERVIEW',
  VIEW_HOTEL: 'VIEW_HOTEL',
  AT: 'AT',
  NON_REFUNDABLE_MSG: 'NON_REFUNDABLE_MSG',
  CANCEL_RESERVATION: 'CANCEL_RESERVATION',
  CANCEL_FLIGHT_RESERVATION: 'CANCEL_FLIGHT_RESERVATION',
  CANCEL_HOTEL_RESERVATION: 'CANCEL_HOTEL_RESERVATION',
  CANCEL_RAIL_RESERVATION: 'CANCEL_RAIL_RESERVATION',
  CANCEL_MISC_RESERVATION: 'CANCEL_MISC_RESERVATION',
  CANCEL_FLIGHT_SUCCESS_MSG: 'CANCEL_FLIGHT_SUCCESS_MSG',
  CANCEL_CAR_SUCCESS_MSG: 'CANCEL_CAR_SUCCESS_MSG',
  CANCEL_LIMO_SUCCESS_MSG: 'CANCEL_LIMO_SUCCESS_MSG',
  CANCEL_HOTEL_SUCCESS_MSG: 'CANCEL_HOTEL_SUCCESS_MSG',
  CANCEL_RAIL_SUCCESS_MSG: 'CANCEL_RAIL_SUCCESS_MSG',
  CANCEL_MISC_SUCCESS_MSG: 'CANCEL_MISC_SUCCESS_MSG',
  REFUND_MSG: 'REFUND_MSG',
  REFUND_MSG_BREX_POINTS: 'REFUND_MSG_BREX_POINTS',
  TOTAL_REFUND: 'TOTAL_REFUND',
  CONTACT_US: 'CONTACT_US',
  AMOUNT_WILL_GET_CREDITED_MSG: 'AMOUNT_WILL_GET_CREDITED_MSG',
  PLEASE: 'PLEASE',
  CANCEL_YOUR_ITENARY_MSG: 'CANCEL_YOUR_ITENARY_MSG',
  FLIGHT_PLURAL: 'FLIGHT_PLURAL',
  AIRLINE_CREDIT: 'AIRLINE_CREDIT',
  PENALTY: 'PENALTY',
  FLIGHT_CREDITS: 'FLIGHT_CREDITS',
  ELIGIBLE_REFUND: 'ELIGIBLE_REFUND',
  BAGGAGE: 'BAGGAGE',
  FEES_AND_TAXES: 'FEES_AND_TAXES',
  EXTRAS: 'EXTRAS',
  SEAT_PREFERENCE: 'SEAT_PREFERENCE',
  TO: 'TO',
  CANCELLATION_POLICY: 'CANCELLATION_POLICY',
  EMPTY_TRIPS_MSG: 'EMPTY_TRIPS_MSG',
  UPDATE_RESERVATION: 'UPDATE_RESERVATION',
  UPDATE_FLIGHT_RESERVATION: 'UPDATE_FLIGHT_RESERVATION',
  SHOW: 'SHOW',
  ITEMS: 'ITEMS',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  REINSTATED: 'REINSTATED',
  BACK_TO_TRIP_DETAILS: 'BACK_TO_TRIP_DETAILS',
  SPOTNANA_SERVICE_FEE: 'SPOTNANA_SERVICE_FEE',
};

export const IUserFacingStatusEnum = UserFacingStatus;

export const hotelCancelAllowedStatusList = [
  UserFacingStatus.CONFIRMED_STATUS,
  UserFacingStatus.APPROVAL_REQUESTED_STATUS,
];

export const bookingStatusMap: Record<BookingHistoryStatus, string> = {
  [PnrBookingHistoryBookingStatus.BOOKED]: tripDetailsTranslationKeys.BOOKED_ON,
  [PnrBookingHistoryBookingStatus.EXCHANGED]: tripDetailsTranslationKeys.EXCHANGED_ON,
  [PnrBookingHistoryBookingStatus.CANCELLED]: tripDetailsTranslationKeys.CANCELLED_ON,
  [PnrBookingHistoryBookingStatus.UPDATED]: tripDetailsTranslationKeys.UPDATED_ON,
};

export const listTripActivityTypeMap: Record<ListTripActivityType, TripActivityType[]> = {
  history: [TripActivityType.BookingHistory],
  task: [TripActivityType.AgentTask],
  qc: [TripActivityType.QcCheckUpdate, TripActivityType.QcRunUpdate],
};

export const OtherAncillaryTypeToNameMap: Record<AirAncillariesResponseAncillaryAncillaryType, string> = {
  [AirAncillariesResponseAncillaryAncillaryType.EARLY_BIRD]: defineMessage('EarlyBird Check-In®'),
  [AirAncillariesResponseAncillaryAncillaryType.WIFI]: defineMessage('WiFi'),
  [AirAncillariesResponseAncillaryAncillaryType.ANCILLARY_TYPE_UNKNOWN]: defineMessage('Ancillary'),
  [AirAncillariesResponseAncillaryAncillaryType.UNRECOGNIZED]: defineMessage('Ancillary'),
};

export const OtherAncillaryTypeMap: Record<AirAncillariesResponseAncillaryAncillaryType, AncillaryType> = {
  [AirAncillariesResponseAncillaryAncillaryType.EARLY_BIRD]: AncillaryType.EarlyBird,
  [AirAncillariesResponseAncillaryAncillaryType.WIFI]: AncillaryType.Wifi,
  [AirAncillariesResponseAncillaryAncillaryType.ANCILLARY_TYPE_UNKNOWN]: AncillaryType.Unknown,
  [AirAncillariesResponseAncillaryAncillaryType.UNRECOGNIZED]: AncillaryType.Unknown,
};
