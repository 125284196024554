/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { OwnershipLabel } from './ownership-label';
import { CardCompany } from './card-company';
// May contain unused imports in some cases
// @ts-ignore
import { CardExpiry } from './card-expiry';
// May contain unused imports in some cases
// @ts-ignore
import { PostalAddress } from './postal-address';

/**
 * User card info
 * @export
 * @interface Card
 */
export interface Card {
  /**
   * Unique identifier for this card
   * @type {string}
   * @memberof Card
   */
  id?: string;
  /**
   * Type of card
   * @type {string}
   * @memberof Card
   */
  type?: CardTypeEnum;
  /**
   *
   * @type {CardCompany}
   * @memberof Card
   */
  company?: CardCompany;
  /**
   * Name on card
   * @type {string}
   * @memberof Card
   */
  name?: string;
  /**
   *
   * @type {PostalAddress}
   * @memberof Card
   */
  address?: PostalAddress;
  /**
   * Card number
   * @type {string}
   * @memberof Card
   */
  number: string;
  /**
   * Expiry month
   * @type {number}
   * @memberof Card
   * @deprecated
   */
  expiryMonth?: number;
  /**
   * Expiry year
   * @type {number}
   * @memberof Card
   * @deprecated
   */
  expiryYear?: number;
  /**
   * Card cvv number
   * @type {string}
   * @memberof Card
   */
  cvv?: string;
  /**
   * Card Label
   * @type {string}
   * @memberof Card
   */
  label?: string;
  /**
   * Native currency of the card.
   * @type {string}
   * @memberof Card
   */
  currency?: string;
  /**
   * Spotnana partner card id.
   * @type {string}
   * @memberof Card
   */
  externalId?: string;
  /**
   * ID of the vault used for creating the card.
   * @type {string}
   * @memberof Card
   */
  vaultId?: string;
  /**
   *
   * @type {CardExpiry}
   * @memberof Card
   */
  expiry?: CardExpiry;
  /**
   * Ownership label of the card whether it is personal, corporate or central.
   * @type {string}
   * @memberof Card
   */
  ownershipLabel?: OwnershipLabel;
}

export const CardTypeEnum = {
  Unknown: 'UNKNOWN',
  Credit: 'CREDIT',
  Debit: 'DEBIT',
} as const;

export type CardTypeEnum = (typeof CardTypeEnum)[keyof typeof CardTypeEnum];
