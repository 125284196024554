import { useMemo } from 'react';
import first from 'lodash/first';
import type { OBTFeatureConfigObtFeatureTypeEnum } from '../types';
import { OBTFeaturePreferencesPreferenceVisibilityEnum } from '../types';
import { useShopFeaturesApplicability } from '../queries';

export type IOBTShopFeaturesConfig = Record<
  OBTFeatureConfigObtFeatureTypeEnum,
  OBTFeaturePreferencesPreferenceVisibilityEnum
>;

type IUseOBTShopFeaturesReturnType = {
  limoOBTFeaturesConfig: IOBTShopFeaturesConfig;
  hotelOBTFeaturesConfig: IOBTShopFeaturesConfig;
  carOBTFeaturesConfig: IOBTShopFeaturesConfig;
  airOBTFeaturesConfig: IOBTShopFeaturesConfig;
  isLoading: boolean;
};

export const useOBTShopFeaturesForUserId = (userId: string): IUseOBTShopFeaturesReturnType => {
  const { data: shopFeaturesApplicabilityResponse, isLoading } = useShopFeaturesApplicability(
    { userId },
    { enabled: !!userId },
  );

  const { limoOBTFeaturesConfig, hotelOBTFeaturesConfig, carOBTFeaturesConfig, airOBTFeaturesConfig } = useMemo(() => {
    const limoOBTFeaturesConfigMap = {} as Record<
      OBTFeatureConfigObtFeatureTypeEnum,
      OBTFeaturePreferencesPreferenceVisibilityEnum
    >;

    shopFeaturesApplicabilityResponse?.limoFeaturesConfig.forEach((curr) => {
      limoOBTFeaturesConfigMap[curr.obtFeatureType] =
        first(curr.obtFeaturePreferences)?.preference.visibility ??
        OBTFeaturePreferencesPreferenceVisibilityEnum.Enabled;
    });

    const hotelOBTFeaturesConfigMap = {} as Record<
      OBTFeatureConfigObtFeatureTypeEnum,
      OBTFeaturePreferencesPreferenceVisibilityEnum
    >;

    shopFeaturesApplicabilityResponse?.hotelFeaturesConfig.forEach((curr) => {
      hotelOBTFeaturesConfigMap[curr.obtFeatureType] =
        first(curr.obtFeaturePreferences)?.preference.visibility ??
        OBTFeaturePreferencesPreferenceVisibilityEnum.Enabled;
    });

    const carOBTFeaturesConfigMap = {} as Record<
      OBTFeatureConfigObtFeatureTypeEnum,
      OBTFeaturePreferencesPreferenceVisibilityEnum
    >;

    shopFeaturesApplicabilityResponse?.carFeaturesConfig?.forEach((curr) => {
      carOBTFeaturesConfigMap[curr.obtFeatureType] =
        first(curr.obtFeaturePreferences)?.preference.visibility ??
        OBTFeaturePreferencesPreferenceVisibilityEnum.Enabled;
    });

    const airOBTFeaturesConfigMap = {} as Record<
      OBTFeatureConfigObtFeatureTypeEnum,
      OBTFeaturePreferencesPreferenceVisibilityEnum
    >;

    shopFeaturesApplicabilityResponse?.airFeaturesConfig?.forEach((curr) => {
      airOBTFeaturesConfigMap[curr.obtFeatureType] =
        first(curr.obtFeaturePreferences)?.preference.visibility ??
        OBTFeaturePreferencesPreferenceVisibilityEnum.Enabled;
    });

    return {
      limoOBTFeaturesConfig: limoOBTFeaturesConfigMap,
      hotelOBTFeaturesConfig: hotelOBTFeaturesConfigMap,
      carOBTFeaturesConfig: carOBTFeaturesConfigMap,
      airOBTFeaturesConfig: airOBTFeaturesConfigMap,
    };
  }, [shopFeaturesApplicabilityResponse]);

  return {
    limoOBTFeaturesConfig,
    hotelOBTFeaturesConfig,
    carOBTFeaturesConfig,
    airOBTFeaturesConfig,
    isLoading,
  };
};
