import { LimoType } from '../../types/api/v2/obt/model/limo-type';
import { defineMessage } from '../../translations/defineMessage';
import type { ILabelValuePair, ILimoFilterState, ILimoSearchErrorState } from '../../types';
import { LimoDetailsCarTypeEnum, LimoSearchType } from '../../types';
import { emptyICarSuggestion } from '../cars';

export const EnumToCarMapper = (carType?: LimoDetailsCarTypeEnum | number): string => {
  switch (carType) {
    case LimoDetailsCarTypeEnum.StandardCar:
      return 'Standard car';
    case LimoDetailsCarTypeEnum.ExecutiveCar:
      return 'Executive car';
    case LimoDetailsCarTypeEnum.BusinessVan:
      return 'Business van';
    case LimoDetailsCarTypeEnum.FirstClass:
      return 'First class';
    case LimoDetailsCarTypeEnum.SUV:
      return 'SUV';
    case LimoDetailsCarTypeEnum.Sedan:
      return 'Sedan';
    case LimoDetailsCarTypeEnum.ExecutiveSedan:
      return 'Executive sedan';
    case LimoDetailsCarTypeEnum.LuxurySedan:
      return 'Luxury sedan';
    case LimoDetailsCarTypeEnum.ExecutiveVan:
      return 'Executive van';
    case LimoDetailsCarTypeEnum.BusinessSedan:
      return 'Business sedan';
    default:
      return '';
  }
};

export const limoTypeKeyLabel: { value: LimoType; label: string }[] = [
  {
    value: LimoType.StandardCar,
    label: EnumToCarMapper(LimoDetailsCarTypeEnum.StandardCar),
  },
  {
    value: LimoType.ExecutiveCar,
    label: EnumToCarMapper(LimoDetailsCarTypeEnum.ExecutiveCar),
  },
  {
    value: LimoType.BusinessVan,
    label: EnumToCarMapper(LimoDetailsCarTypeEnum.BusinessVan),
  },
  {
    value: LimoType.FirstClass,
    label: EnumToCarMapper(LimoDetailsCarTypeEnum.FirstClass),
  },
  {
    value: LimoType.Suv,
    label: EnumToCarMapper(LimoDetailsCarTypeEnum.SUV),
  },
  {
    value: LimoType.Sedan,
    label: EnumToCarMapper(LimoDetailsCarTypeEnum.Sedan),
  },
  {
    value: LimoType.ExecutiveSedan,
    label: EnumToCarMapper(LimoDetailsCarTypeEnum.ExecutiveSedan),
  },
  {
    value: LimoType.LuxurySedan,
    label: EnumToCarMapper(LimoDetailsCarTypeEnum.LuxurySedan),
  },
  {
    value: LimoType.ExecutiveVan,
    label: EnumToCarMapper(LimoDetailsCarTypeEnum.ExecutiveVan),
  },
  {
    value: LimoType.BusinessSedan,
    label: EnumToCarMapper(LimoDetailsCarTypeEnum.BusinessSedan),
  },
];

export const limoEmptyErrorState: ILimoSearchErrorState = {
  pickup: '',
  dropOff: '',
  from: '',
  to: '',
};

export const getLimoHourlyOptions = (): ILabelValuePair[] => [
  { label: defineMessage('2 hours'), value: '2' },
  { label: defineMessage('3 hours'), value: '3' },
  { label: defineMessage('4 hours'), value: '4' },
  { label: defineMessage('5 hours'), value: '5' },
  { label: defineMessage('6 hours'), value: '6' },
  { label: defineMessage('7 hours'), value: '7' },
  { label: defineMessage('8 hours'), value: '8' },
  { label: defineMessage('9 hours'), value: '9' },
  { label: defineMessage('10 hours'), value: '10' },
  { label: defineMessage('11 hours'), value: '11' },
  { label: defineMessage('12 hours'), value: '12' },
  { label: defineMessage('13 hours'), value: '13' },
  { label: defineMessage('14 hours'), value: '14' },
  { label: defineMessage('15 hours'), value: '15' },
  { label: defineMessage('16 hours'), value: '16' },
  { label: defineMessage('17 hours'), value: '17' },
  { label: defineMessage('18 hours'), value: '18' },
  { label: defineMessage('19 hours'), value: '19' },
  { label: defineMessage('20 hours'), value: '20' },
  { label: defineMessage('21 hours'), value: '21' },
  { label: defineMessage('22 hours'), value: '22' },
  { label: defineMessage('23 hours'), value: '23' },
];

export const defaultLimoFilters: ILimoFilterState = {
  limoTypes: [],
  vendors: [],
};

export const emptyLimoSearchRequestParams = {
  dateTime: { from: '', to: '' },
  pickup: emptyICarSuggestion,
  dropOff: emptyICarSuggestion,
  searchType: LimoSearchType.TRANSFER,
  duration: undefined,
  userOrgIds: [],
};
