/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The type of custom field.
 * @export
 * @enum {string}
 */

export const CustomFieldType = {
    Question: 'QUESTION',
    Meeting: 'MEETING',
    Budget: 'BUDGET',
    BrexToken: 'BREX_TOKEN'
} as const;

export type CustomFieldType = typeof CustomFieldType[keyof typeof CustomFieldType];



