import produce from 'immer';
import difference from 'lodash/difference';
import differenceBy from 'lodash/differenceBy';
import groupBy from 'lodash/groupBy';
import pick from 'lodash/pick';
import unionBy from 'lodash/unionBy';

import first from 'lodash/first';
import { emptySegmentLocation, searchForm } from '../../constants/flights';
import AutocompleteService from '../../services/AutocompleteService';
import { airGetOptionLabel, getEmptySegment } from '../../transformers/flights';
import type { ICommonI18nMessage } from '../../translations/defineMessage';
import { defineCommonMessage } from '../../translations/defineMessage';
import type { IAirSuggestion, IFlightSearchSegmentState } from '../../types/air-segments';
import { AirLocationTypeEnum } from '../../types/air-segments';
import type { Airport } from '../../types/api/v2/obt/model';
import { PreferredLocationLabel } from '../../types/api/v2/obt/model/preferred-location-label';
import type { IAutocompleteSuggestion } from '../../types/autocomplete';
import type { IFlightTripTypeProps } from '../../types/flight';
import { isAirSuggestion } from '../../types/flight';
import type { OriginOrDestination } from '../../types/rail';
import { getArrayFromArrayOrNode } from '../common';

const requiredAirSuggestionFields = ['type', 'code', 'name', 'place', 'countryCode'] as const;

export const getFlightOppositeSide = (side: OriginOrDestination): OriginOrDestination =>
  side === 'origin' ? 'destination' : 'origin';

const getUpdatedErrors = (
  index: number,
  side: OriginOrDestination,
  errors: IFlightTripTypeProps['errors'],
): IFlightTripTypeProps['errors'] => {
  const updatedErrors = produce(errors, (draftErrors) => {
    if (draftErrors[index]) {
      draftErrors[index][side] = '';
    }
  });

  return updatedErrors;
};

export function isAirportType(option: IAirSuggestion): boolean {
  if (!option.type) {
    return false;
  }

  return [
    AirLocationTypeEnum.AIRPORT,
    AirLocationTypeEnum.CITY_AIRPORT,
    PreferredLocationLabel.Home,
    PreferredLocationLabel.Other,
    PreferredLocationLabel.Work,
  ].includes(option.type);
}

export function isAnyCityType(option: IAirSuggestion): boolean {
  return option.type === AirLocationTypeEnum.CITY || option.type === AirLocationTypeEnum.CITY_GROUP;
}

export function isAirSuggestionSelected(selectedValues: IAirSuggestion[], option: IAirSuggestion) {
  let isSelected = false;
  if (isAirportType(option)) {
    isSelected = selectedValues.some((selectedOption) => selectedOption.code === option.code);
  } else if (isAnyCityType(option)) {
    const cityAirports = option.data || [];
    const cityAirportCodes = cityAirports.map((airport) => airport.airportCode);
    const selectedAirportCodes = selectedValues.map((selectedOption) => selectedOption.code);
    isSelected = difference(cityAirportCodes, selectedAirportCodes).length === 0;
  }

  return isSelected;
}

function sanitizeAirSuggestions(suggestions: IAirSuggestion[]) {
  if (suggestions.length < 2) {
    return suggestions;
  }

  const filteredSuggestions = suggestions.filter((s) => s.type && s.code);
  if (filteredSuggestions.length < 1) {
    return [emptySegmentLocation];
  }

  return filteredSuggestions;
}

function getAirportsForAirSuggestion(suggestion: IAirSuggestion): IAirSuggestion[] {
  let airports: IAirSuggestion[] = [];
  if (isAirportType(suggestion)) {
    airports = [suggestion];
  } else if (isAnyCityType(suggestion)) {
    const cityAirports = suggestion.data || [];
    const cityAirportSuggestions = cityAirports.map((airport) =>
      AutocompleteService.getAirportNode(airport, AirLocationTypeEnum.AIRPORT, cityAirports),
    );
    airports = cityAirportSuggestions;
  }

  return airports;
}

const getUpdatedSegmentsForSelectedValue = (
  key: OriginOrDestination,
  index: number,
  segments: IFlightTripTypeProps['segments'],
  value: IAutocompleteSuggestion | null,
): IFlightTripTypeProps['segments'] => {
  if (!value) {
    return segments;
  }

  if (!isAirSuggestion(value)) {
    return segments;
  }

  const currentSelectedValues = getArrayFromArrayOrNode(segments[index][key]);
  const isAlreadySelected = isAirSuggestionSelected(currentSelectedValues, value);
  const airportsOfValue = getAirportsForAirSuggestion(value);

  let newSegments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[] = [];

  if (isAlreadySelected) {
    const updatedValues = differenceBy(currentSelectedValues, airportsOfValue, (option) => option.code);
    const sanitizedUpdatedValues = sanitizeAirSuggestions(updatedValues);

    newSegments = produce(segments, (draftSegments) => {
      draftSegments[index][key] = sanitizedUpdatedValues;
    });
  } else {
    const updatedValues = unionBy(airportsOfValue, currentSelectedValues, (option) => option.code);
    const sanitizedUpdatedValues = sanitizeAirSuggestions(updatedValues);

    newSegments = produce(segments, (draftSegments) => {
      draftSegments[index][key] = sanitizedUpdatedValues;
    });
  }

  return newSegments;
};

export function getUpdatedSegmentsWithSelectedAirports(
  key: OriginOrDestination,
  index: number,
  segments: IFlightTripTypeProps['segments'],
  airports: IAirSuggestion[],
) {
  const sanitizedUpdatedValues = sanitizeAirSuggestions(airports);
  return produce<IFlightTripTypeProps['segments']>(segments, (draftSegments) => {
    draftSegments[index][key] = sanitizedUpdatedValues;
  });
}

export const updateMultiCityTripMultiSelectSegmentLocation = (
  key: OriginOrDestination,
  index: number,
  segments: IFlightTripTypeProps['segments'],
  errors: IFlightTripTypeProps['errors'],
  updateSegments: (segments: IFlightTripTypeProps['segments']) => void,
  updateErrors: (errors: IFlightTripTypeProps['errors']) => void,
  value: IAutocompleteSuggestion | null,
): void => {
  const newSegments = getUpdatedSegmentsForSelectedValue(key, index, segments, value);
  updateSegments(newSegments);

  const updatedErrors = getUpdatedErrors(index, key, errors);
  updateErrors(updatedErrors);
};

export const updateOneWayTripMultiSelectSegmentLocation = (
  key: OriginOrDestination,
  segments: IFlightTripTypeProps['segments'],
  errors: IFlightTripTypeProps['errors'],
  updateSegments: (segments: IFlightTripTypeProps['segments']) => void,
  updateErrors: (errors: IFlightTripTypeProps['errors']) => void,
  value: IAutocompleteSuggestion | null,
): void => {
  const newSegments = getUpdatedSegmentsForSelectedValue(key, 0, segments, value);
  updateSegments(newSegments);

  const updatedErrors = getUpdatedErrors(0, key, errors);
  updateErrors(updatedErrors);
};

export const updateRoundTripMultiSelectSegmentLocation = (
  key: OriginOrDestination,
  segments: IFlightTripTypeProps['segments'],
  errors: IFlightTripTypeProps['errors'],
  updateSegments: (segments: IFlightTripTypeProps['segments']) => void,
  updateErrors: (errors: IFlightTripTypeProps['errors']) => void,
  value: IAutocompleteSuggestion | null,
): void => {
  const oppositeSide = getFlightOppositeSide(key);

  const updatedFirstSegments = getUpdatedSegmentsForSelectedValue(key, 0, segments, value);
  const newSegments = getUpdatedSegmentsForSelectedValue(oppositeSide, 1, updatedFirstSegments, value);

  updateSegments(newSegments);

  const updatedFirstSegmentErrors = getUpdatedErrors(0, key, errors);
  const updatedErrors = getUpdatedErrors(1, oppositeSide, updatedFirstSegmentErrors);
  updateErrors(updatedErrors);
};

export const updateRoundtripMultiSelectSegmentsWithAirports = (
  key: OriginOrDestination,
  segments: IFlightTripTypeProps['segments'],
  errors: IFlightTripTypeProps['errors'],
  updateSegments: (segments: IFlightTripTypeProps['segments']) => void,
  updateErrors: (errors: IFlightTripTypeProps['errors']) => void,
  values: IAutocompleteSuggestion[],
): void => {
  const oppositeSide = getFlightOppositeSide(key);
  const airSuggestions: IAirSuggestion[] = values.filter(isAirSuggestion);
  const updatedOneSideSegments = getUpdatedSegmentsWithSelectedAirports(key, 0, segments, airSuggestions);
  const updatedReturnSideSegments = getUpdatedSegmentsWithSelectedAirports(
    oppositeSide,
    1,
    updatedOneSideSegments,
    airSuggestions,
  );
  updateSegments(updatedReturnSideSegments);

  const updatedFirstSegmentErrors = getUpdatedErrors(0, key, errors);
  const updatedErrors = getUpdatedErrors(1, oppositeSide, updatedFirstSegmentErrors);
  updateErrors(updatedErrors);
};

export const updateOnewayMultiSelectSegmentsWithAirports = (
  key: OriginOrDestination,
  segments: IFlightTripTypeProps['segments'],
  errors: IFlightTripTypeProps['errors'],
  updateSegments: (segments: IFlightTripTypeProps['segments']) => void,
  updateErrors: (errors: IFlightTripTypeProps['errors']) => void,
  values: IAutocompleteSuggestion[],
): void => {
  const oppositeSide = getFlightOppositeSide(key);
  const airSuggestions: IAirSuggestion[] = values.filter(isAirSuggestion);
  const updatedOneSideSegments = getUpdatedSegmentsWithSelectedAirports(key, 0, segments, airSuggestions);

  updateSegments(updatedOneSideSegments);

  const updatedFirstSegmentErrors = getUpdatedErrors(0, key, errors);
  const updatedErrors = getUpdatedErrors(1, oppositeSide, updatedFirstSegmentErrors);
  updateErrors(updatedErrors);
};

export const updateMultiCityMultiSelectSegmentsWithAirports = (
  key: OriginOrDestination,
  segments: IFlightTripTypeProps['segments'],
  errors: IFlightTripTypeProps['errors'],
  index: number,
  updateSegment: (index: number, segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>) => void,
  updateErrors: (index: number, error: IFlightSearchSegmentState<string>) => void,
  values: IAutocompleteSuggestion[],
): void => {
  const oppositeSide = getFlightOppositeSide(key);
  const airSuggestions: IAirSuggestion[] = values.filter(isAirSuggestion);
  const updatedOneSideSegments = getUpdatedSegmentsWithSelectedAirports(key, index, segments, airSuggestions);

  updateSegment(index, updatedOneSideSegments[index]);

  const updatedFirstSegmentErrors = getUpdatedErrors(index, key, errors);
  const updatedErrors = getUpdatedErrors(1, oppositeSide, updatedFirstSegmentErrors);
  updateErrors(index, updatedErrors[index]);
};

export function getSegmentsGroupedByCity(suggestions: IAirSuggestion[]): IAirSuggestion[] {
  const groupedSuggestions = groupBy(suggestions, 'data.0.groupInfo.groupId');
  const allGroupValues = Object.values(groupedSuggestions);

  const finalSuggestions: IAirSuggestion[] = allGroupValues.flatMap((groupedValues) => {
    const groupInfo = groupedValues[0].data?.[0]?.groupInfo;

    // If there is no groupInfo, that mean these are all airports. They can be
    // forwarded as is.
    if (!groupInfo) {
      return groupedValues.map((val) => pick(val, requiredAirSuggestionFields));
    }

    // In case of group, find all associated airports.
    const allGroupAirports = groupedValues[0].groupAirports || [];
    const areAllAirportsSelected = allGroupAirports.length === groupedValues.length;

    // If all airports are already selected and selected group has associated
    // IATA city code, just send the city instead of sending individual
    // airports.
    if (areAllAirportsSelected && groupInfo.groupCityCode) {
      let cityNode = AutocompleteService.getAirCityNode(groupInfo, allGroupAirports, AirLocationTypeEnum.CITY);
      // This is in case suggestions are coming from Airport Autocomplete, wherein _GROUP is added
      if (cityNode.code.includes('_GROUP')) {
        cityNode = {
          ...cityNode,
          code: cityNode.code.replace('_GROUP', ''),
        };
      }
      return [pick(cityNode, requiredAirSuggestionFields)];
    }

    // If not all values of group are selected or group doesn't have IATA city
    // code, pass them as individual airports.
    return groupedValues.map((val) => pick(val, requiredAirSuggestionFields));
  });

  return finalSuggestions;
}

export function getDisplayValueForAirSuggestions(suggestions: IAirSuggestion[] | null): string {
  if (!suggestions) {
    return '';
  }

  if (suggestions.length < 1) {
    return '';
  }

  if (suggestions.length === 1) {
    return airGetOptionLabel(suggestions[0]);
  }

  const groupedSuggestions = groupBy(suggestions, 'data.0.groupInfo.groupId');
  const allGroupValues = Object.values(groupedSuggestions);

  const optionLabels = allGroupValues.flatMap((groupedValues) => {
    const groupInfo = groupedValues[0].data?.[0]?.groupInfo;

    if (!groupInfo) {
      return groupedValues.map((val) => val.code);
    }

    const allGroupAirports = groupedValues[0].groupAirports || [];
    const areAllAirportsSelected = allGroupAirports.length === groupedValues.length;

    if (areAllAirportsSelected) {
      return [groupInfo.mainAirportLocation?.name || groupInfo.groupId];
    }

    return groupedValues.map((val) => val.code);
  });

  return optionLabels.join(', ');
}

export function getDisplayLabelForAirSuggestions(
  suggestions: IAirSuggestion[] | null,
): ICommonI18nMessage<object> | null {
  if (!suggestions) {
    return null;
  }

  if (suggestions.length < 1) {
    return null;
  }

  const groupedSuggestions = groupBy(suggestions, 'data.0.groupInfo.groupId');
  const allGroupValues = Object.values(groupedSuggestions);

  if (allGroupValues.length > 1) {
    return null;
  }

  const firstGroupValues = allGroupValues[0];
  const groupInfo = firstGroupValues[0].data?.[0]?.groupInfo;

  if (!groupInfo) {
    return null;
  }

  const allGroupAirports = firstGroupValues[0].groupAirports || [];
  const areAllAirportsSelected = allGroupAirports.length === firstGroupValues.length;

  if (!areAllAirportsSelected) {
    return null;
  }

  return defineCommonMessage('All Airports');
}

export function getAirportsFromSuggestions(suggestions: IAutocompleteSuggestion[]): Airport[] {
  return suggestions
    .flatMap((val) => {
      if (!isAirSuggestion(val)) {
        return [null];
      }

      if (isAirportType(val)) {
        if (val.data) {
          return val.data;
        }

        const airportNode: Airport = {
          airportCode: val.code,
          airportName: val.name || '',
          cityCode: '',
        };
        return [airportNode];
      }

      if (isAnyCityType(val)) {
        return val.data || [];
      }

      return [null];
    })
    .filter((val): val is Airport => !!val);
}

export function getMergedAirSuggestions(
  firstSuggestions: IAutocompleteSuggestion[],
  secondSuggestions: IAutocompleteSuggestion[],
): IAutocompleteSuggestion[] {
  const firstAirports = getAirportsFromSuggestions(firstSuggestions);
  const secondAirports = getAirportsFromSuggestions(secondSuggestions);

  const groupedAirports = groupBy([...firstAirports, ...secondAirports], 'airportCode');
  const uniqAirports = Object.values(groupedAirports).map((airports) => {
    const defaultAirport = airports[0];
    const airportWithGroupInfo = airports.find((a) => a.groupInfo);

    return airportWithGroupInfo || defaultAirport;
  });

  return AutocompleteService.getGroupedAirSuggestions(uniqAirports);
}

export function transformSegmentsToSegmentsGroupedByCity(
  segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[],
  tripType: string,
) {
  const finalSegments =
    tripType === searchForm.AIR_SEARCH_FORM_TRIP_TYPE_ONE_WAY.toString()
      ? [first(segments) ?? getEmptySegment()]
      : segments;

  return finalSegments.map((seg) => ({
    ...seg,
    origin: getSegmentsGroupedByCity(getArrayFromArrayOrNode(seg.origin)),
    destination: getSegmentsGroupedByCity(getArrayFromArrayOrNode(seg.destination)),
  }));
}

export const getAirportCodeForAirSuggestion = (airSuggestion: IAirSuggestion[] | IAirSuggestion) => {
  const nodes = getArrayFromArrayOrNode(airSuggestion);
  return nodes.map((node) => node.code);
};

export const getAirportCodesFromSegments = (
  segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[],
) => {
  const originAirportCodes = segments.map((segment) => getAirportCodeForAirSuggestion(segment.origin));
  const destinationAirportCodes = segments.map((segment) => getAirportCodeForAirSuggestion(segment.destination));

  return {
    originAirportCodes,
    destinationAirportCodes,
  };
};
