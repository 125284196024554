/* eslint-disable */
import { Amenity, Uta, Upa } from '../../../v1/obt/air/routehappy';
import { DateTime, Duration } from '../../../v1/common/date_time';
import {
  FlightNumber,
  PassengerType,
  PassengerAge,
  FareCategory,
  Cabin,
  Co2EmissionDetail,
  POS,
  SourceInfo,
  Alliance,
} from '../../../v1/obt/air/air_common';
import { Money, PaymentMethod } from '../../../v1/common/money';
import { PolicyInfo } from '../../../v1/obt/common/policy_info';
import { ThirdPartySource } from '../../../v1/obt/supplier/third_party_info';
import { Preference, PreferredType } from '../../../v1/obt/common/common';
import { UnusedCreditInfo } from './unused_credits';

export interface Data {
  flights: DataFlight[];
  legs: DataLeg[];
  taxes: DataTax[];
  amenities: Amenity[];
  utas: Uta[];
  upas: Upa[];
}

export interface DataFlight {
  departureDateTime: DateTime;
  arrivalDateTime: DateTime;
  duration: Duration;
  origin: DataFlightAirport;
  destination: DataFlightAirport;
  marketing: FlightNumber;
  operating: FlightNumber;
  equipment?: DataFlightEquipment;
  /**
   *  Sometimes flights make immediate stops called hidden stops. An example of a hidden stop
   *  could be planned refueling of the aircraft.
   */
  hiddenStops: DataFlightHiddenStop[];
  stats?: DataFlightStatistics;
  meals: DataFlightMeal[];
  /** Distance covered by the flight segment in miles. */
  distanceInMiles: number;
  /**
   * Free text operating airline name that has to be displayed to the user if
   * present. The field is known as Banner in Sabre.
   * Example: SKYWEST DBA UNITED EXPRESS
   */
  operatingAirlineName: string;
  /** flag to indicate the subject to government approval is required. */
  subjectToGovtApproval: boolean;
}

export interface DataFlightEquipment {
  code: string;
  type: string;
}

export interface DataFlightAirport {
  airport: string;
  terminal: string;
  /** Country Code */
  country: string;
  countryName: string;
  cityName: string;
  airportName: string;
  gate: string;
}

export interface DataFlightHiddenStop {
  /**
   * For NDC Southwest, we do not always get information about arrival date/time
   * of hidden stops. Therefore, these fields should be optional.
   */
  arrivalDateTime?: DateTime;
  departureDateTime?: DateTime;
  duration?: Duration;
  airport?: DataFlightAirport;
}

export interface DataFlightStatistics {
  risk: DataFlightStatisticsDelayRisk;
  /**
   * This list has probability of flight getting delayed for different
   * time intervals (eg : [80,10,5,5] represents 80% chance of
   * <30 min delay, 10% chance of 30-60 min delay and so on).
   */
  delayDistribution: number[];
}

/**
 * This represents the risk of flight being delayed (eg: "LOW",
 * "MODERATE", etc)
 */
export enum DataFlightStatisticsDelayRisk {
  LOW = 0,
  MODERATE = 1,
  HIGH = 2,
  SEVERE = 3,
  UNKNOWN = 4,
  UNRECOGNIZED = -1,
}

export interface DataLeg {
  flightIndices: number[];
  /** Unique identifier for leg. */
  legId: string;
}

export interface DataTax {
  amount?: Money;
  description: string;
}

export interface FareAmount {
  base: Money;
  tax: Money;
}

export interface CreditAmount {
  /** Amount that will be stored as airline credits for future redemption during an exchange or a refund process. */
  amount?: Money;
}

export interface PaxInfo {
  paxType: PassengerType;
  numPax: number;
  paxAge?: PassengerAge;
  farePerPax?: FareAmount;
  taxIndices: number[];
  fareComponents: PaxInfoFareComponent[];
  merchantFeePerPax?: Money;
  /**
   * Amount that will be stored as airline credits for future redemption during an exchange or a refund process.
   * This is an optional field which might have some value only when any of the legs to be removed in the air
   *  exchange flow has refund in the form mentioned above.
   */
  creditAmountPerPax?: CreditAmount;
  /** Airline fees including service charges and card fees, like OB Fees */
  airlineFeesPerPax?: Money;
}

export interface PaxInfoFareComponent {
  legInfo: PaxInfoFareComponentLegInfo[];
  /**
   * The fare basis code contains information about the fare. For eg,
   * "QUAJZNB3"
   */
  fareBasisCode: string;
  /**
   * Fare type code defines the type of fare. For eg, Fare type "END" stands
   * for Economy Non-Discountable.
   */
  fareType: string;
  /**
   * Ticket designator indicates the type of discount applied on fare. For eg,
   * "ACMSA".
   */
  ticketDesignator: string;
  /**
   * The account code is used to get corporate negotiated price. For eg,
   * "SPxxx" is the account code for Spotify.
   */
  corpAccountCode: string;
  /** The base fare for this fare component group. */
  baseFare?: Money;
  /** Q-surcharges are miscellaneous charges. */
  qSurcharge?: Money;
  /**
   * Stopover fee is sometimes charged if the passenger takes a stop in
   * between the journey.
   */
  stopoverFee?: Money;
  /** Fare differentials are additional charges over base fare. */
  fareDifferential?: Money;
  fareDirectionality?: PaxInfoFareComponentFareDirectionality;
  /** If true, this fare component belongs to a side trip journey */
  isSideTrip: boolean;
  /**
   * ID of a fare component. This ID is used to link and merge two fare components which
   * are stored as different fare components in proto. This is an optional field, and can be
   * empty. The fare components with no ID should be considered as an independent fare component.
   */
  fareComponentId: string;
  /**
   * Group Id to link a set of fare components. This ID is optional, and if empty then the fare
   * components for a single pax type are considered to form a group.
   */
  groupId: string;
  /** Pricing codes used to price this fare */
  pricingQualifiers?: PaxInfoFareComponentPricingQualifiers;
  /**
   * The passengerType of the fare, this can be different from the passengerType of the user for
   * which this fare is booked.
   */
  farePaxType: PassengerType;
  /** The passenger age group to which the fare belongs */
  farePaxAge?: PassengerAge;
}

export interface PaxInfoFareComponentLegInfo {
  flightInfo: PaxInfoFareComponentLegInfoFlightInfo[];
  utaIndices: number[];
  /** @deprecated */
  validatingAirline: string;
  fareType: FareType;
  brandCode: string;
  brandName: string;
  fareCategory: FareCategory;
  vendorProgramType: VendorProgramType;
  /** Optional field containing total fare for all passengers for this leg */
  totalLegFare?: FareAmount;
  /**
   * If leg is preferred or not
   *
   * @deprecated
   */
  preferredType: PreferredType[];
  /** Optional field containing per pax fare for this leg */
  legFarePerPax?: FareAmount;
  /** Indicates whether leg is preferred, blocked or not preferred. */
  preferences: Preference[];
  /** Optional field containing total cheapest fare for all passengers for this leg */
  totalCheapestLegFare?: FareAmount;
  /** Optional field containing per pax the cheapest fare for this leg. */
  cheapestLegFarePerPax?: FareAmount;
  multiSupplierInfo?: PaxInfoFareComponentLegInfoMultiSupplierInfo;
}

export interface PaxInfoFareComponentLegInfoFlightInfo {
  cabin: Cabin;
  bookingCode: string;
  amenitiesIndices: number[];
  upaIndices: number[];
  sourceCabin: string;
  co2EmissionDetail?: Co2EmissionDetail;
  flightId: string;
}

/** Contains information regarding cloning an itinerary */
export interface RateOptionCloneInfo {
  /** Indicates whether a particular rate option is closest to the original itinerary or not. */
  isClosestMatch: boolean;
}

export interface PaxInfoFareComponentLegInfoMultiSupplierInfo {
  /** Third party source for this leg */
  source: ThirdPartySource;
  /** Point of sale for this leg */
  pos?: POS;
}

export interface PaxInfoFareComponentPricingQualifiers {
  tourCode: string;
  endorsement: string;
  ticketDesignator: string;
}

export interface PaxInfoFareComponentFareDirectionality {
  /** The direction of this fare */
  direction: PaxInfoFareComponentFareDirectionalityDirection;
  /** The type of fare (one way, round trip, etc.) */
  type: PaxInfoFareComponentFareDirectionalityType;
}

export enum PaxInfoFareComponentFareDirectionalityDirection {
  UNKNOWN_DIRECTION = 0,
  EASTERN_HEMISPHERE = 1,
  WESTERN_HEMISPHERE = 2,
  ATLANTIC = 3,
  PACIFIC = 4,
  UNRECOGNIZED = -1,
}

export enum PaxInfoFareComponentFareDirectionalityType {
  UNKNOWN_TYPE = 0,
  ONE_WAY = 1,
  ROUND_TRIP_LEG1 = 2,
  ROUND_TRIP_LEG2 = 3,
  UNRECOGNIZED = -1,
}

export interface RateOption {
  rateOptionId: string;
  category: Cabin;
  totalFare: FareAmount;
  changeFee?: { amount: Money };
  paxInfo: PaxInfo[];
  /** @deprecated */
  validatingAirline: string;
  /** @deprecated */
  policyInfo?: PolicyInfo;
  /**
   * NDC offers certain subcategories for offers like
   * premium economy lowest, premium economy highest, flex, latitude etc.
   */
  offerName: string;
  /** @deprecated */
  fareType: FareType;
  source: ThirdPartySource;
  ticketType: RateOptionTicketType;
  /**
   * This list will have same number of PolicyInfo objects as the number of legs
   * in current itinerary.
   */
  policyInfos: PolicyInfo[];
  /**
   * Amount that will be stored as airline credits for future redemption during an exchange or a refund process.
   * This is an optional field which might have some value only when any of the legs to be removed in the air
   *  exchange flow has refund in the form mentioned above.
   */
  creditAmount?: CreditAmount;
  /**
   * This is for internal use. Contains information about data source for each leg. If source info
   * is same for all legs, only one object will be present in sourceInfos array.
   */
  sourceInfos: SourceInfo[];
  /** The point of sale where this 'rate option' was shopped from. */
  pos?: POS;
  /** Cheapest published fare encountered for that itinerary */
  cheapestPublishedFare?: FareAmount;

  // List of allowed forms of payment allowed for this rate option.
  allowedFop: PaymentMethod[];
  /** This represents that whether the itinerary is voidable or not. */
  isVoidable: boolean;
  /** Total merchant fee applied on this rate option */
  merchantFee?: Money;
  /** Flight credits can be applied to this rate option */
  hasApplicableCredits: boolean;
  /**
   * Percentage of private fare charged as merchant fee. This is for internal
   * use.
   */
  /** @deprecated Use merchantFee instead */
  merchantFeePercentage: number;

  /** Contains information regarding cloning an itinerary */
  cloneInfo?: RateOptionCloneInfo;

  /** Time marker for hold booking. If its passed the flight cannot be put on hold */
  holdDeadline?: DateTime;
  showRawFareRules: boolean;
  /** Whether the given rate option is selectable by user for booking. */
  isUnselectable: boolean;
  /**
   * Airline within the source. This is required for suppliers like NDC where we have multiple
   * airlines in a single source.
   */
  airline: string;
}

export enum RateOptionTicketType {
  SINGLE = 0,
  MULTI = 1,
  UNRECOGNIZED = -1,
}

export interface Itinerary {
  legIndices: number[];
  rateOptions: RateOption[];
}

export interface ItineraryGroup {
  legGroups: ItineraryGroupLegGroup[];
}

export interface ItineraryGroupLegGroup {
  legOptions: ItineraryGroupLegGroupLegOption[];
}

export interface ItineraryGroupLegGroupLegOption {
  legIndex: number;
  rateOptions: RateOption[];
}

export interface Metadata {
  availableAirlines: MetadataAirline[];
  fareCategoryView: MetadataFareCategoryView;
  /** Current Itinerary for use cases like air modify/exchange. */
  currentItinerary?: MetadataCurrentItinerary;
  /** List of all vendor programs in this air search response */
  vendorPrograms: MetadataVendorProgramInfo[];
  /** Median of economy fares with max stop 1 and max layover 120 minutes */
  llf?: Money;
  /** list of all flight restrictions per flight such as seat booking, loyalty etc */
  flightRestrictions: MetadataFlightRestrictions[];
  /**
   * fare statistics of all logical itineraries per shelf.
   * In case of multi pax, only primary i.e. adult fare is included in stats.
   */
  logicalFareStatistics: MetadataFareStatistics[];
  /** Flag indicating that itineraries are priced on a leg-by-leg basis */
  legByLegPricing: boolean;
  /**
   * List of enabled fare categories which should be shown regardless of the
   * fares sent to the consumer.
   */
  enabledFareCategories: MetadataFareCategoryWithCode[];
  /** Alliances applicable for the response */
  applicableAlliances: Alliance[];
  /** Contains information for every shelf. */
  shelfInfo: MetadataShelfInfo[];
}
export interface MetadataShelfInfo {
  /** Fare Categories like basic, economy, business. */
  fareCategory?: FareCategory;
  /** Minimum price of a fare corresponding to a category. */
  totalFareAmount?: FareAmount;
  /**
   * Duration of the itinerary which corresponds to the one which has the
   * min fare price.
   */
  duration?: Duration;
}
export interface MetadataAirline {
  /** 2 letter IATA code. */
  code: string;
  name: string;
}

export interface MetadataVendorProgramInfo {
  discountCode: string;
  /** UA Pass plus program info */
  uaPassPlus?: MetadataVendorProgramInfoUaPassPlus | undefined;
}

export interface MetadataVendorProgramInfoUaPassPlus {
  /** ID of card to be used for bookings */
  cardId: string;
}

export interface MetadataCurrentItinerary {
  /**
   * Index of the itinerary in the array AirSearchResponse#itineraries.
   * Defaults to -1 if current itinerary is not present.
   */
  index: number;
  /** Selected rate option id in the current itinerary. Defaults to empty string if not present. */
  rateOptionId: string;
}

export enum MetadataFareCategoryView {
  UNKNOWN = 0,
  CABIN = 1,
  NGS = 2,
  UNRECOGNIZED = -1,
}

/**
 * Fare category to name map. Fare Category enum is serialized to a number
 * value. API Consumer won't be able to translate the name of the category
 * using this number unless a string code is sent. This object maps a fare
 * category to its coded name.
 */
export interface MetadataFareCategoryWithCode {
  fareCategory?: FareCategory;
  fareCategoryCode: string;
}

export interface PaginationParams {
  /** The page number for which response has been returned. */
  currentPageNumber: number;
  /** The total number of pages available. */
  totalNumPages: number;
  /** The total number of results available. */
  totalNumResults: number;
  /** Number of objects in single page. */
  currentPageSize: number;
}

export interface AirSearchResponse {
  searchId: string;
  data: Data;
  itineraries: Itinerary[];
  itineraryGroups: ItineraryGroup[];
  currSelectedItinerary?: Itinerary;
  metadata?: Metadata;
  paginationParams?: PaginationParams;
  unusedCredit: UnusedCreditInfo[];
  /** List of cheapest options after applying filters */
  cheapestOptions: Itinerary[];
}

export enum FareTypeEnum {
  PUBLISHED = 0,
  CORPORATE = 1,
  PRIVATE_MARKETED_PUBLIC = 2,
  PRIVATE = 3,
  UNRECOGNIZED = -1,
}

export enum VendorProgramTypeEnum {
  UNKNOWN_PROGRAM_TYPE = 0,
  UA_PASS_PLUS = 1,
  UNRECOGNIZED = -1,
}

export enum DataFlightMeal {
  UNKNOWN = 0,
  ALCOHOL_BEVERAGES_FOR_PURCHASE = 1,
  BREAKFAST = 2,
  COLD_MEAL = 3,
  COMPLIMENTARY_ALCOHOL_BEVERAGES = 4,
  CONTINENTAL_BREAKFAST = 5,
  DINNER = 6,
  FOOD_FOR_PURCHASE = 7,
  FOOD_AND_BEVERAGE_FOR_PURCHASE = 8,
  HOT_MEAL = 9,
  LUNCH = 10,
  MEAL = 11,
  NO_MEAL_SERVICE = 12,
  REFRESHMENT = 13,
  REFRESHMENT_FOR_PURCHASE = 14,
  SNACK = 15,
  UNRECOGNIZED = -1,
}

export type FareType = 0 | 1 | 2 | 3 | -1;

export type VendorProgramType = 0 | 1 | -1;

export interface MetadataFlightRestrictions {
  /** index of flight in AirSearchResponse > Data > Flights list */
  flightIndex: number;
  /** restrictions such as seat booking, loyalty etc */
  restrictions: MetadataFlightRestrictionsRestriction[];
}

export enum MetadataFlightRestrictionsRestriction {
  NONE = 0,
  PRE_BOOKING_SEAT_NOT_ALLOWED = 1,
  POST_BOOKING_SEAT_NOT_ALLOWED = 2,
  SEAT_BOOKING_NOT_ALLOWED_DUE_TO_BRAND = 3,
  SEAT_BOOKING_NOT_ALLOWED = 4,
  SEAT_BOOKING_NOT_ALLOWED_OPEN_SEATING = 5,
  SEAT_NOT_GUARANTEED_WARNING = 6,
  SEAT_BOOKING_NOT_ALLOWED_DUE_TO_CODESHARE = 7,
  UNRECOGNIZED = -1,
}

export interface MetadataFareStatistics {
  fareCategory?: FareCategory;
  minimum?: MetadataFareStatisticsStatisticsItem;
  median?: MetadataFareStatisticsStatisticsItem;
  maximum?: MetadataFareStatisticsStatisticsItem;
}

export interface MetadataFareStatisticsStatisticsItem {
  /**
   * deprecated, use totalFareAmount instead
   *
   * @deprecated
   */
  totalFare?: Money;
  baseFare?: Money;
  totalFareAmount?: FareAmount;
  /** Duration of the itinerary */
  duration?: Duration;
}
