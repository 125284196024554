import { CARBON_EMISSION_DELTA_THRESHOLD } from '../../constants/flights';
import { ICo2EmissionsVariantEnum } from '../../types/common';

export function getCo2EmissionsVariant(totalCo2EmissionDeltaInPercentage: number | null): ICo2EmissionsVariantEnum {
  if (!totalCo2EmissionDeltaInPercentage) {
    return ICo2EmissionsVariantEnum.Neutral;
  }

  // Anything in the range of +/- 5% is considered neutral
  if (totalCo2EmissionDeltaInPercentage > CARBON_EMISSION_DELTA_THRESHOLD) {
    return ICo2EmissionsVariantEnum.Positive;
  }
  if (totalCo2EmissionDeltaInPercentage < -CARBON_EMISSION_DELTA_THRESHOLD) {
    return ICo2EmissionsVariantEnum.Negative;
  }
  return ICo2EmissionsVariantEnum.Neutral;
}
