import React, { PropsWithChildren, ReactElement, useCallback, useLayoutEffect, useState } from 'react';
import { MARRIOTT_STATE_PARAM_VALUE, GENESYS_CHAT_STATE_PARAM_VALUE } from './constants';

interface IExternalAuthCodeContext {
  authCode: string | null;
  authState: string | null;
  resetAuthState: () => void;
}

export const ExternalAuthCodeContext = React.createContext<IExternalAuthCodeContext | null>(null);

/**
 * This hook provides access to the External Auth Code context.
 * The ExternalAuthCodeProvider is rendered before auth provider and checks if there is auth code in the url with
 * a specific state which is passed as prop into provider. If it is present, it stores the code and state value in
 * the context and removes it from the url in order to prevent triggering the internal sso auth flow.
 * Reference: https://github.com/aws-amplify/amplify-js/issues/9208
 */
export function useExternalAuthCodeContext(): IExternalAuthCodeContext {
  const context = React.useContext(ExternalAuthCodeContext);

  if (!context) {
    throw new Error('useExternalAuthCodeContext must be used within a ExternalAuthCodeProvider');
  }

  return context;
}

// TODO: update to specific state name when backend is ready and state is formalized
const urlAuthStateValues = [MARRIOTT_STATE_PARAM_VALUE, GENESYS_CHAT_STATE_PARAM_VALUE];

export const ExternalAuthCodeProvider: React.FC<PropsWithChildren> = ({ children }): ReactElement => {
  const [authCode, setAuthCode] = useState<string | null>(null);
  const [authState, setAuthState] = useState<string | null>(null);

  useLayoutEffect(() => {
    const urlObject = new URL(window.location.href);
    const urlAuthCode = urlObject.searchParams.get('code');
    const urlAuthState = urlObject.searchParams.get('state');

    if (urlAuthCode && urlAuthState && urlAuthStateValues.includes(urlAuthState)) {
      setAuthCode(urlAuthCode);
      setAuthState(urlAuthState);
      urlObject.searchParams.delete('code');
      urlObject.searchParams.delete('state');
      window.history.pushState({ path: urlObject.toString() }, '', urlObject.toString());
    }
  }, []);

  const handleResetAuthState = useCallback(() => {
    setAuthCode(null);
    setAuthState(null);
  }, []);

  return (
    <ExternalAuthCodeContext.Provider
      value={{
        authCode,
        authState,
        resetAuthState: handleResetAuthState,
      }}
    >
      {children}
    </ExternalAuthCodeContext.Provider>
  );
};
