import { Persona } from '../../types/api/v2/obt/model/persona';
import { useGetUser } from '../../queries/users';
import { useLoggedInUserId } from '../../providers/AuthProvider';

export const useUserPersona = (userId: string | undefined = undefined) => {
  const loggedInUserId = useLoggedInUserId();
  const { data: user } = useGetUser(
    {
      userId: userId ?? loggedInUserId?.userId?.id ?? '',
    },
    { enabled: !!loggedInUserId?.userId?.id || !!userId },
  );

  const persona = user?.persona as Persona | undefined;
  return {
    persona,
    isGuestPersona: persona === Persona.Guest,
    isEmployeePersona: persona === Persona.Employee,
    isAdhocPersona: persona === Persona.Adhoc,
  };
};
