import invert from 'lodash/invert';
import moment from 'moment';
import { EmergencyContactRelationEnumV1 } from '../types/api/v1/obt/common/emergency_contact';
import { EmergencyContactRelationEnum } from '../types/api/v2/obt/model/emergency-contact';
import { LengthUnitEnum } from '../types/api/v1/common/length';
import { NameSuffix } from '../types/api/v1/common/name';

import type { PhoneNumberType } from '../types/api/v1/common/phone_number';
import { PhoneNumberCountryCodeSourceEnum } from '../types/api/v1/common/phone_number';
import { CreatePnrStatus, PnrStatusV1 } from '../types/api/v1/obt/pnr/pnr';
import { QuestionFormat } from '../types/api/v1/obt/policy/user_defined_entity';
import { NameSuffix as NameSuffixV2 } from '../types/api/v2/obt/model/name-suffix';
import {
  PhoneNumberCountryCodeSourceEnum as PhoneNumberCountryCodeSourceEnumV2,
  PhoneNumberTypeEnum,
} from '../types/api/v2/obt/model/phone-number';
import type { IAutoCompleteConfig, IAutocompleteType } from '../types/autocomplete';
import type { ILinkData, IPopoverOrigins } from '../types/common';
import { CustomFieldQuestionType } from '../types/customFields';
import { IPhoneNumberTypeEnum } from '../types/traveler';
// eslint-disable-next-line import/no-cycle
import type { IRailCo2EmissionPerTravelType } from '../types/rail';
import { TravelClass } from '../types/api/v1/obt/rail/rail_common';
import { IncompleteUserActionName } from '../types/userActions';

export const LengthUnits = {
  [LengthUnitEnum.MILE]: 'mi',
  [LengthUnitEnum.KM]: 'km',
  [LengthUnitEnum.UNKNOWN_UNIT]: '',
  [LengthUnitEnum.UNRECOGNIZED]: '',
};

export const WeightUnits = {
  1: 'Kg',
  2: 'Lbs',
};

export const ParseIntRadix = 10;

export const EMPTY_REGEX = /(?:)/;

// TODO:Krishna Discuss on Datetime formats
export const dateFormats = {
  DATE: 'MMM DD, YYYY',
  DATE__TIME_HR12: 'MMM DD, YYYY hh:mm A',
  DATE_TIME_HR12_ZONE_OFFSET: 'MMM DD, YYYY hh:mm A (Z)',
  DATE_MONTH: 'DD MMM',
  DATE_FULL_MONTH: 'DD MMMM YYYY',
  DATE_SHORT_MONTH: 'DD MMM YYYY',
  DAY: 'ddd',
  DAY_DATE: 'ddd, MMM DD',
  DAY_DATE__TIME_HR12: 'ddd, MMM DD - hh:mm A',
  DATE_DAY: 'MMM DD, ddd',
  FULL_DAY_DATE: 'dddd, MMM DD',
  DATE_YEAR: 'MMM DD, YYYY',
  DATE_DAY_YEAR: 'MMM DD YYYY, dddd',
  DAY_DATE_YEAR: 'ddd, MMM DD, YYYY',
  FULL_DAY_DATE_YEAR: 'dddd, MMMM D, YYYY',
  /*
   * DAY_DATE_YEAR_WO_DAY is required because dayjs is unable to parse
   * `ddd` part of the date string
   *
   * Please, refer to https://github.com/iamkun/dayjs/issues/1802 for more details
   * Short overview: loading `customParseFormat` plugin (required to enable custom string as parse format)
   * leads to excluding `d{1,4}` token from the list of formatting tokens
   */
  DAY_DATE_YEAR_WO_DAY: '[ddd, ]MMM DD, YYYY',
  DATE_MONTH_DAY: 'DD MMM, ddd',
  ISO: 'YYYY-MM-DDTHH:mm:ss',
  ISO_W_TIMEZONE: 'YYYY-MM-DD[T]HH:mm:ss[Z]',
  /*
   * ISO_WO_SECONDS was added because dayjs causes `Invalid Date` result when
   * ISO format is used and no `seconds/ss` part is presented
   */
  ISO_WO_SECONDS: 'YYYY-MM-DDTHH:mm',
  /*
   * For the same reason as above
   */
  ISO_SHORT: 'YYYY-MM-DD',

  CARD_DATE: 'MM/YYYY',
  LONG_DATE: 'DD/MM/YYYY',
  LONG_DATE_US: 'MM/DD/YYYY',
  LONG_DATE_REVERSE: 'YYYY/MM/DD',
  LONG_DATE_REVERSE_FORMAT: 'YYYY-MM-DD',
  LONG_DATE_HR12_TIME: 'DD/MM/YYYY hh:mm A',
  LONG_DATE_REVERSE_HR12_TIME: 'YYYY/MM/DD hh:mm A',
  LONG_DATE_REVERSE_HR24_TIME_HYPHENATED: 'YYYY-MM-DD HH:mm:ss',
  LONG_DATE_REVERSE_HR24_TIME: 'YYYY/MM/DD HH:mm:ss',
  /*
   * LONG_DATE_REVERSE_HR24_TIME_WITHOUT_SECONDS was added because dayjs causes 'Invalid Date' when
   * when trying to parse in LONG_DATE_REVERSE_HR24_TIME format and "seconds" part is not presented
   */
  LONG_DATE_REVERSE_HR24_TIME_WITHOUT_SECONDS: 'YYYY/MM/DD HH:mm',
  LONG_DATE_REVERSE_RAILS_PARAMS: 'YYYY%2FMM%2FDD',
  DAY_DATE_HR12_TIME: 'ddd, MMM DD hh:mm A',
  DAY_DATE_HR12_TIME_ZONE: 'ddd, MMM DD hh:mm A z',
  SHORT_DATE: 'MMM DD',
  SHORT_DATE__TIME_HR12: 'MMM DD, hh:mm A',
  MONTH: 'MMM',
  ONLY_DATE: 'DD',
  MONTH_YEAR: 'MMM YY',
  MONTH_FULL_YEAR: 'MMM YYYY',
  FULL_MONTH_YEAR: 'MMMM YYYY',
  ONLY_YEAR: 'YYYY',
  DATE_WITH_SHORT_YEAR: 'MMM DD, YY',
  TIME: 'hh:mm A',
  DATE_AT_TIME_HR12_ZONE: 'MMM DD, YYYY [at] hh:mm A (z)',
  DATE_AT_TIME_HR12_ZONE_WITHOUT_BRACKET: 'MMM DD, YYYY [at] hh:mm A z',
  DATE_AT_TIME_HR12_ZONE_OFFSET: 'MMM DD, YYYY [at] hh:mm A (Z)',
  DATE_AT_TIME_HR24_ZONE: 'MMM DD, YYYY [at] hh:mm z',
  DATE_AT_TIME_HR24: 'dddd, D MMM [at] HH:mm',
};

export const AXIOS_CANCELLED_CALL = 'AXIOS_CANCELLED_CALL';
export const INVALID_AUTH_TOKEN = 'Canceled due to Invalid Auth token in private app';

// TODO(Ashit): The below constant needs to be deprecated in favour of the map variable
export const TRAVELER_INFO_HEADER_KEY = 'spotnana-traveler-info';
export const IMPERSONATION_ACCESS_TOKEN = 'impersonation-access-token';
export const REQUEST_HEADERS: Record<string, string> = {
  SPOTNANA_WORKFLOW_ID: 'workflow-id',
  SELECTED_ORG_ID: 'selected-org-id',
  TRAVELER_INFO_HEADER_KEY: 'spotnana-traveler-info',
  AGENT_TASK_REQUEST: 'agent-task-filters',
  PERSONAL_PROFILE_TRAVELER_READ: 'personal-profile',
  X_SESSION_ID: 'x-session-id',
  X_TMC_ID: 'x-tmc-id',
  X_ORG_ID: 'x-org-id',
};

export const timeFormats = {
  HR12: 'hh:mm A',
  HR12_TIME: 'hh:mm',
  HR12_PERIOD: 'A',
  HR24: 'HH:mm',
  HOUR12: 'hh',
  MINUTES: 'mm',
  UNIX: 'X',
};

// TODO: remove the eslint-disable
// eslint-disable-next-line no-restricted-syntax
export const todayDate = (): string => moment().format(dateFormats.LONG_DATE_REVERSE_FORMAT);

export const NANOS = 10 ** 9;

export const autocompleteTypes: { [k in IAutocompleteType]: IAutocompleteType } = {
  AIR: 'AIR',
  HOTEL: 'HOTEL',
  CAR: 'CAR',
  RAIL: 'RAIL',
  POLICY_LOCATION: 'POLICY_LOCATION',
  CAR_VENDOR: 'CAR_VENDOR',
  HOTEL_VENDOR: 'HOTEL_VENDOR',
  PLACES: 'PLACES',
};

export const autocompleteConfig: IAutoCompleteConfig = {
  AIR: 3,
  HOTEL: 3,
  CAR: 3,
  RAIL: 3,
  POLICY_LOCATION: 3,
  CAR_VENDOR: 2,
  HOTEL_VENDOR: 2,
  PLACES: 2,
};

export const defaultPopOverAnchorOrigin: IPopoverOrigins = {
  vertical: 'bottom',
  horizontal: 'left',
};
export const defaultPopOverTransformOrigin: IPopoverOrigins = {
  vertical: 'top',
  horizontal: 'center',
};

export const globalTranslationKeys = {
  CHILD: 'CHILD',
  FRIEND: 'FRIEND',
  OTHER: 'OTHER',
  PARENT: 'PARENT',
  SPOUSE: 'SPOUSE',
  UNKNOWN_RELATION: 'UNKNOWN_RELATION',
  UNRECOGNIZED: 'UNRECOGNIZED',
  REMOVE: 'REMOVE',
  REVERT: 'REVERT',
  EDIT_SEARCH: 'EDIT_SEARCH',
  PAYMENT: 'PAYMENT',
  DELAYED_INVOICING: 'DELAYED_INVOICING',
  PAID: 'PAID',
  TMC_CARD: 'TMC_CARD',
  TF_PAY: 'TF_PAY',
  CHEQUE_OR_WIRE: 'CHEQUE_OR_WIRE',
  PAYMENT_OPTIONS: 'PAYMENT_OPTIONS',
  COMPANY_CREDIT_CARD: 'COMPANY_CREDIT_CARD',
  BILLED_TO_COMPANY: 'BILLED_TO_COMPANY',
  CHARGED_VIA_DELAYED_INVOICING: 'CHARGED_VIA_DELAYED_INVOICING',
  CHARGING_METHOD: 'CHARGING_METHOD',
  DONT_WORRY_ABOUT_THIS: 'DONT_WORRY_ABOUT_THIS',
  RATE: 'RATE',
  HISTORY: 'HISTORY',
  CLICK_TO_REFRESH: 'CLICK_TO_REFRESH',
  DEFAULT_ITEM_UNDONE_ACTION: 'DEFAULT_ITEM_UNDONE_ACTION',
  DEFAULT_ITEM_UNDONE_ACTION_SUBTITLE: 'DEFAULT_ITEM_UNDONE_ACTION_SUBTITLE',
  OK: 'OK',
  FOR: 'FOR',
  DEFAULT: 'DEFAULT',
  SET_DEFAULT: 'SET_DEFAULT',
  // ToDo: Airat or Bhavan: move this into common ns.
  CONFIRM_ACTION: 'CONFIRM_ACTION',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  ENABLE: 'ENABLE',
  DISABLE: 'DISABLE',
  APPLICABLE_TO: 'APPLICABLE_TO',
  CODE: 'CODE',
  CURRENCY: 'CURRENCY',
  QUEUE: 'QUEUE',
  PCC_LABEL: 'PCC_LABEL',
  UNDONE_ACTION: 'UNDONE_ACTION',
  SIGN_IN: 'SIGN_IN',
  LOGOUT: 'LOGOUT',
  FLIGHTS: 'FLIGHTS',
  HOME: 'HOME',
  TRIP: 'TRIP',
  PROGRAM: 'PROGRAM',
  SETTINGS: 'SETTINGS',
  COMPANY: 'COMPANY',
  TMC_SETTING: 'TMC_SETTING',
  POLICIES: 'POLICIES',
  USERS: 'USERS',
  ANALYTICS: 'ANALYTICS',
  ANALYTICS_DASHBOARD: 'ANALYTICS_DASHBOARD',
  REPORTS: 'REPORTS',
  HOTELS: 'HOTELS',
  CARS: 'CARS',
  CAR: 'CAR',
  AIR: 'AIR',
  HOTEL: 'HOTEL',
  RAILS: 'RAILS',
  REPORT_ISSUE: 'REPORT_ISSUE',
  CREATING_REPORT_ISSUE: 'CREATING_REPORT_ISSUE',
  SELECT_PROFILE: 'SELECT_PROFILE',
  PROFILE_INFO: 'PROFILE',
  TRIPS: 'TRIPS',
  TRIP_DETAILS: 'TRIP_DETAILS',
  CHANGE_PROFILE: 'CHANGE_PROFILE',
  FETCHING_PROFILE: 'FETCHING_PROFILE',
  UNABLE_TO_LOAD: 'UNABLE_TO_LOAD',
  BOOKING_SENT_FOR_APPROVAL: 'BOOKING_SENT_FOR_APPROVAL',
  BOOKING_DONE: 'BOOKING_DONE',
  OUT_OF_POLICY: 'OUT_OF_POLICY',
  IN_POLICY: 'IN_POLICY',
  VIEW_MORE: 'VIEW_MORE',
  DETAILS: 'DETAILS',
  CREATE_SHELL_PNR: 'CREATE_SHELL_PNR',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  RESET: 'RESET',
  COMPANY_LEGAL_NAME: 'COMPANY_LEGAL_NAME',
  COMPANY_LEGAL_COPYRIGHT: 'COMPANY_LEGAL_COPYRIGHT',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_OF_USE: 'TERMS_OF_USE',
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  DELETED: 'DELETED',
  DELETING: 'DELETING',
  CANCEL: 'CANCEL',
  CONFIRM: 'CONFIRM',
  NO_RESULTS_FOUND: 'NO_RESULTS_FOUND',
  NO_RESULTS_FOUND_DESCRIPTION: 'NO_RESULTS_FOUND_DESCRIPTION',
  COPY_LOCATION: 'COPY_LOCATION',
  TEXT_COPIED_TO_THE_CLIPBOARD: 'TEXT_COPIED_TO_THE_CLIPBOARD',
  CONFIRMATION_NUMBER_COPIED_TO_THE_CLIPBOARD: 'CONFIRMATION_NUMBER_COPIED_TO_THE_CLIPBOARD',
  AIRLINE_REFERENCE_COPIED_TO_THE_CLIPBOARD: 'AIRLINE_REFERENCE_COPIED_TO_THE_CLIPBOARD',
  HOTEL_REFERENCE_COPIED_TO_THE_CLIPBOARD: 'HOTEL_REFERENCE_COPIED_TO_THE_CLIPBOARD',
  SEARCH: 'SEARCH',
  CONFIRM_BOOKING: 'CONFIRM_BOOKING',
  CONTINUE: 'CONTINUE',
  SELECT_REASON: 'SELECT_REASON',
  DATE_PICKER_AND_DATE_INPUT_PLACEHOLDER: 'DATE_PICKER_AND_DATE_INPUT_PLACEHOLDER',
  ABOUT: 'ABOUT',
  ADD_TRAVELER: 'ADD_TRAVELER',
  ADD_TRAVELERS: 'ADD_TRAVELERS',
  TRAVELER: 'TRAVELER',
  ADD_ME: 'ADD_ME',
  SEARCHING_PROFILES: 'SEARCHING_PROFILES',
  SAVE: 'SAVE',
  SAVING: 'SAVING',
  YES: 'YES',
  NO: 'NO',
  LIMO: 'LIMO',
  SELECT: 'SELECT',
  BOOKING_IMPORTER: 'BOOKING_IMPORTER',
  SWIPE_RIGHT_TO_BOOK: 'SWIPE_RIGHT_TO_BOOK',
  SEE_MORE: 'SEE_MORE',
  SAFETY: 'SAFETY',
  TRAVELERS: 'TRAVELERS',
  SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
  MISCELLANEOUS: 'MISCELLANEOUS',
  PHOTOS: 'PHOTOS',
  DAYS: 'DAYS',
  DAY: 'DAY',
  YEARS: 'YEARS',
  NOT_SET: 'NOT_SET',
  NO_LIMIT: 'NO_LIMIT',
  STAR: 'STAR',
  STARS: 'STARS',
  SUPPORT: 'SUPPORT',
  DATE: 'DATE',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  LOADING_COMPANIES: 'LOADING_COMPANIES',
  NO_COMPANIES_FOUND: 'NO_COMPANIES_FOUND',
  PLEASE_TRY_AGAIN: 'PLEASE_TRY_AGAIN',
  NO_COMPANIES_MATCHING_MSG: 'NO_COMPANIES_MATCHING_MSG',
  CHOOSE_CARD: 'CHOOSE_CARD',
  FILTER: 'FILTER',
  CLEAR: 'CLEAR',
  APPLY: 'APPLY',
  SELECT_ALL: 'SELECT_ALL',
  GO_NATIVE_WITH_SPOTNANA: 'GO_NATIVE_WITH_SPOTNANA',
  ALL_INVENTORY_CUSTOMER_SUPPORT: 'ALL_INVENTORY_CUSTOMER_SUPPORT',
  START_BOOKING_WITH_BEST_TRAVEL: 'START_BOOKING_WITH_BEST_TRAVEL',
  TAKE_TO_APP: 'TAKE_TO_APP',
  ACTIVE: 'ACTIVE',
  REQUIRED_FIELDS: 'REQUIRED_FIELDS',
  CLICK_TO_SELECT: 'CLICK_TO_SELECT',
  UNAVAILABLE: 'UNAVAILABLE',
  CHECKOUT: 'CHECKOUT',
  PREVIOUS_TOTAL: 'PREVIOUS_TOTAL',
  UPDATED_TOTAL: 'UPDATED_TOTAL',
  PRICE_UPDATED: 'PRICE_UPDATED',
  TRIP_NAME: 'TRIP_NAME',
  MANDATORY_FIELD: 'MANDATORY_FIELD',
  THIS_CARD_IS_NOT_SUPPORTED: 'THIS_CARD_IS_NOT_SUPPORTED',
  THE_CARD_IS_NOT_SUPPORTED_BY_THE_HOTEL: 'THE_CARD_IS_NOT_SUPPORTED_BY_THE_HOTEL',
  MAXIMUM_SUPPORTED_FILE_SIZES: 'MAXIMUM_SUPPORTED_FILE_SIZES',
  COULD_NOT_LOAD_THE_IMAGE: 'COULD_NOT_LOAD_THE_IMAGE',
  NO_TRAVELER_PROFILES_FOUND: 'NO_TRAVELER_PROFILES_FOUND',
  SEARCH_BY_NAME_OR_EMAIL: 'SEARCH_BY_NAME_OR_EMAIL',
  COPIED: 'COPIED',
  COPY: 'COPY',
  EXPIRY: 'EXPIRY',
  CARD_NUMBER: 'CARD_NUMBER',
  ALL: 'ALL',
  DOWNLOAD_TOOLTIP: 'DOWNLOAD_TOOLTIP',
  CONTACT_SUPPORT: 'CONTACT_SUPPORT',
  INTERNAL_SUB_MENU: 'INTERNAL_SUB_MENU',
  DEBUG_TOOL_LINK: 'DEBUG_TOOL_LINK',
  DEBUG_TOOL_LINK_COPY: 'DEBUG_TOOL_LINK_COPY',
  DEBUG_TOOL_LINK_COPIED: 'DEBUG_TOOL_LINK_COPIED',
  EDIT_OR_ADD: 'EDIT_OR_ADD',
  AGENT: 'AGENT',
  SHOW_OUT_OF_POLICY_OFFERS: 'SHOW_OUT_OF_POLICY_OFFERS',
  HEY: 'HEY',
  HAPPY_BIRTHDAY: 'HAPPY_BIRTHDAY',
  PREFERRED: 'PREFERRED',
  PERSONAL_TRIP_CONFIRMATION_TITLE: 'PERSONAL_TRIP_CONFIRMATION_TITLE',
  PERSONAL_TRIP_CONFIRMATION_SUBTITLE: 'PERSONAL_TRIP_CONFIRMATION_SUBTITLE',
  CORPORATE_TRIP_CONFIRMATION_TITLE: 'CORPORATE_TRIP_CONFIRMATION_TITLE',
  CORPORATE_TRIP_CONFIRMATION_SUBTITLE: 'CORPORATE_TRIP_CONFIRMATION_SUBTITLE',
  PERSONAL_TRIP_OOP_LABEL: 'PERSONAL_TRIP_OOP_LABEL',
  ERRORS: 'ERRORS',
  COMMON: 'COMMON',
  ALL_FARES_CURRENCY_INFO: 'ALL_FARES_CURRENCY_INFO',
  ALL_FARES_CURRENCY_INFO_AVERAGE_PER_TRAVELLER: 'ALL_FARES_CURRENCY_INFO_AVERAGE_PER_TRAVELLER',
  PERSONAL_BOOKING_DISCLAIMER: 'PERSONAL_BOOKING_DISCLAIMER',
  LOADING: 'LOADING',
  ADDRESS_LINE_1: 'ADDRESS_LINE_1',
  ADDRESS_LINE_2: 'ADDRESS_LINE_2',
  ZIP_CODE: 'ZIP_CODE',
  CITY: 'CITY',
  COUNTRY: 'COUNTRY',
  STATE_PROVINCE_REGION: 'STATE_PROVINCE_REGION',
  SPECIAL_PRICE: 'SPECIAL_PRICE',
  YOU_SAVE_AMOUNT_ON_BOOKING: 'YOU_SAVE_AMOUNT_ON_BOOKING',
  VIA_ORGANIZATION_SPECIAL_PRICE: 'VIA_ORGANIZATION_SPECIAL_PRICE',
  BREX_POINTS_REFUND_DISCLAIMER: 'BREX_POINTS_REFUND_DISCLAIMER',
};

export enum hardApprovalTranslationKeys {
  APPROVAL_NEEDED = 'APPROVAL_NEEDED',
  TRIP_APPROVED = 'TRIP_APPROVED',
  TRIP_DENIED = 'TRIP_DENIED',
  BOOKING_WILL_BE_VOIDED = 'BOOKING_WILL_BE_VOIDED',
  RETURN_TO_SPOTNANA = 'RETURN_TO_SPOTNANA',
  APPROVER_APPROVED = 'APPROVER_APPROVED',
  APPROVER_DENIED = 'APPROVER_DENIED',
  TRIP_THAT_IS = 'TRIP_THAT_IS',
  THAT_IS = 'THAT_IS',
  NEEDS_TO_BE_APPROVED = 'NEEDS_TO_BE_APPROVED',
  MADE_BOOKING_IN = 'MADE_BOOKING_IN',
  APPROVE = 'APPROVE',
  DENY = 'DENY',
  TRIP_VOIDED = 'TRIP_VOIDED',
  REQUEST_VOIDED = 'REQUEST_VOIDED',
  VOIDED_BECAUSE_NO_RESPONSE = 'VOIDED_BECAUSE_NO_RESPONSE',
  TRIP_VOIDED_ON = 'TRIP_VOIDED_ON',
  TRIP_PROCESSED = 'TRIP_PROCESSED',
  REQUEST_PROCESSED = 'REQUEST_PROCESSED',
  PROCESSED_ALREADY = 'PROCESSED_ALREADY',
}

export enum caseCodesTranslationKeys {
  CLICK_TO_ADD = 'CLICK_TO_ADD',
  CLICK_TO_ADD_EDIT = 'CLICK_TO_ADD_EDIT',
  CASE_CODE = 'CASE_CODE',
  ADD = 'ADD',
  EDIT = 'EDIT',
  CASE_CODES = 'CASE_CODES',
  CASE_CODES_NOT_FOUND = 'CASE_CODES_NOT_FOUND',
  TYPE_TO_SEARCH = 'TYPE_TO_SEARCH',
  TOTAL_ERROR = 'TOTAL_ERROR',
  MISSING_PERCENTAGE_ERROR = 'MISSING_PERCENTAGE_ERROR',
  TOTAL = 'TOTAL',
  SAVE = 'SAVE',
  CLEAR_ALL = 'CLEAR_ALL',
  CASE_CODES_TOOLTIP_MESSAGE = 'CASE_CODES_TOOLTIP_MESSAGE',
}

export enum supportMenuTranslationKeys {
  HERE_TO_HELP = 'HERE_TO_HELP',
  CUSTOMER_CONSULTANTS_AVAILABLE_24_7 = 'CUSTOMER_CONSULTANTS_AVAILABLE_24_7',
  TALK_TO_CONSULTANT = 'TALK_TO_CONSULTANT',
  SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK',
}

export enum localizationKeys {
  LOCALIZED_NUMBER = 'LOCALIZED_NUMBER',
  LOCALIZED_DISTANCE = 'LOCALIZED_DISTANCE',
  LOCALIZED_CURRENCY = 'LOCALIZED_CURRENCY',
  LOCALIZED_DATETIME = 'LOCALIZED_DATETIME',
}

export enum unsavedChangesTranslationKeys {
  UNSAVED_CHANGES_TEXT = 'UNSAVED_CHANGES_TEXT',
  SAVE_UNSAVED_CHANGES = 'SAVE_UNSAVED_CHANGES',
  CANCEL = 'CANCEL',
}

export enum userImpersonationTranslationKeys {
  TEMPORARILY_LOGGED_IN = 'TEMPORARILY_LOGGED_IN',
  WHEN_YOU_ARE_DONE = 'WHEN_YOU_ARE_DONE',
  SWITCH_BACK = 'SWITCH_BACK',
  TO_YOUR_ACCOUNT = 'TO_YOUR_ACCOUNT',
  LOGGED_IN_AS = 'LOGGED_IN_AS',
}

export enum loadFailedTranslationKeys {
  RETRY_BTN_TEXT = 'RETRY_BTN_TEXT',
}

export enum errorMutationsTranslationKeys {
  DELETE_PHONE_NUMBER_ERROR_TITLE = 'DELETE_PHONE_NUMBER_ERROR_TITLE',
  DELETE_PASSPORT_ERROR_TITLE = 'DELETE_PASSPORT_ERROR_TITLE',
  DELETE_LOYALTY_PROGRAM_ERROR_TITLE = 'DELETE_LOYALTY_PROGRAM_ERROR_TITLE',
  SAVE_PHONE_NUMBER_ERROR_TITLE = 'SAVE_PHONE_NUMBER_ERROR_TITLE',
  SAVE_PASSPORT_ERROR_TITLE = 'SAVE_PASSPORT_ERROR_TITLE',
  SAVE_PROFILE_ERROR_TITLE = 'SAVE_PROFILE_ERROR_TITLE',
  SAVE_LOYALTY_ERROR_TITLE = 'SAVE_LOYALTY_ERROR_TITLE',
}

export const timePickerHourOptions: { value: string; label: string }[] = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];

export const timePickerMinutesOptions: { value: string; label: string }[] = [
  { value: '00', label: '00' },
  { value: '15', label: '15' },
  { value: '30', label: '30' },
  { value: '45', label: '45' },
];

export const timePickerTimeFormatOptions: { value: string; label: string }[] = [
  { value: 'AM', label: 'AM' },
  { value: 'PM', label: 'PM' },
];

export const devIDs = [
  '@spotnana.com',
  '@spotifyfake.com',
  '@bardinfake.com',
  '@einridefake.com',
  '@falconfake.com',
  '@iconiqfake.com',
  '@indexfake.com',
  '@ribbitfake.com',
  '@sparkfake.com',
];

export const bookingServicesLinks: ILinkData[] = [
  {
    id: 'flights',
    labelKey: globalTranslationKeys.FLIGHTS,
    pathname: '/flights',
  },
  {
    id: 'hotels',
    labelKey: globalTranslationKeys.HOTELS,
    pathname: '/hotels',
  },
  {
    id: 'cars',
    labelKey: globalTranslationKeys.CARS,
    pathname: '/cars',
  },
];

export { CreatePnrStatus, PnrStatusV1 };

export enum addressTranslationKeys {
  ADDRESS_LINE1 = 'ADDRESS_LINE1',
  ADDRESS_LINE2 = 'ADDRESS_LINE2',
  DEFAULT_ADDRESS = 'DEFAULT_ADDRESS',
  COUNTRY = 'COUNTRY',
  CITY = 'CITY',
  STATE = 'STATE',
  POSTALCODE = 'POSTALCODE',
}

export enum phoneNumberTranslationKeys {
  PHONE = 'PHONE',
  PHONE_COUNTRY_CODE = 'PHONE_COUNTRY_CODE',
  PHONE_EXTENSION = 'PHONE_EXTENSION',
  MOBILE = 'MOBILE',
  MOBILE_COUNTRY_CODE = 'MOBILE_COUNTRY_CODE',
}

export const APPLICATION_ID_FOR_BREX_TRAVEL_ALL_ENVS = 'c9a60af5-1bc4-4d93-af46-08864e3c70d3';

export enum FileUploadErrorEnum {
  FILE_SIZE_EXCEEDED = 'FILE_SIZE_EXCEEDED',
  FILE_SELECTION_ERROR = 'FILE_SELECTION_ERROR',
}

// refetch the currency exchange rates in every 30mins
export const CURRENCY_REFETCH_INTERVAL = 18000000;

export enum BookingStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  REVALIDATING_PRICE = 'REVALIDATING_PRICE',
  CREATING_PNR = 'CREATING_PNR',
  BOOKING_SUCCESS = 'BOOKING_SUCCESS',
  BOOKING_FAILED = 'BOOKING_FAILED',
}

export enum ExchangingStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  REVALIDATING_PRICE = 'REVALIDATING_PRICE',
  MODIFING_PNR = 'MODIFING_PNR',
  EXCHANGING_SUCCESS = 'EXCHANGING_SUCCESS',
  EXCHANGING_FAILED = 'EXCHANGING_FAILED',
}

export enum RetryPaymentStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  REVALIDATING_PRICE = 'REVALIDATING_PRICE',
  RETRYING_PAYMENT = 'RETRYING_PAYMENT',
  RETRY_SUCCESS = 'RETRY_SUCCESS',
  RETRY_FAILED = 'RETRY_FAILED',
}

export const patternMatchingRegex: Record<string, RegExp> = {
  html: /<(?=.? .?\/ ?>|br|hr|input|!--|wbr)[a-z]+.?>|<([a-z]+).?<\/\1>/i,
  // eslint-disable-next-line prefer-regex-literals
  url: new RegExp('([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+'),
};

export const randomPastelColors = [
  '#CCD4BF',
  '#E7CBA9',
  '#EEBAB2',
  '#E8D6CF',
  '#76CDCD',
  '#2CCED2',
  '#B8E0F6',
  '#F5F3E7',
  '#BEB4C5',
  '#E6A57E',
  '#218B82',
  '#9AD9DB',
  '#E5DBD9',
  '#98D4BB',
  '#EB96AA',
  '#C6C9D0',
  '#C54B6C',
  '#76CDCD',
  '#2CCED2',
  '#B8E0F6',
  '#F9968B',
  '#F27348',
  '#26474E',
  '#F5BFD2',
  '#E5DB9C',
  '#D0BCAC',
  '#D5E4C3',
  '#E5B3BB',
  '#C47482',
  '#F5E2E4',
  '#F9CAD7',
];

export const mapPhoneTypeFromV1ToV2: Record<PhoneNumberType, PhoneNumberTypeEnum> = {
  [IPhoneNumberTypeEnum.MOBILE]: PhoneNumberTypeEnum.Mobile,
  [IPhoneNumberTypeEnum.LANDLINE]: PhoneNumberTypeEnum.Landline,
  [IPhoneNumberTypeEnum.UNKNOWN_TYPE]: PhoneNumberTypeEnum.UnknownType,
  [IPhoneNumberTypeEnum.UNRECOGNIZED]: PhoneNumberTypeEnum.UnknownType,
};

export const mapPhoneSourceCodeFromV1ToV2: Record<
  PhoneNumberCountryCodeSourceEnum,
  PhoneNumberCountryCodeSourceEnumV2
> = {
  [PhoneNumberCountryCodeSourceEnum.FROM_DEFAULT_COUNTRY]: PhoneNumberCountryCodeSourceEnumV2.FromDefaultCountry,
  [PhoneNumberCountryCodeSourceEnum.FROM_NUMBER_WITHOUT_PLUS_SIGN]:
    PhoneNumberCountryCodeSourceEnumV2.FromNumberWithoutPlusSign,
  [PhoneNumberCountryCodeSourceEnum.FROM_NUMBER_WITH_IDD]: PhoneNumberCountryCodeSourceEnumV2.FromNumberWithIdd,
  [PhoneNumberCountryCodeSourceEnum.FROM_NUMBER_WITH_PLUS_SIGN]:
    PhoneNumberCountryCodeSourceEnumV2.FromNumberWithPlusSign,
  [PhoneNumberCountryCodeSourceEnum.UNRECOGNIZED]: PhoneNumberCountryCodeSourceEnumV2.Unspecified,
  [PhoneNumberCountryCodeSourceEnum.UNSPECIFIED]: PhoneNumberCountryCodeSourceEnumV2.Unspecified,
};

export const mapNameSuffixFromV1ToV2: Record<NameSuffix, NameSuffixV2> = {
  [NameSuffix.NAME_SUFFIX_UNKNOWN]: NameSuffixV2.NameSuffixUnknown,
  [NameSuffix.UNRECOGNIZED]: NameSuffixV2.NameSuffixUnknown,
  [NameSuffix.SR]: NameSuffixV2.Sr,
  [NameSuffix.PHD]: NameSuffixV2.Phd,
  [NameSuffix.ATTY]: NameSuffixV2.Atty,
  [NameSuffix.DO]: NameSuffixV2.Do,
  [NameSuffix.II]: NameSuffixV2.Ii,
  [NameSuffix.III]: NameSuffixV2.Iii,
  [NameSuffix.IV]: NameSuffixV2.Iv,
  [NameSuffix.JR]: NameSuffixV2.Jr,
  [NameSuffix.MD]: NameSuffixV2.Md,
};

export const mapEmergencyContactRelationFromV1ToV2: Record<
  EmergencyContactRelationEnumV1,
  EmergencyContactRelationEnum
> = {
  [EmergencyContactRelationEnumV1.RELATION_UNKNOWN]: EmergencyContactRelationEnum.RelationUnknown,
  [EmergencyContactRelationEnumV1.SPOUSE]: EmergencyContactRelationEnum.Spouse,
  [EmergencyContactRelationEnumV1.PARENT]: EmergencyContactRelationEnum.Parent,
  [EmergencyContactRelationEnumV1.SIBLING]: EmergencyContactRelationEnum.Sibling,
  [EmergencyContactRelationEnumV1.CHILD]: EmergencyContactRelationEnum.Child,
  [EmergencyContactRelationEnumV1.FRIEND]: EmergencyContactRelationEnum.Friend,
  [EmergencyContactRelationEnumV1.RELATIVE]: EmergencyContactRelationEnum.Relative,
  [EmergencyContactRelationEnumV1.COLLEAGUE]: EmergencyContactRelationEnum.Colleague,
  [EmergencyContactRelationEnumV1.OTHER]: EmergencyContactRelationEnum.Other,
  [EmergencyContactRelationEnumV1.UNRECOGNIZED]: EmergencyContactRelationEnum.RelationUnknown,
} as const;

export const mapNameSuffixToString: Record<NameSuffix, string> = {
  [NameSuffix.NAME_SUFFIX_UNKNOWN]: 'NAME_SUFFIX_UNKNOWN',
  [NameSuffix.UNRECOGNIZED]: '',
  [NameSuffix.SR]: 'SR',
  [NameSuffix.JR]: 'JR',
  [NameSuffix.MD]: 'M.D.',
  [NameSuffix.PHD]: 'Ph.D.',
  [NameSuffix.II]: 'II',
  [NameSuffix.III]: 'III',
  [NameSuffix.IV]: 'IV',
  [NameSuffix.DO]: 'D.O.',
  [NameSuffix.ATTY]: 'Atty',
};

export const mapNameV2SuffixToString: Record<NameSuffixV2, string> = {
  [NameSuffixV2.NameSuffixUnknown]: '',
  [NameSuffixV2.Sr]: 'SR',
  [NameSuffixV2.Jr]: 'JR',
  [NameSuffixV2.Md]: 'M.D.',
  [NameSuffixV2.Phd]: 'Ph.D.',
  [NameSuffixV2.Ii]: 'II',
  [NameSuffixV2.Iii]: 'III',
  [NameSuffixV2.Iv]: 'IV',
  [NameSuffixV2.Do]: 'D.O.',
  [NameSuffixV2.Atty]: 'Atty',
};

export const customFieldQuestionTypeToQuestionFormatMap = {
  [CustomFieldQuestionType.SINGLE_SELECT]: QuestionFormat.RADIO_BUTTON,
  [CustomFieldQuestionType.MULTI_SELECT]: QuestionFormat.CHECKBOX,
  [CustomFieldQuestionType.MULTI_SELECT_WITH_PERCENTAGE]: QuestionFormat.CHECKBOX_WITH_PERCENTAGE,
} as const;

type InvertResult<T extends Record<PropertyKey, PropertyKey>> = {
  // Need to disable prettier as it doesn't support new TS syntax
  // eslint-disable-next-line prettier/prettier
  [P in keyof T as T[P]]: P;
};

export const questionFormatToCustomFieldQuestionTypeMap = invert(
  customFieldQuestionTypeToQuestionFormatMap,
) as InvertResult<typeof customFieldQuestionTypeToQuestionFormatMap>;

export const DEFAULT_SPOTNANA_PARTNER_NAME = 'Spotnana Customer';

export const MAX_LENGTH_AUTOCOMPLETE = 50;

export const VGS_ORIGIN = 'https://js.verygoodvault.com';
export const VGS_COLLECT_JS_SRC = `${VGS_ORIGIN}/vgs-collect/2.27.0/vgs-collect.js`;
export const VGS_SHOW_JS_SRC = `${VGS_ORIGIN}/vgs-show/2.1.0/show.js`;

export const defaultCO2EmissionPerTravelClass: IRailCo2EmissionPerTravelType = {
  [TravelClass.UNKNOWN]: 0,
  [TravelClass.FIRST]: 0,
  [TravelClass.STANDARD]: 0,
  [TravelClass.BUSINESS]: 0,
  [TravelClass.SLEEPER]: 0,
  [TravelClass.STANDARD_PREMIUM]: 0,
  [TravelClass.BUSINESS_PREMIUM]: 0,
  [TravelClass.COACH]: 0,
  [TravelClass.UNRECOGNIZED]: 0,
  [TravelClass.ROOM]: 0,
  [TravelClass.EXECUTIVE]: 0,
};

export const SOFT_APPROVAL_TEXT =
  'This booking is subject to an approval process. If your booking is denied you will receive an email notification with more information.';

export const HARD_APPROVAL_TEXT_VOIDABLE =
  "This booking requires approval. If approval isn't received on time this booking will be voided.";

export const HARD_APPROVAL_TEXT_NON_VOIDABLE =
  'This booking requires approval but cannot be refunded or exchanged. If approval is not given it will result in a loss of funds.';

export const SPOTNANA_ORG_ID = 'ecc5b835-8001-430c-98f8-fedeccebe4cf';

/** List of currently active actions that are supported by the app
 * This ensures that we filter out any unsupported actions sent from the backend
 */
export const supportedUserActions = [
  IncompleteUserActionName.PRIVACY_CONSENT,
  IncompleteUserActionName.DATA_SYNC,
  IncompleteUserActionName.LANGUAGE_PREFERENCE,
];

export const HTML_REGEXP = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
