import type { IVGSForm, IVGsSubmitOptions } from '../../types';
import type { IAirRevalidateItineraryRequest, IAirRevalidateItineraryResponse } from '../../types/flight';
import type { IHeaders } from '../../hooks/useGetRequestHeader';
import type { InternalAPIError } from '../../types/common';

export async function revalidateItineraryViaCvv(
  vgsForm: IVGSForm,
  revalidateRequest: IAirRevalidateItineraryRequest,
  requestHeaders: IHeaders,
  submitVgsForm: (
    vgsForm: IVGSForm,
    path: string,
    options: IVGsSubmitOptions,
  ) => Promise<{
    status: string;
    response: any;
  }>,
) {
  const response = await submitVgsForm(vgsForm, '/v1/air-revalidate-itin', {
    mapDotToObject: 'mergeArray',
    data: revalidateRequest,
    headers: requestHeaders,
  });

  const revalidateResponse = response.response as IAirRevalidateItineraryResponse | InternalAPIError;

  if (
    revalidateResponse &&
    ('bookingId' in revalidateResponse || 'totalFare' in revalidateResponse) &&
    !('errorCode' in revalidateResponse)
  ) {
    return revalidateResponse;
  }

  throw revalidateResponse || new Error('No response received during cvv validation. Please try again.');
}
