import first from 'lodash/first';
import last from 'lodash/last';
import type { TFunction } from 'react-i18next';
import { dateFormats } from '../constants/common';
import { safetyAndCleaningPracticesOptions } from '../constants/flights';
import type { PassengerAge, PassengerTypeEnum } from '../types/api/v1/obt/air/air_common';
import { CabinEnum } from '../types/api/v1/obt/air/air_common';
import type {
  AirSearchResponse,
  RateOption,
  PaxInfoFareComponentLegInfo,
  VendorProgramType,
  RateOptionTicketType,
} from '../types/api/v1/obt/air/air_search_response';
import { FareTypeEnum, VendorProgramTypeEnum } from '../types/api/v1/obt/air/air_search_response';
import type {
  IFlightAmenity,
  IFlightDetail,
  IFlightLegDetails,
  IItineraryAirline,
  IItinerarySelectedLeg,
  IPolicyInfo,
  ILegFlightIndexInfo,
  ILegBaggageOptions,
  IBaggageOptions,
  ILegAirports,
  IRefundChangePolicy,
  IFlightLayoverFlightInfo,
} from '../types/flight';
import { FlightDetailsInfoType } from '../types/flight';
import { MoneyUtil } from '../utils/Money';
import { convertDateFormat } from '../date-utils';
import AirSearchResponseManager from './AirSearchResponseManager';
import { PolicyInfoApprovalType } from '../types/api/v1/obt/common/policy_info';
import type { PaymentMethod } from '../types';
import type { Uta } from '../types/api/v1/obt/air/routehappy';
import { UtaPointsLoyaltyProgram } from '../types/api/v1/obt/air/routehappy';

export default class AirSelectedItineraryResponseManager extends AirSearchResponseManager {
  constructor(protected readonly response: AirSearchResponse) {
    super(response);
  }

  public GetItineraryAllLegs(): IItinerarySelectedLeg[] {
    return this.GetItineraryAllLegsForItineraryIndex(0);
  }

  public GetNegotiatedSavings(): MoneyUtil {
    const itinIndex = 0;
    return super.GetNegotiatedSavings(itinIndex);
  }

  public isRoundTrip(): boolean {
    const allLegs = this.GetItineraryAllLegs();
    if (allLegs.length !== 2) {
      return false;
    }

    const firstLegSummary = this.GetLegSummary({ itinIndex: 0, legNumber: allLegs[0].legNumber });
    const secondLegSummary = this.GetLegSummary({ itinIndex: 0, legNumber: allLegs[1].legNumber });

    const firstLegSummaryOrigin = first(firstLegSummary.airports);
    const firstLegSummaryDestination = last(firstLegSummary.airports);

    const secondLegSummaryOrigin = first(secondLegSummary.airports);
    const secondLegSummaryDestination = last(secondLegSummary.airports);

    return (
      firstLegSummaryDestination?.code === secondLegSummaryOrigin?.code &&
      secondLegSummaryDestination?.code === firstLegSummaryOrigin?.code
    );
  }

  public getLegFlightIndexInfo(): ILegFlightIndexInfo[] {
    const flightIndexInfo: ILegFlightIndexInfo[] = [];

    const legDetails = this.GetItineraryAllLegs();
    legDetails.forEach((legDetail, legNumber) => {
      const flightIndices = this.GetLegFlights(legDetail.legIndex);
      flightIndices.forEach((flightIndex, flightNumber) => {
        flightIndexInfo.push({
          legNumber,
          flightNumber,
          legIndex: legDetail.legIndex,
          flightIndex,
        });
      });
    });

    return flightIndexInfo;
  }

  public GetSummaryLegDetails(legNumber: number, legIndex: number): IFlightLegDetails[] {
    return this.GetSummaryLegDetailsForItineraryIndex(legNumber, legIndex, 0);
  }

  public GetItineraryAllFlights(): IFlightDetail[] {
    return this.GetItineraryAllFlightsForItineraryIndex(0);
  }

  public GetFlightsPerLeg(): ILegAirports[][] {
    const flightsPerLeg: ILegAirports[][] = [];

    const { legs, flights } = this.response.data;

    legs?.forEach((leg, legIndex) => {
      flightsPerLeg[legIndex] = [];
      flights?.forEach((flight, flightIndex) => {
        if (leg.flightIndices.includes(flightIndex)) {
          flightsPerLeg[legIndex].push({
            departureAirportCode: flight.origin.airport,
            arrivalAirportCode: flight.destination.airport,
          });
        }
      });
    });

    return flightsPerLeg;
  }

  public GetTripName(tt: TFunction<'WEB' | 'MOBILE', undefined>, isCheckoutV2Enabled?: boolean): string {
    const legs = this.GetItineraryAllLegs();

    const firstLegNumber = first(legs)?.legNumber ?? 0;
    const lastLegNumber = last(legs)?.legNumber ?? 0;
    const { flightDetails: firstLegDetails } = this.GetLegDetails({
      itinIndex: 0,
      legNumber: firstLegNumber,
    });
    const { flightDetails: lastLegDetails } = this.GetLegDetails({
      itinIndex: 0,
      legNumber: lastLegNumber,
    });

    const departureDate = first(firstLegDetails)?.departureDateTime ?? '';

    let tripDetartureDate = '';

    if (isCheckoutV2Enabled) {
      tripDetartureDate = convertDateFormat(departureDate, dateFormats.LONG_DATE_REVERSE, dateFormats.MONTH_FULL_YEAR);
    } else {
      tripDetartureDate = convertDateFormat(departureDate, dateFormats.LONG_DATE_REVERSE, dateFormats.DATE);
    }

    const isRoundTrip = this.isRoundTrip();

    if (isCheckoutV2Enabled) {
      // round trip
      if (isRoundTrip) {
        return tt('Trip to {{firstLegArrivalAirportCity}} ({{tripDetartureDate}})', {
          firstLegArrivalAirportCity: last(firstLegDetails)?.arrivalAirportCity ?? '',
          tripDetartureDate,
        });
      }

      // one way
      if (legs.length === 1) {
        return tt('Trip to {{lastLegArrivalAirportCity}} ({{tripDetartureDate}})', {
          lastLegArrivalAirportCity: last(lastLegDetails)?.arrivalAirportCity ?? '',
          tripDetartureDate,
        });
      }
    }

    if (isRoundTrip) {
      return `(${tripDetartureDate}) ${last(firstLegDetails)?.arrivalAirportCity ?? ''}`;
    }

    if (legs.length > 1) {
      return tt('{{tripDetartureDate}} Multi city trip', { tripDetartureDate });
    }

    return `(${tripDetartureDate}) ${last(lastLegDetails)?.arrivalAirportCity ?? ''}`;
  }

  public GetItineraryAllAirlines(): IItineraryAirline[] {
    const itineraryLegs = this.GetItineraryAllLegs();

    const itineraryAirlines: IItineraryAirline[] = [];
    itineraryLegs.forEach((itineraryLeg) => {
      const { legIndex, legNumber } = itineraryLeg;
      const flightIndices = this.GetLegFlights(legIndex);
      flightIndices.forEach((flightIndex, flightNumber) => {
        const { operating, marketing } = this.GetFlightAirline(flightIndex);
        itineraryAirlines.push({
          flightIndex: flightNumber,
          legIndex: legNumber,
          airline: { operating: operating.airline, marketing: marketing.airline },
        });
      });
    });
    return itineraryAirlines;
  }

  public isHardApprovalRequired(): boolean {
    let approvalRequired = false;
    this.response.itineraries.forEach((itinerary) => {
      const { rateOptions } = itinerary;
      rateOptions.forEach((rateOption) => {
        const { policyInfos } = rateOption;
        policyInfos.forEach((policyInfo) => {
          if (policyInfo.approvalType === PolicyInfoApprovalType.HARD_APPROVAL) {
            approvalRequired = true;
          }
        });
      });
    });

    return approvalRequired;
  }

  public isSoftApprovalRequired(): boolean {
    if (this.isHardApprovalRequired()) {
      return false;
    }

    let approvalRequired = false;
    this.response.itineraries.forEach((itinerary) => {
      const { rateOptions } = itinerary;
      rateOptions.forEach((rateOption) => {
        const { policyInfos } = rateOption;
        policyInfos.forEach((policyInfo) => {
          if (policyInfo.approvalType === PolicyInfoApprovalType.SOFT_APPROVAL) {
            approvalRequired = true;
          }
        });
      });
    });

    return approvalRequired;
  }

  public isItineraryVoidable(): boolean {
    return this.response.itineraries[0].rateOptions[0].isVoidable;
  }

  // TODO: accept paxInfoIndex in future
  private getPaxInfoFareComponentLegInfo(legNumber: number): PaxInfoFareComponentLegInfo {
    return this.response.itineraries[0].rateOptions[0].paxInfo[0].fareComponents[0].legInfo[legNumber];
  }

  public getBrandName(legNumber: number): string {
    return this.getPaxInfoFareComponentLegInfo(legNumber).brandName;
  }

  public getBookingCode(legNumber: number, flightNumber: number): string {
    return this.getPaxInfoFareComponentLegInfo(legNumber).flightInfo[flightNumber].bookingCode;
  }

  public getRateOptionFareType(): FareTypeEnum {
    return super.getRateOptionFareType(0, 0);
  }

  public getIsCorporateRate(legNumber: number): boolean {
    return this.getPaxInfoFareComponentLegInfo(legNumber).fareType === FareTypeEnum.CORPORATE;
  }

  public getIsUatpPassPlusRate(legNumber: number): boolean {
    return this.getPaxInfoFareComponentLegInfo(legNumber).vendorProgramType === VendorProgramTypeEnum.UA_PASS_PLUS;
  }

  public getFlightCabin(legNumber: number, flightNumber: number): string {
    return CabinEnum[this.getPaxInfoFareComponentLegInfo(legNumber).flightInfo[flightNumber].cabin];
  }

  public getFlightCabinEnum(legNumber: number, flightNumber: number): CabinEnum {
    return this.getPaxInfoFareComponentLegInfo(legNumber).flightInfo[flightNumber].cabin;
  }

  public isOutOfPolicy(legNumber: number): boolean {
    return this.isOutOfPolicyForItineraryAndRateOptionIndex(legNumber, 0, 0);
  }

  public getTicketType(): RateOptionTicketType {
    return this.response.itineraries[0].rateOptions[0].ticketType;
  }

  public getPolicyInfoForLeg(legNumber: number): IPolicyInfo | undefined {
    return this.getPolicyInfoForLegWithItineraryAndRateOptionIndex(legNumber, 0, 0);
  }

  public static getFlightAmenitiesAndSafetyPractices(allAmenities: IFlightAmenity[]): {
    amenities: IFlightAmenity[];
    safetyAndCleaningPractices: IFlightAmenity[];
  } {
    const amenities: IFlightAmenity[] = [];
    const safetyAndCleaningPractices: IFlightAmenity[] = [];

    allAmenities.forEach((amenity) => {
      if (safetyAndCleaningPracticesOptions.includes(amenity.key)) {
        safetyAndCleaningPractices.push(amenity);
      } else {
        amenities.push(amenity);
      }
    });

    return {
      amenities,
      safetyAndCleaningPractices,
    };
  }

  public GetPaxLegInfo(paxIndex: number): PaxInfoFareComponentLegInfo[] {
    return this.response.itineraries[0].rateOptions[0].paxInfo[paxIndex].fareComponents[0].legInfo;
  }

  public GetLegBaseFare(legIndex: number, paxIndex: number): MoneyUtil {
    const legInfo = this.GetPaxLegInfo(paxIndex);
    return MoneyUtil.parse(legInfo[legIndex].totalLegFare?.base);
  }

  private GetUniqueVendors(paxIndex: number): VendorProgramType[] {
    const vendorTypes: VendorProgramType[] = [];
    this.GetPaxLegInfo(paxIndex).forEach((legDetails) => {
      vendorTypes.push(legDetails.vendorProgramType);
    });
    return [...new Set(vendorTypes)];
  }

  public GetIsMultiTicketItinerary(paxIndex = 0): boolean {
    const uniqueVendors = this.GetUniqueVendors(paxIndex);
    if (uniqueVendors.length > 1) {
      return true;
    }
    return false;
  }

  public GetHasUaPassPlusfare(paxIndex = 0): boolean {
    const uniqueVendors = this.GetUniqueVendors(paxIndex);
    if (uniqueVendors.includes(VendorProgramTypeEnum.UA_PASS_PLUS)) {
      return true;
    }
    return false;
  }

  public GetUaPassPlusCardId(): string {
    const uatpCard = this.response.metadata?.vendorPrograms?.find((vendorProgram) => vendorProgram.uaPassPlus?.cardId);
    if (uatpCard?.uaPassPlus?.cardId) {
      return uatpCard.uaPassPlus.cardId;
    }
    return '';
  }

  public GetUaPassPlusBaseFare(zeroMoney: MoneyUtil, paxIndex = 0): MoneyUtil {
    return this.GetPaxLegInfo(paxIndex).reduce((totalPassPlusFare, legInfo) => {
      if (legInfo.vendorProgramType === VendorProgramTypeEnum.UA_PASS_PLUS && legInfo.totalLegFare) {
        let baseAmount = MoneyUtil.parse(legInfo.totalLegFare.base);

        if (baseAmount.isZero()) {
          baseAmount = zeroMoney;
        }
        return totalPassPlusFare.add(baseAmount);
      }
      return totalPassPlusFare;
    }, zeroMoney);
  }

  public GetUaPassFeesAndTaxes(zeroMoney: MoneyUtil, paxIndex = 0): MoneyUtil {
    return this.GetPaxLegInfo(paxIndex).reduce((totalPassPlusFare, legInfo) => {
      if (legInfo.vendorProgramType === VendorProgramTypeEnum.UA_PASS_PLUS && legInfo.totalLegFare) {
        let taxAmount = MoneyUtil.parse(legInfo.totalLegFare.tax);
        if (taxAmount.isZero()) {
          taxAmount = zeroMoney;
        }
        return totalPassPlusFare.add(taxAmount);
      }
      return totalPassPlusFare;
    }, zeroMoney);
  }

  public GetNonUaPassPlusBaseFare(zeroMoney: MoneyUtil, paxIndex = 0): MoneyUtil {
    return this.GetPaxLegInfo(paxIndex).reduce((totalPassPlusFare, legInfo) => {
      if (legInfo.vendorProgramType !== VendorProgramTypeEnum.UA_PASS_PLUS && legInfo.totalLegFare) {
        let baseAmount = MoneyUtil.parse(legInfo.totalLegFare.base);

        if (baseAmount.isZero()) {
          baseAmount = zeroMoney;
        }
        return totalPassPlusFare.add(baseAmount);
      }
      return totalPassPlusFare;
    }, zeroMoney);
  }

  public GetNonUaPassFeesAndTaxes(zeroMoney: MoneyUtil, paxIndex = 0): MoneyUtil {
    return this.GetPaxLegInfo(paxIndex).reduce((totalPassPlusFare, legInfo) => {
      if (legInfo.vendorProgramType !== VendorProgramTypeEnum.UA_PASS_PLUS && legInfo.totalLegFare) {
        let taxAmount = MoneyUtil.parse(legInfo.totalLegFare.tax);
        if (taxAmount.isZero()) {
          taxAmount = zeroMoney;
        }
        return totalPassPlusFare.add(taxAmount);
      }
      return totalPassPlusFare;
    }, zeroMoney);
  }

  public getLegWiseEmissions(): Array<number | null> {
    const legs = this.GetItineraryAllLegs();

    return legs.map(({ legIndex }) => {
      const { flightInfo } = this.response.itineraries[0].rateOptions[0].paxInfo[0].fareComponents[0].legInfo[legIndex];
      return AirSelectedItineraryResponseManager.GetTotalEmissionsForFlightsInfo(flightInfo);
    });
  }

  public GetPaxInfoIndex(
    paxType: PassengerTypeEnum,
    paxAge?: PassengerAge,
    itinIndex = 0,
    rateOptionIndex = 0,
  ): number {
    return super.GetPaxInfoIndex(paxType, paxAge, itinIndex, rateOptionIndex);
  }

  public getPaxBaseFare(paxNumber: number, itinIndex = 0, rateOptionIndex = 0): MoneyUtil {
    return super.getPaxBaseFare(paxNumber, itinIndex, rateOptionIndex);
  }

  public getAllowedFop(itinIndex = 0, rateOptionIndex = 0): PaymentMethod[] {
    return this.response.itineraries[itinIndex].rateOptions[rateOptionIndex].allowedFop;
  }

  public getPaxTax(paxNumber: number, itinIndex = 0, rateOptionIndex = 0): MoneyUtil {
    return super.getPaxTax(paxNumber, itinIndex, rateOptionIndex);
  }

  public getMetaDataWithBaggageOptions(baggageOptions: ILegBaggageOptions[]): IBaggageOptions[] {
    const bagaggeOptionsWithMetaData = baggageOptions.map((baggageOption) => {
      const baggageLegIndex = baggageOption.legIndex;
      if (baggageLegIndex !== -1) {
        const legFlightIndices = this.GetLegFlights(baggageLegIndex);
        const departureAirport = this.GetLegAirportCityByFlightIndex(baggageLegIndex, legFlightIndices[0])[0];
        const arrivalAirport = this.GetLegAirportCityByFlightIndex(
          baggageLegIndex,
          legFlightIndices[legFlightIndices.length - 1],
        )[1];

        const { code: departureAirportCode } = this.GetAirports(baggageLegIndex)[0];
        const { code: arrivalAirportCode } = this.GetAirports(baggageLegIndex)[1];

        return {
          ...baggageOption,
          metaData: {
            departureAirport,
            arrivalAirport,
            departureAirportCode,
            arrivalAirportCode,
          },
        };
      }

      const itineraryAllLegs = this.GetItineraryAllLegs();
      const inBound = this.GetLegFlights(itineraryAllLegs[0].legIndex);
      const outBound = this.GetLegFlights(itineraryAllLegs[itineraryAllLegs.length - 1].legIndex);
      const departureAirport = this.GetLegAirportCityByFlightIndex(0, inBound[0])[0];
      const arrivalAirport = this.GetLegAirportCityByFlightIndex(
        itineraryAllLegs[itineraryAllLegs.length - 1].legIndex,
        outBound[outBound.length - 1],
      )[1];

      const { code: departureAirportCode } = this.GetAirports(itineraryAllLegs[0].legIndex)[0];
      const { code: arrivalAirportCode } = this.GetAirports(itineraryAllLegs[itineraryAllLegs.length - 1].legIndex)[1];

      return {
        ...baggageOption,
        metaData: {
          departureAirport,
          arrivalAirport,
          departureAirportCode,
          arrivalAirportCode,
        },
      };
    });
    return bagaggeOptionsWithMetaData;
  }

  public GetLegFlightDetailsForSelectedItinerary(legIndex: number) {
    return this.GetLegFlightDetails(0, legIndex);
  }

  public GetFlightDetailsForLegIndexFlightIndex(legIndex: number, flightIndex: number) {
    const legDetails = this.GetLegFlightDetailsForSelectedItinerary(legIndex);

    const allFlightDetailsForLeg = legDetails.filter(
      (info): info is IFlightLayoverFlightInfo => info.type === FlightDetailsInfoType.FLIGHT,
    );
    return allFlightDetailsForLeg[flightIndex];
  }

  public GetLegUTAsForSelectedItinerary(legNumber: number) {
    return this.GetLegUTAsForItineraryIndex(legNumber, 0);
  }

  public GetShowRawFareRulesForSelectedItinerary() {
    return this.GetShowRawFareRulesForItineraryIndex(0);
  }

  public GetMerchantFee() {
    return super.GetMerchantFee(0);
  }

  public GetRefundChangePolicy(): IRefundChangePolicy {
    return this.GetRefundChangePolicyForItineraryIndex(0);
  }

  public getHoldDeadline(): RateOption['holdDeadline'] {
    return super.getHoldDeadline(0);
  }

  public getUtaPointsForKey(key: 'companyEarn' | 'rewards' | 'statusCredits'): number {
    const itineraryAllLegs = this.GetItineraryAllLegsForItineraryIndex(0);
    const pointsPerLeg: number[] = itineraryAllLegs.map((itineraryLeg) => {
      const legInfo = this.GetSummaryLegDetailsForItineraryIndex(itineraryLeg.legNumber, itineraryLeg.legIndex, 0)[0];
      // We take the first flight details uta because utas are at leg level and they are duplicated for flights of the same leg
      const legUtas = legInfo.flightDetails[0].utas;

      const pointsUtas = legUtas.filter((uta) => uta.key === key);

      if (pointsUtas.length === 0) {
        return 0;
      }
      let totalLegPoints = 0;
      pointsUtas.forEach((current) => {
        totalLegPoints += current.uta?.[key]?.points ?? 0;
      });

      return totalLegPoints;
    });
    return pointsPerLeg.reduce((prevSum, current) => {
      return prevSum + current;
    }, 0);
  }

  public getRewardPointsPrograms(): UtaPointsLoyaltyProgram[] {
    const itineraries: IFlightDetail[] = this.GetItineraryAllFlightsForItineraryIndex(0);
    const rewardsProgramsPerFlight: UtaPointsLoyaltyProgram[] = itineraries.map((itinerary) => {
      const pointsUta: Uta | undefined = itinerary.utas.find((uta) => uta.key === 'rewards')?.uta;
      if (!pointsUta || !pointsUta.rewards) {
        return UtaPointsLoyaltyProgram.LOYALTY_PROGRAM_UNKNOWN;
      }
      return pointsUta.rewards?.program;
    });
    return rewardsProgramsPerFlight;
  }

  public isOnlyPayableByRewardsPoint(legIndex: number): boolean {
    const legBaseFare =
      this.response.itineraries[0].rateOptions[0].paxInfo[0]?.fareComponents[0]?.legInfo[legIndex]?.totalLegFare?.base;

    return this.GetIsQantasRewardsFare(MoneyUtil.parse(legBaseFare));
  }

  public getQantasFareConfig(totalSeatPrice: MoneyUtil) {
    const legInfo = this.GetPaxLegInfo(0);
    const currency = MoneyUtil.parse(legInfo[0]?.totalLegFare?.base).getCurrency();
    const isQantasRewardFare = legInfo.some((_, legIndex) => this.isOnlyPayableByRewardsPoint(legIndex));
    let cashToBePaid = MoneyUtil.create(0, currency);
    let pointsUsed = 0;
    legInfo.forEach((_legDetail, legIndex) => {
      const baseFare = this.GetTotalLegBaseFare(0, 0, legIndex);
      const taxFare = this.GetTotalLegTaxFare(0, 0, legIndex);
      if (this.isOnlyPayableByRewardsPoint(legIndex)) {
        pointsUsed += baseFare.getQantasPoints()?.amount || 0;
        cashToBePaid = cashToBePaid.add(taxFare);
      } else {
        cashToBePaid = cashToBePaid.add(baseFare).add(taxFare);
      }
      cashToBePaid = totalSeatPrice.add(cashToBePaid);
    });
    return {
      isQantasRewardFare,
      cashToBePaid,
      pointsUsed,
    };
  }

  public getLegFlightNumberUsingFlightIndex(legIndex: number, flightIndex: number): number {
    return this.response.data.legs[legIndex].flightIndices.findIndex(
      (currentFlightIndex) => currentFlightIndex === flightIndex,
    );
  }
}
