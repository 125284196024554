import { CabinEnum } from '../types/api/v1/obt/air/air_common';
import type { FlightSeatMap } from '../types/api/v1/obt/air/air_seat_map_response';
import {
  ColumnSectionColumnPosition,
  SeatSectionSeatType,
  SeatSectionSeatLocation,
} from '../types/api/v1/obt/air/air_seat_map_response';

const snapshot: FlightSeatMap[] = [
  {
    cabinSections: [
      {
        facilitySections: [],
        cabin: CabinEnum.ECONOMY,
        bookingCode: '',
        locations: [],
        columnSections: [
          {
            columns: [
              {
                columnNumber: 'A',
                position: ColumnSectionColumnPosition.WINDOW,
              },
              {
                columnNumber: 'B',
                position: ColumnSectionColumnPosition.CENTER,
              },
              {
                columnNumber: 'C',
                position: ColumnSectionColumnPosition.AISLE,
              },
            ],
          },
          {
            columns: [
              {
                columnNumber: 'D',
                position: ColumnSectionColumnPosition.AISLE,
              },
              {
                columnNumber: 'E',
                position: ColumnSectionColumnPosition.CENTER,
              },
              {
                columnNumber: 'F',
                position: ColumnSectionColumnPosition.WINDOW,
              },
            ],
          },
        ],
        rowSections: [
          {
            rowNumbers: {
              min: 1,
              max: 30,
            },
            rowTypes: [],
            facilitySections: [],
            availableSeats: [
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
              { columnNumber: [] },
            ],
            seatSections: [
              {
                columnNumbers: ['A', 'B', 'C', 'D', 'E', 'F'],
                price: {},
                type: SeatSectionSeatType.SEAT,
                limitations: [],
                location: [SeatSectionSeatLocation.BULKHEAD_ROW],
                facilities: [],
                commercialName: '',
              },
            ],
          },
        ],
      },
    ],
    warnings: [],
    operatingAirline: '',
    isLoyaltyUsed: false,
  },
];

export default snapshot;
