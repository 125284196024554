/**
 * As of now we only support limited currencies due to limited availability of Spotnana PCCs
 * Others will be added when Operations team implement the other currencies
 * Mentioned in requirements doc here: https://docs.google.com/document/d/1GPi_4RLWWxzZolVLBsGIBARIp2ZsNdWhA1-vD_sdtA8/edit?disco=AAAAH9_Prag
 */

export const countryCurrencyMap = {
  AED: 'AE',
  ARS: 'AR',
  AUD: 'AU',
  BRL: 'BR',
  CAD: 'CA',
  CHF: 'CH',
  CLP: 'CL',
  CNY: 'CN',
  COP: 'CO',
  CRC: 'CR',
  CZK: 'CZ',
  DKK: 'DK',
  DOP: 'DO',
  EUR: 'EU',
  GBP: 'GB',
  GTQ: 'GT',
  HKD: 'HK',
  HNL: 'HN',
  HUF: 'HU',
  IDR: 'ID',
  ILS: 'IL',
  INR: 'IN',
  JPY: 'JP',
  KRW: 'KR',
  MXN: 'MX',
  MYR: 'MY',
  NOK: 'NO',
  NZD: 'NZ',
  PEN: 'PE',
  PHP: 'PH',
  PLN: 'PL',
  RMB: 'CN',
  RON: 'RO',
  SAR: 'SA',
  SEK: 'SE',
  SGD: 'SG',
  THB: 'TH',
  TRY: 'TR',
  TWD: 'TW',
  UAH: 'UA',
  USD: 'US',
  VND: 'VN',
  ZAR: 'ZA',
};

export type SupportedBillingCurrencyKeys = keyof typeof countryCurrencyMap;

export const supportedBillingCurrenciesISO = Object.keys(countryCurrencyMap).map((currency) => ({
  label: currency,
  value: currency,
}));

const supportedBillingCurrencies = Object.keys(countryCurrencyMap).map((currency) => ({
  label: currency,
  value: countryCurrencyMap[currency as SupportedBillingCurrencyKeys],
}));

export default supportedBillingCurrencies;
