/* eslint-disable react-hooks/rules-of-hooks */
// TODO: if we go ahead with creating a controlled type layer of flags, add typing to
import { useFeatureFlag as useFF, useFeatureFlags as useFFs } from '@harnessio/ff-react-client-sdk';
import type {
  BlocksPalette,
  BrexControls,
  IAirMultipaxSimplifiedVariables,
  IFlightSearchRedesign,
  IPersonalBookingFlags,
  IUsePartnerFlags,
  MobAppUpdateConfig,
  ThemeOptions,
} from '../types';

export type THarnessFlags = {
  BE_TEST_FLAG: boolean;
  FE_TEST_FLAG: boolean;

  // Web Feature Flags
  FE_FLIGHT_BOOKING_WINDOW: boolean;
  FE_LLF_INCREMENT_HALF_HOUR: boolean;
  FE_COMMON_NEW_BOOKING_FEEDBACK_PAGE: boolean;
  FE_AIR_FETCH_LOYALTY_FROM_API: boolean;
  FE_AIR_FLIGHT_NUMBER_INPUT_TOOLTIP: boolean;
  FE_AIR_CANCELLATION_NUMBER: boolean;
  FE_RINGS: boolean;
  FE_PROFILE_USER_IMPERSONATION: boolean;
  FE_AIR_RETRY_PAYMENT: boolean;
  FE_WEB_CHECK_IN: boolean;
  FE_PROFILE_PRONOUNS: boolean;
  FE_AIR_MANUAL_FORM_CANCEL: boolean;
  FE_AIR_MANUAL_FORM_CANCEL_FOR_FLIGHT_CREDIT: boolean;
  FE_INFRA_BUG_FILLING: boolean;
  FE_INFRA_SHOW_DEBUG_LINK: boolean;
  FE_AIR_NEW_EXCHANGE_FORM_CANCELLATION: boolean;
  FE_HOTEL_CHECKOUT_AMOUNT_DUE_TEXT: boolean;
  FE_HOTEL_MARRIOTT_UNAVAILABILITY_NOTIFICATION: boolean;
  FE_HOTEL_SHOW_ADDITIONAL_CANCELLATION_DETAILS: boolean;
  FE_HOTEL_SEARCH_RESULTS_LATENCY: boolean;
  FE_HOTEL_ENABLE_LATENCY_PRICE_SORT: boolean;
  FE_AIR_SEARCH_HIGH_FARE: boolean;
  FE_AIR_MANUAL_FORM_CHARGE_TRAVELER: boolean;
  FE_TMC_UAPP_AND_UATP_ENHANCEMENT: boolean;
  FE_AIR_REBOOK_FLIGHT: boolean;
  FE_AIR_ALLOW_SSR_NON_AGENTS: boolean;
  FE_AIR_DEFAULT_OOP_FILTER_ENABLED: boolean;
  FE_AIR_PREFILL_LAST_SEARCH: boolean;
  FE_AIR_SHOW_OPEN_DEBUG_TOOL_FOR_EVERYONE: boolean;
  FE_AIR_SHOW_LOWER_FARES_AVAILABLE_FOR_LLF_OOP: boolean;
  FE_COMMON_SHOW_ERROR_DETAILS_TRAVELLER: boolean;
  FE_AIR_SHOW_TRAVEL_RESTRICTIONS: boolean;
  FE_AIR_CHECKOUT_V2: boolean;
  FE_IN_APP_BUG_REPORTING: boolean;
  FE_DEFAULT_OOP_FILTER_ENABLED: {
    hotels: boolean;
    cars: boolean;
  };
  FE_SHOW_SEAT_CONFIRMATION_MODAL: boolean;
  FE_AIR_DEFAULT_SEPARATE_TICKET_FILTER_ENABLED: boolean;
  FE_INVOICE_DOWNLOAD: boolean;
  FE_AIR_ALLOW_MULTI_AIRPORT_SELECTION: boolean;
  FE_HOTEL_REBOOK: boolean;
  FE_INFRA_FULL_STORY: boolean;
  FE_TRAINLINE_SPLIT_TICKETS: boolean;
  FE_TRAINLINE_GROUP_SAVE: boolean;
  FE_RAIL_AVOID_VIA_FILTER: boolean;
  FE_AIR_WAIVER_CODE: boolean;
  FE_AIR_WAIVER_CODE_BOOKING_FLOW: boolean;
  FE_NEW_RAIL_CANCEL: boolean;
  FE_NEW_RAILCARD_PICKER: boolean;
  FE_CAR_SPECIAL_REQUEST: boolean;
  FE_CAR_ACRISS_CODE: boolean;
  FE_NEGOTIATED_RATES_VISIBILITY: boolean;
  FE_RATE_ASSUARANCE_RE_BOOKING: boolean;
  FE_ANCILLARY_UI: boolean;
  FE_HOTEL_UPDATED_CANCELLATION_CALCULATION: boolean;
  FE_SHELL_PNR_FINALIZE: boolean;
  FE_MANUAL_FORM_FINALIZE: boolean;
  FE_HOTEL_SPECIAL_REQUESTS: boolean;
  FE_HOTEL_MODIFY_CHECKOUT_MIGRATION: boolean;
  FE_HOTEL_SHOW_DEPOSIT_LABEL: boolean;
  FE_HOTEL_SHOW_BOOKED_TIMES_LABEL: boolean;
  FE_LIMO_OBT: boolean;
  FE_AGENT_FEE: boolean;

  FE_VISA_REQUIREMENTS: boolean;
  FE_COMMON_NEW_CONFIRMATION_PAGE: boolean;
  FE_AGENT_TRIP_ACTIVITY_QC_TAB: boolean;
  FE_AIR_FLIGHT_CREDITS_AND_MCO_IMPROVEMENTS: boolean;
  FE_AIR_RAIL_SEARCH_SUGGESTION: boolean;
  FE_COMPANION_VIEW_SHOW_TRAVELER_DEFAULT_PCC: boolean;
  FE_AGENT_SEAT1A_TAB: boolean;
  FE_AIR_SEARCH_SOURCE: boolean;
  FE_CORP_EVENTS_GUEST_TRAVELER: boolean;
  FE_AIR_HIDE_REFUNDABILITY_FILTER: boolean;
  FE_TMC_HIDE_UNMASKING_IN_UATP_AND_UAPP_PROGRAMS: boolean;
  FE_TMC_SEND_SUPPLIER_ID_FOR_PCC_LIST: boolean;
  FE_SERVICE_FEE_CUSTOM_SETTING_FOR_ENTITY: boolean;
  FE_AIR_AMADEUS_ADDITIONAL_LOYALTY_INFO: boolean;
  FE_CUSTOM_FIELD_LOCATIONS: boolean;
  FE_AIR_FARE_SHELF_CHEAPEST_DETAILS: boolean;
  FE_FLIGHT_CREDIT_FILTER: boolean;
  FE_SUGGESTED_FLIGHT_OPTIONS: boolean;
  FE_AMADEUS_CHECKOUT_CONFIG: {
    jsUrl: string;
    cssUrl: string;
  };
  FE_LLF_IMPROVEMENTS_AIR: boolean;
  FE_SPOTTERNET: boolean;
  FE_ENABLE_TWILIO_CHAT: boolean;

  FE_AGENT_HELP_CENTER: boolean;
  FE_INFRA_DARK_MODE: boolean;
  FE_AGENT_FEE_SETTINGS: boolean;
  FE_TMC_SPOTBOT: boolean;
  FE_INFRA_TRAVEL_TYPES: {
    name: 'FLIGHTS' | 'HOTELS' | 'CARS' | 'RAILS' | 'OTHERS' | 'LIMO';
    order: number;
    enabled: boolean;
  }[];
  FE_TMC_HIDE_SUPPLIER_V1: boolean;
  FE_TMC_SUPPLIER_CONFIGURATIONS: boolean;
  FE_INFRA_TRAVEL_TYPES_IS_GUEST_VALUE: boolean;
  FE_AIR_CARBON_COST: boolean;
  FE_SEAT1A_CHECKLIST: boolean;
  FE_INFRA_PARTNER_FLAG: IUsePartnerFlags;
  FE_AIR_GROUP_BOOKING: boolean;
  FE_CONTACT_HISTORY_TRIP_LEVEL: boolean;
  FE_CORPORATE_EVENT_GUESTS: boolean;
  FE_COMMON_NONBINARY_GENDER: boolean;
  FE_AIR_MULTIPAX_SIMPLIFIED: IAirMultipaxSimplifiedVariables;
  FE_COMMON_PERSONAL_BOOKING: IPersonalBookingFlags;
  FE_AIR_TEST_BOOKING: boolean;
  FE_AIR_SHOW_CANCEL_RECOMMENDATION_MODAL: boolean;
  FE_AIR_SEARCH_RESULTS_SHIMMER: boolean;
  FE_AIR_SHOW_INLINE_SEATMAP_SHOPPING: boolean;
  FE_AIR_ADD_EDIT_UNUSED_CREDIT: boolean;
  FE_INFRA_GDPR: boolean;
  FE_COMMON_NEW_SEARCH_DATE_PICKER: boolean;
  FE_HOTEL_DETAILS_FILTERS_NEW_UX: boolean;
  FE_HOTEL_DETAILS_DATE_SELECTION: boolean;
  FE_COMMON_NEW_AUTOCOMPLETE: boolean;
  FE_HOTEL_LOYALTY_ID_VALIDATION: boolean;
  FE_SERVICE_FEE_RULE_ENGINE: boolean;
  FE_ADD_USER_SCREEN_MANAGER_EMAIL_DETAIL: boolean;
  FE_ADD_USER_SCREEN_GRADE_FIELD: boolean;
  FE_FAKE_ORG_MANAGEMENT: boolean;
  FE_AIR_URL_VALIDATION: { checkout: boolean; searchResults: boolean };
  FE_AIR_FLIGHT_MANUAL_FORM_CREATE_V2: boolean;
  FE_REQUESTED_BY_NON_PROFILED_TRAVELER: boolean;
  FE_AIR_MANUAL_FORM_EDIT: boolean;
  FE_AIR_SEARCH_UX_REDESIGN: IFlightSearchRedesign;
  FE_AIR_SHARE_FARE_QUOTE_V2: {
    enabled: boolean;
    all_sources: boolean;
    sabre: boolean;
    travel_fusion: boolean;
    ndc: boolean;
    amadeus: boolean;
    farelogix_ndc: boolean;
    southwest: boolean;
    cleartrip: boolean;
    kyte: boolean;
  };
  FE_AIR_MANUAL_FORM_EXCHANGE: boolean;
  FE_AIR_MANUAL_FORM_ALL_CANCEL: boolean;
  FE_AIR_MANUAL_FORM_UPLOAD_DOCUMENT: boolean;
  FE_HOTEL_MANUAL_FORM_UPLOAD_DOCUMENT: boolean;
  FE_LIMO_MANUAL_FORM_UPLOAD_DOCUMENT: boolean;
  FE_RAIL_MANUAL_FORM_UPLOAD_DOCUMENT: boolean;
  FE_CAR_MANUAL_FORM_UPLOAD_DOCUMENT: boolean;
  FE_SKIP_TRAVELER_NAME_VALIDATION: boolean;
  FE_PAYMENT_DELAYED_INVOICE_MANUAL_FORM: boolean;
  FE_TMC_SUPPLIER_CRUD: boolean;
  FE_AIR_SPLIT_PAYMENT: boolean;
  FE_HOTEL_COMPANY_SPECIAL_PRICE: boolean;
  FE_HOTEL_SHOPPING_SEARCH_AUTOCOMPLETE_NEW: boolean;
  FE_CAR_MANUAL_FORM_DIRECT_BILLING_FOP: boolean;
  FE_CREATE_AGENT_TASK: boolean;
  FE_AGENT_COMPANION_VIEW: boolean;
  FE_AGENT_QUEUE_DASHBOARD_V3: boolean;
  FE_AGENT_SERVICING_NOTES: boolean;
  FE_RAIL_EXCHANGES: boolean;
  FE_RAIL_MULTIPAX: boolean;
  FE_RAIL_LONDON_TRAVELCARDS: boolean;
  FE_RAIL_SEARCH_AUTOCOMPLETE_NEW: boolean;
  FE_SEAT_SELECTION_REMARKS: boolean;
  FE_ENABLE_EVENTS_REPORT: {
    // ENABLED: boolean;
    liveboardId: string;
  };
  FE_INFRA_LE_EXTERNAL_ID: boolean;
  FE_INFRA_AUTOCOMPLETE_PREFERRED_NAME: boolean;

  FE_BACKOFFICE_CHROMERIVER: boolean;
  FE_CAR_SHOPPING_REDESIGN_FILTERS_SIDEBAR: boolean;
  FE_CAR_CARD_NEW_DESIGN: boolean;
  FE_AIR_FARE_BREAKUP_REVALIDATE: boolean;
  FE_VIRTUAL_CARD_FOP_CARS: boolean;
  FE_CAR_ALLOW_EDIT_LOYALTY: boolean;
  FE_AIR_QC_ENABLED: boolean;
  FE_AIR_PROMO_CODE: boolean;
  FE_CAR_MODIFY_BOOKING: boolean;
  FE_AIR_DISRUPTION_MANAGEMENT: boolean;
  FE_DIRECT_BILLING_NEW_UI: boolean;
  FE_ALLOW_CHANGE_FOP_HOTEL_MODIFY: boolean;
  FE_USE_PRICE_CHECK_RATE_OPTION: boolean;
  FE_HOLD_V2: boolean;
  FE_AIR_HOLD_SEAT: boolean;
  FE_HOTEL_RADIUS_SLIDER: boolean;
  FE_PAYMENTS_CVV_DOC_LINK: boolean;
  FE_PAYMENTS_CARD_OWNERSHIP_LABEL: boolean;
  FE_CAR_VPAY_NEW_UI: boolean;
  FE_AIR_EXCHANGE_CVV_PAYMENT_SETUP: boolean;
  FE_PAYMENTS_LODGE_CARD_INDICATOR: boolean;
  FE_PAYMENTS_SETUP_CALL_RETRY_PAYMENTS: boolean;
  FE_PAYMENTS_SETUP_CALL_SEAT_SELECTION: boolean;
  FE_PAYMENTS_DELAYED_INVOICE_PAYMENT_METHOD: boolean;
  FE_MARRIOTT_HOTEL_FARE_BREAKDOWN_RENAMING: boolean;
  FE_PAYMENTS_HIDE_DELAYED_INVOICE_TOGGLE: boolean;
  FE_PAYMENTS_SCALE_LEGAL_ENTITY: boolean;
  FE_PAYMENTS_MIGRATE_TMC_CARDS: boolean;
  FE_CAR_SEARCH_AUTOCOMPLETE_NEW: boolean;
  FE_INFRA_PAYMENTS_LIST_V2: boolean;
  FE_PAYMENTS_RAZORPAY_INDIA: boolean;
  FE_PAYMENTS_TRIP_FEE_ENHANCEMENT: boolean;

  // WEB Data/AI feature flags
  FE_REPORTS: string;
  FE_REPORTS_JSON_WITH_CATEGORIES: string;
  FE_REPORTS_FOR_MANAGERS: boolean;
  FE_SELF_SERVE_V1: boolean;
  FE_REPORTS_FAVORITES: boolean;
  FE_REPORTS_SCHEDULES: boolean;
  FE_REPORTS_SCHEDULES_WRITE_ACCESS: boolean;
  FE_REPORTS_TS_COOKIELESS_AUTH: boolean;
  FE_REPORTS_TS_IDLE_TIMEOUT_REINIT: boolean;

  // WEB Corporate Feature flags
  FE_CORP_POLICY_USER_UPLOAD: boolean;
  FE_CORP_PROFILE_ARRANGER: boolean;
  FE_CORP_PROFILE_DISPLAY_BUSINESS_INFO: boolean;
  FE_CORP_SPLIT_PAYMENT_INFO: {
    cabinShouldSplit: boolean;
    cabinPersonalFop: boolean;
    ancillaryShouldSplit: boolean;
    ancillaryPersonalFop: boolean;
  };
  FE_CORP_COMPANY_SITE_MESSAGING: boolean;
  FE_CORP_DESIGNATED_APPROVER: boolean;
  FE_CORP_SUPPLIER_MANAGEMENT: boolean;
  FE_CORP_POLICY_AIR_DOM_INTL_SPLIT: boolean;
  FE_CORP_100K_CUSTOM_FIELDS: boolean;
  FE_CORP_100K_CUSTOM_FIELDS_MANUAL_OPTIONS: boolean;
  FE_CORP_SECONDARY_APPROVER: boolean;
  FE_CORP_TRIP_LEVEL_CUSTOM_FIELD: boolean;
  FE_CORP_CC_TO_MANAGER: boolean;
  FE_CORP_POLICY_USER_GROUP_PERSONA_OPTION: boolean;
  FE_CORP_SHARED_USER_INFO_FORM: boolean;
  FE_CORP_POLICY_HOTEL_RESTRICT_KEYWORDS: boolean;
  FE_CORP_POLICY_REASON_CODES: boolean;
  FE_CORP_POLICY_RAIL_POLICY_APPROVAL: boolean;
  FE_CORP_POLICY_SHOW_NOT_ALLOWED: boolean;
  FE_CORP_SAFETY_RICH_TEXT: boolean;
  FE_CORP_NOT_ALLOWED_ENGINE_TYPES: boolean;
  FE_CORP_SITE_MESSAGING_RICH_TEXT: boolean;
  FE_CORP_CUSTOM_FIELD_SFTP: boolean;
  FE_CORP_FETCH_LOYALTIES_FROM_API: {
    hotels: boolean;
    profile: boolean;
    flights: boolean;
    hotelsCheckoutLoyaltyAddApplicable: boolean;
    hotelsCheckoutLoyaltyAutoSelectApplicable: boolean;
    cars: boolean;
  };
  FE_CORP_TRAVELER_NOTES: boolean;
  FE_CORP_POLICY_DEFAULT_APPROVERS: boolean;
  FE_CORP_TAAS_SETTINGS: {
    legalEntityRemarks: boolean;
    tmc_remarks: boolean;
    updated_pcc_configuration: boolean;
    email_recipients: boolean;
    community_model: boolean;
    enabled: boolean;
  };
  FE_CORP_BOOK_FOR_GUEST: boolean;
  FE_CORP_POLICY_HOTEL_MEDIAN_RATES: boolean;
  FE_CORP_HOTEL_MEDIAN_RATE_V3: boolean;
  FE_CORP_BULK_UPLOAD_USER_ROLES: boolean;
  FE_CORP_BULK_UPLOAD_LEGAL_ENTITY: boolean;
  FE_CORP_BULK_UPLOAD_OFFICES: boolean;
  FE_CORP_BULK_DOWNLOAD_USERS: boolean;
  FE_CORP_LEFT_NAV_V2: boolean;
  FE_CORP_NON_EMPLOYEE_POLICY: boolean;
  FE_CORP_ADMIN_PAGINATION_OFFICE: boolean;
  FE_CORP_CUSTOMIZE_CABIN_BY_MILEAGE: boolean;
  FE_CORP_SFTP_INTEGRATION: boolean;
  FE_CORP_ADMIN_PAGINATION_LEGAL_ENTITY: boolean;
  FE_CORP_HIDE_HOTEL_CUSTOMIZATION_FOR_NON_DEFAULT_POLICY: boolean;
  FE_CORP_PROFILE_BOOKING_NAME: boolean;
  FE_CORP_USER_RESTRICTIONS: boolean;
  FE_CORP_COMPANY_GENERAL_UI_V2: boolean;
  FE_CORP_COMPANY_APPLICATION_VISIBILITY: boolean;
  FE_CORP_ONBOARDING_IMPLEMENTATION: boolean;
  FE_CORP_BONVOY_LOGIN: boolean;
  FE_CORP_ONBOARDING_INVITE_USERS_EMAIL: boolean;
  FE_CORP_BREX_INTEGRATION: boolean;
  FE_CORP_NEW_INTEGRATIONS_UI: boolean;
  FE_CORP_APP_HEADER_EXPENSE_TAB: boolean;
  FE_CORP_ASYNC_DOWNLOAD_USERS: boolean;
  FE_CORP_ASYNC_DOWNLOAD_LEGAL_ENTITIES: boolean;
  FE_CORP_ASYNC_DOWNLOAD_OFFICES: boolean;
  FE_CORP_ASYNC_DOWNLOAD_ROLES: boolean;
  FE_CORP_SSO_INTEGRATIONS: boolean;
  FE_CORP_EMBED_STORE_REDIRECT_ON_ERROR: boolean;
  FE_CORP_REMOVE_AZURE_AND_CUSTOM_AUTH_METHODS: boolean;
  FE_CORP_SCALE_OFFICE: boolean;
  FE_CORP_POLICY_PARENT_CHANGE: boolean;
  FE_CORP_SCALE_LEGAL_ENTITY: boolean;
  FE_CORP_SHOW_PERSISTENT_SITE_MESSAGES: boolean;
  FE_CORP_NEW_ACTIVITY_SIDEDRAWER: boolean;
  FE_CORP_CONFIGURE_SEAT1A: boolean;
  FE_CORP_POLICY_LLF_MIXED_CABIN: boolean;
  FE_CORP_USER_SEARCH_ACROSS_ORG: boolean;
  FE_CORP_POLICY_PREVENT_BOOKING: boolean;
  FE_CORP_AUTH_DYNAMIC_REDIRECT_URL: boolean;
  FE_CORP_USER_COUNTRY_RESTRICTIONS: boolean;
  FE_AMADEUS_WITH_TRAVEL_FUND: boolean;
  FE_CORP_DISABLE_LE_TIER: boolean;
  FE_CORP_LOGOUT_QUERY_PARAM: boolean;
  FE_CORP_EMERGENCY_CONTACT_V2: boolean;
  FE_CORP_SHOW_INACTIVE_APPROVERS: boolean;
  FE_CORP_POLICY_OVERNIGHT_FLIGHT_V2: boolean;
  FE_CORP_POLICY_HOTEL_LOWEST_FARE: boolean;
  FE_CORP_AUTHENTICATION_PAGE_ENABLED: boolean;

  // WEB Trips Feature Flags
  FE_TMC_EMAIL_COMM_PREFS: boolean;
  FE_TMC_TRIPS_WITH_V3: boolean;
  FE_COMPANY_TRIPS_PAGE: boolean;
  FE_TRIPS_STATUS_FILTER: boolean;
  FE_TRIPS_V3_PAYMENT_DETAILS: boolean;
  FE_RETURN_TO_COMPANY_TRIPS: boolean;
  FE_TRIPS_DISABLE_MULTIPAX_ADD_TO_TRIPS: boolean;
  FE_TRIPS_AIR_SPLIT_PNR: boolean;
  FE_TRIPS_APPROVAL_DASHBOARD: boolean;
  FE_TRIPS_FLIGHT_STATS_NOTIFICATIONS: boolean;
  FE_TRIPS_BREX_OFFLINE_BOOKING: boolean;
  FE_TRIP_ACTIVITY_SSR_OSI_TAB: boolean;
  FE_COMMON_TRIP_FEATURES: boolean;
  FE_TRIP_REMARKS: {
    ENABLED: boolean;
    NEW_BADGE_ENABLED: boolean;
  };
  FE_TRIP_V3_DETAILS_API: boolean;
  FE_TRIPS_QC_VERFICATION: boolean;
  FE_TMC_TRIPS_UNFINALIZE: boolean;
  FE_TMC_PAYMENT_METHODS_V3: boolean;
  FE_CORP_PASSIVE_APPROVAL_OPTION: boolean;
  FE_INFRA_PRIVACY_NOTICE: boolean;
  FE_INFRA_AUTOCOMPLETE_TMC_NAME: boolean;
  FE_INFRA_SUPPORT_CONFIG_PAGE: boolean;
  FE_PNR_REMARKS: boolean;
  FE_INFRA_SUPPORT_HELP_CENTER_LINK: {
    enabled: boolean;
    helpCenterUrl: string;
  };
  FE_TMC_FINALIZE_FORCE_TICKETING: boolean;
  FE_INFRA_VIRTUAL_ORGANISATION_SELECTOR: boolean;
  FE_TMC_API_CONFIGURATIONS: boolean;
  FE_LOGIN_ACTIONS: boolean;
  FE_TMC_COMPANY_TRIPS_V3: boolean;
  FE_TMC_TRIP_DETAIL_WITH_V3: boolean;
  FE_TMC_TRIP_NOTES: boolean;
  FE_TMC_TRIPS_V3_PAYMENT_DETAILS: boolean;
  FE_TMC_DISABLE_BOOKING: boolean;
  FE_TMC_AIRLINE_FOP: boolean;
  FE_TMC_TRIPS_FOR_ARRANGER: boolean;
  FE_TMC_COMPANY_TRIP_FILTERS_V3: boolean;
  FE_TMC_COMPANY_TRIP_LIST_V3: boolean;
  FE_TMC_CONFIG_MIDOFFICE_RULES: boolean;
  FE_TMC_SKIP_PCC_CRED_VALIDATION: boolean;
  FE_TMC_HIDE_DEFAULT_PCCS: boolean;
  FE_TMC_EMAIL_BUILDER: boolean;
  FE_CARBON_EMISSIONS_AIR_SEARCH: boolean;
  FE_INITIATE_BOOKING_VALIDATION: boolean;
  FE_CARBON_EMISSIONS_REDESIGN: boolean;
  FE_AIR_SEATMAP_SHOW_LOYALTY_WARNING: boolean;
  FE_INFRA_TRAVELER_AUTOCOMPLETE_BLOCKS: boolean;
  FE_INFRA_THEME_OPTIONS: ThemeOptions;
  // temporary flag to mimic api call
  FE_INFRA_CONFIG_DRIVEN_WHITELABELLING: Partial<BlocksPalette>;
  FE_INFRA_PREFILLED_CUSTOM_FIELDS: boolean;
  FE_TMC_TRIPS_NOTES_V2: boolean;
  FE_TMC_AUDIT_LOGS: boolean;
  FE_TS_AUDIT_LOGS_DATASOURCE_ID: string;

  // Web Events Feature Flags
  FE_EVENTS_CUSTOM_FIELD: boolean;
  FE_EVENTS_ENTITY_AUTOCOMPLETE_TRIPS: boolean;
  FE_EVENTS_DISABLE_VALIDATE_ITINERARY: boolean;
  FE_EVENTS_BULK_OPERATIONS: {
    checkboxSelection: boolean;
    hideActions: boolean;
    invitationStatusFilter: boolean;
  };
  FE_EVENTS_ORGANIZATION_FILTER: boolean;
  FE_EVENTS_AIRPORT_SELECTOR: boolean;
  FE_EVENTS_BOOKING_STATUS_FILTER: boolean;
  FE_EVENTS_ALLOWED_FLIGHT_TYPES: boolean;
  FE_EVENTS_POC_PAYMENT_SOURCES: boolean;
  FE_EVENTS_SUGGESTED_ARRIVAL_DEPARTURE: boolean;
  FE_ROUTE_TO_HUMAN_AGENT: boolean;
  FE_END_CHAT_BY_USER: boolean;

  FE_STENCIL_NEW_TRIP_TEMPLATE: boolean;

  // Mobile Feature Flags
  MOB_RINGS: boolean;
  MOB_INFRA_DEV_MENU: boolean;
  MOB_PROFILE_PRONOUNS: boolean;
  MOB_NEW_AIR_EXCHANGE_CHECKOUT: boolean;
  MOB_AIR_NEW_EDIT_SEAT_CHECKOUT: boolean;
  MOB_AIR_RETRY_PAYMENT: boolean;
  MOB_WEB_CHECK_IN: boolean;
  MOB_INFRA_FULL_STORY: boolean;
  MOB_INVOICE_DOWNLOAD: boolean;
  MOB_CORP_COMPANY_SITE_MESSAGING: boolean;
  MOB_RAIL_SEATMAP: boolean;
  MOB_TRAINLINE_SPLIT_TICKETS: boolean;
  MOB_NEW_RAIL_CANCEL: boolean;
  MOB_AIR_REBOOK_FLIGHT: boolean;
  MOB_CORP_100K_CUSTOM_FIELDS: boolean;
  MOB_VISA_REQUIREMENTS: boolean;
  MOB_CORP_TRIP_LEVEL_CUSTOM_FIELD: boolean;
  MOB_INFRA_THEME_CUSTOMISATION: boolean;
  MOB_INFRA_UPDATE_APP_DIALOG: boolean;
  MOB_INFRA_PARTNER_FLAGS: boolean;
  MOB_AIR_CONFIRMATION_PAGE: boolean;
  MOB_TRIP_FEATURES: boolean;
  MOB_AIR_CARBON_COST: boolean;
  MOB_AIR_SEARCH_SOURCE: boolean;
  MOB_AIR_SHOW_INLINE_SEATMAP_SHOPPING: boolean;
  MOB_AIR_NATIONALITY_SELECTOR: boolean;
  MOB_AIR_SHOW_REMOVE_BUTTON_AIR_EXCHANGE: boolean;
  MOB_AIR_SHOW_TRAVEL_POLICY: boolean;
  MOB_AIR_SHOW_CANCEL_RECOMMENDATION_MODAL: boolean;
  MOB_COMMON_NON_BINARY_GENDER: boolean;
  MOB_AIR_MULTIPAX_SIMPLIFIED: IAirMultipaxSimplifiedVariables;
  MOB_COMMON_PERSONAL_BOOKING: IPersonalBookingFlags;
  MOB_CORP_PROFILE_PREFERRED_LOCATIONS: boolean;
  MOB_CORP_CENTER_ACCOUNT_SWITCHING: boolean;
  MOB_RAIL_BOOKING: boolean;
  MOB_JAIL_BREAK_DETECTION: boolean;
  MOB_SSL_PINNING: boolean;
  MOB_CORP_PROFILE_BOOKING_NAME: boolean;
  MOB_INFRA_PRIVACY_NOTICE: boolean;
  MOB_CORP_USER_RESTRICTIONS: boolean;
  MOB_PAYMENTS_CARD_OWNERSHIP_LABEL: boolean;
  MOB_LOGIN_ACTIONS: boolean;
  MOB_TMC_TRIP_NOTES: boolean;
  MOB_APP_UPDATE_CONFIG: MobAppUpdateConfig;
  MOB_WEBVIEW_APP: boolean;
  MOB_ENABLE_TWILIO_CHAT: boolean;
  MOB_INFRA_PAYMENTS_LIST_V2: boolean;
  MOB_INSTABUG_BUG_REPORTING: boolean;
  MOB_CORP_SHOW_PERSISTENT_SITE_MESSAGES: boolean;
  MOB_PAYMENTS_RAZORPAY_INDIA: boolean;
  MOB_NEW_SUPPORT_CONTACT: boolean;
  MOB_TMC_TRIPS_NOTES_V2: boolean;
  MOB_DYNAMIC_FONTS_ENABLED: boolean;
  MOB_ROUTE_TO_HUMAN_AGENT: boolean;
  MOB_END_CHAT_BY_USER: boolean;

  // permanent flags
  BE_BREX_CONTROLS: BrexControls;
};

export const useFeatureFlag: <Key extends keyof THarnessFlags, TValue extends THarnessFlags[Key]>(
  flagIdentifier: Key,
  defaultValue?: TValue,
) => TValue | undefined = useFF;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFeatureFlags: <T extends Partial<THarnessFlags>>(flagIdentifiers?: T) => T = useFFs as any;
