import { PropsWithChildren } from 'react';
import { useLoggedInUserId } from 'obt-common/providers/AuthProvider';
import { useUserAuthConfigQuery } from 'obt-common/queries/users';
import Config from 'obt-common/utils/Config';
import { webSessionStorage } from 'obt-common/utils/SessionStorage';
import { StorageKeys } from 'obt-common/types';
import { useShouldGenesysRedirect } from './useShouldGenesysRedirect';

/**
 * Redirect to IDP url for Genesys auth.
 * Please refer to: https://help.mypurecloud.com/articles/authenticated-web-messaging-overview/
 */
export function GenesysCloudAuthRedirect({ children }: PropsWithChildren<{}>): JSX.Element | null {
  const userId = useLoggedInUserId();
  const { data: authConfig, isLoading: authConfigLoading } = useUserAuthConfigQuery(userId?.userId?.id);
  // When logout, userId is empty
  const { metadata } = authConfig?.authProviders?.[0] || {};
  const { id: identityProvider } = metadata?.azure || {};

  const { isLoading, shouldRedirect } = useShouldGenesysRedirect();

  if (isLoading || authConfigLoading) {
    return null;
  }

  if (shouldRedirect) {
    const { VITE_GENESYS_CHAT_CLIENT_ID, VITE_SPOTNANA_IDP_URL } = Config;
    const { origin, href } = window.location;
    const encodedOrigin = origin;

    const urlParams: { [key in string]: string } = {
      redirect_uri: encodedOrigin,
      state: 'genesys',
      response_type: 'code',
      client_id: VITE_GENESYS_CHAT_CLIENT_ID as string,
      scope: 'email openid profile',
    };
    /* istanbul ignore next: For not bloating the unit test cases, ignoring low risk statement */
    if (identityProvider) {
      /* istanbul ignore next: For not bloating the unit test cases, ignoring low risk statement */
      urlParams.identity_provider = identityProvider;
    }

    const stringifiedUrlParams = new URLSearchParams(urlParams).toString();
    const urlStr = `${VITE_SPOTNANA_IDP_URL}/oauth2/authorize?${stringifiedUrlParams}`;
    const path = href.substring(origin.length);
    webSessionStorage.setItem(StorageKeys.URL_BEFORE_GENESYS_REDIRECT, path);
    window.location.href = urlStr;
    return null;
  }

  return <>{children}</>;
}
