/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * APPROVAL Status
 * @export
 * @enum {string}
 */

export const ApprovalStatus = {
  ApprovalPending: 'APPROVAL_PENDING',
  Approved: 'APPROVED',
  Denied: 'DENIED',
  TimedOut: 'TIMED_OUT',
  SoftApprovalTimeout: 'SOFT_APPROVAL_TIMEOUT',
} as const;

export type ApprovalStatus = (typeof ApprovalStatus)[keyof typeof ApprovalStatus];
