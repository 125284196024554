import { useLoggedInUserId } from 'obt-common/providers/AuthProvider';
import { useUserAuthConfigQuery } from 'obt-common/queries/users';
import { useSupportConfigForCurrentOrganization } from 'obt-common/queries/supplier';
import { isGenesysAuthCloudConfigValid } from 'obt-common/utils/genesys/isGenesysAuthCloudConfigValid';
import { useExternalAuthCodeContext } from '../ExternalAuthCodeProvider/ExternalAuthCodeProvider';

export type ShouldGenesysDirect = {
  shouldRedirect: boolean;
  isLoading: boolean;
};

export function useShouldGenesysRedirect(): ShouldGenesysDirect {
  const userId = useLoggedInUserId();
  const authCodeContext = useExternalAuthCodeContext();
  const { data: authConfig, isLoading: authConfigLoading } = useUserAuthConfigQuery(userId?.userId?.id);
  const { authProviderType } = authConfig?.authProviders?.[0] || {};
  const { isLoading: supportConfigLoading, data: supportConfigData } = useSupportConfigForCurrentOrganization();
  let shouldRedirect = false;
  if (!authCodeContext.authCode && window?.location?.origin) {
    // no authCode and not in mobile
    shouldRedirect = isGenesysAuthCloudConfigValid(supportConfigData, authProviderType);
  }

  return {
    shouldRedirect,
    isLoading: authConfigLoading || supportConfigLoading,
  };
}
