/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of APPROVAL
 * @export
 * @enum {string}
 */

export const ApprovalType = {
  HardApproval: 'HARD_APPROVAL',
  SoftApproval: 'SOFT_APPROVAL',
  PassiveApproval: 'PASSIVE_APPROVAL',
} as const;

export type ApprovalType = (typeof ApprovalType)[keyof typeof ApprovalType];
