import { css } from '@emotion/react';
import { themed } from '../utils/styling';
import { cornerRadii } from '../utils/styling/utilityTokens';

export const icon_button_base = themed(
  ({ palette }) => css`
    &.MuiButton-root {
      padding: 0;
      background: transparent;
      min-width: 0;

      &:not(.Mui-disabled) {
        &:hover {
          background: ${palette.bg.hover};
        }
      }
    }

    &.Mui-disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: all;

      & > .BlocksIconButton-Icon {
        color: ${palette.icon.light};
      }
    }

    &:focus-visible {
      outline: 2px solid ${palette.border.keyboardFocus};
      outline-offset: 1px;

      & .MuiTouchRipple-child {
        border-radius: 0;
        background-color: unset;
      }
    }

    &.BlocksIconButton-CustomSize-xlarge {
      width: 48px !important;
      height: 48px !important;
      ${cornerRadii.buttonStandard}

      & > .BlocksIconButton-Icon {
        width: 24px;
        height: 24px;
      }
    }

    &.MuiButton-sizeLarge {
      width: 40px;
      height: 40px;
      ${cornerRadii.buttonStandard}

      & > .BlocksIconButton-Icon {
        width: 24px;
        height: 24px;
      }
    }

    &.MuiButton-sizeMedium {
      width: 32px;
      height: 32px;
      ${cornerRadii.buttonSmall}

      & > .BlocksIconButton-Icon {
        width: 20px;
        height: 20px;
      }
    }

    &.MuiButton-sizeSmall {
      width: 24px;
      height: 24px;
      ${cornerRadii.buttonSmall}

      & > .BlocksIconButton-Icon {
        width: 16px;
        height: 16px;
      }
    }

    &.BlocksIconButton-rounded,
    &.BlocksIconButton-outlineRounded,
    &.BlocksIconButton-outlineRoundedFilled {
      border-radius: 50%;
    }

    &.BlocksIconButton-outlined,
    &.BlocksIconButton-outlineRounded {
      border: 1px solid ${palette.bg.buttonDisabled};

      &:hover {
        border-color: ${palette.border.hover};
      }

      &.Mui-disabled {
        border-color: ${palette.border.light};
      }
    }

    &.BlocksIconButton-outlineRoundedFilled {
      background-color: ${palette.surface.base};
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .loading-icon {
      animation: spin 1s linear infinite;
    }
  `,
);

export const icon_button_primary = themed(
  ({ palette }) => css`
    color: ${palette.bg.brand};
  `,
);

export const icon_button_standard = themed(
  ({ palette }) => css`
    color: ${palette.text.secondary};
  `,
);

export const icon_button_subtle = themed(
  ({ palette }) => css`
    color: ${palette.text.tertiary};
  `,
);

export const icon_button_info = themed(
  ({ palette }) => css`
    color: ${palette.text.link};
  `,
);

export const icon_button_success = themed(
  ({ palette }) => css`
    color: ${palette.bg.success};
  `,
);

export const icon_button_error = themed(
  ({ palette }) => css`
    color: ${palette.bg.error};
  `,
);

export const icon_button_inverse = themed(
  ({ palette }) => css`
    color: ${palette.icon.inverse};
    &:focus-visible {
      outline: 2px solid ${palette.border.infoInverse};
    }
    &:not(.Mui-disabled) {
      &:hover,
      &:focus-visible {
        background: ${palette.bg.hoverInverse} !important;
      }
    }
    &.Mui-disabled {
      & > .BlocksIconButton-Icon {
        color: ${palette.icon.lightInverse};
      }
    }
  `,
);

export const icon_button_shadow = themed(
  ({ palette }) => css`
    box-shadow: ${palette.shadow.componentShadow};
  `,
);
