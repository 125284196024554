/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Limo type.
 * @export
 * @enum {string}
 */

export const LimoType = {
  StandardCar: 'STANDARD_CAR',
  ExecutiveCar: 'EXECUTIVE_CAR',
  BusinessVan: 'BUSINESS_VAN',
  FirstClass: 'FIRST_CLASS',
  Suv: 'SUV',
  Sedan: 'SEDAN',
  ExecutiveSedan: 'EXECUTIVE_SEDAN',
  LuxurySedan: 'LUXURY_SEDAN',
  ExecutiveVan: 'EXECUTIVE_VAN',
  BusinessSedan: 'BUSINESS_SEDAN',
} as const;

export type LimoType = (typeof LimoType)[keyof typeof LimoType];
