import type { MoneyUtil } from '../utils/Money';
import type { IFlightSearchSegmentState } from './air-segments';
import type { AmadeusPaymentTabEnum, MopList } from './amadeus';
import type { EntityId } from './api/v1/common/entity_reference';
import type { GenderEnum } from './api/v1/common/gender';
import type { PaymentMethod } from './api/v1/common/money';
import type { Int32Range } from './api/v1/common/range';
import type {
  AirAncillariesResponse,
  AirAncillariesResponseAncillaryAncillaryDetailsFlightId,
  AirAncillariesResponseBaggage,
  AirAncillariesResponseReqdParamsForCheckoutParameter,
} from './api/v1/obt/air/air_ancillaries';
import type {
  Alliance,
  Cabin,
  FareCategory,
  FlightNumber,
  MealType,
  PassengerAge,
  PassengerType,
} from './api/v1/obt/air/air_common';
import { FareCategoryCabinViewFareCategory, FareCategoryNGSFareCategory } from './api/v1/obt/air/air_common';
import type {
  AirRevalidateItineraryRequest,
  AirRevalidateItineraryRequestOtherServiceInformation,
  AirRevalidateItineraryRequestTravelerInfoBaggage,
  AirRevalidateItineraryRequestTravelerInfoOtherAncillary,
  AirRevalidateItineraryRequestTravelerInfoSpecialServiceRequest,
  AirUnusedCreditRedemptionRequest,
} from './api/v1/obt/air/air_revalidate_itinerary_request';
import type { AirRevalidateItineraryResponse } from './api/v1/obt/air/air_revalidate_itinerary_response';
import type {
  AirSearchRequest,
  AirSearchRequestCorporateInfo,
  AirSearchRequestLeg,
  AirSearchRequestLegSearchParamsLegCloneInfo,
  AirSearchRequestPaxInfo,
  Filter,
  FilterAllianceFilter,
  FilterCabinFilter,
  FilterFareCategoryFilter,
  FilterFlightNumberFilter,
  FilterMultiTicketFilter,
  FilterPolicyFilter,
  FilterTimeRangeFilter,
  FilterTimeRangeFilterTimeRange,
  FilterUnusedCreditFilter,
  SortOption,
  SortOptionSortBy,
} from './api/v1/obt/air/air_search_request';
import { FilterChangeableFilter } from './api/v1/obt/air/air_search_request';
import type {
  DataFlight,
  DataFlightEquipment,
  DataLeg,
  FareAmount,
  FareType,
  AirSearchResponse as FlightsSearchResponse,
  Itinerary,
  MetadataAirline,
  MetadataFlightRestrictions,
  PaxInfoFareComponentLegInfoFlightInfo,
  RateOption,
  VendorProgramType,
} from './api/v1/obt/air/air_search_response';
import {
  FareTypeEnum,
  MetadataFareCategoryView,
  MetadataFlightRestrictionsRestriction,
  RateOptionTicketType,
} from './api/v1/obt/air/air_search_response';
import type { AirSeatMapRequest, AirSeatMapRequestLoyaltyInfo } from './api/v1/obt/air/air_seat_map_request';
import type {
  AirSeatMapResponse,
  ColumnSection,
  ColumnSectionColumn,
  FacilitySection,
  FacilitySectionFacility,
  Location,
  RowSectionRowType,
  SeatSectionPrice,
  SeatSectionSeatLimitation,
  SeatSectionSeatLocation,
  SeatSectionSeatType,
} from './api/v1/obt/air/air_seat_map_response';
import { SeatSectionSeatFacility } from './api/v1/obt/air/air_seat_map_response';
import type { PromotionCode } from './api/v1/obt/air/promotion_code';
import type { Amenity, Upa, Uta, UtaBaggage, UtaPoints, UtaPolicy } from './api/v1/obt/air/routehappy';
import { UtaPolicyAssessmentType, UtaPolicyValueSeat } from './api/v1/obt/air/routehappy';
import type { FetchTravelerUnusedCreditsResponse } from './api/v1/obt/air/unused_credits';
import type { Preference } from './api/v1/obt/common/common';
import type { PnrRemark } from './api/v1/obt/common/pnr_remarks';
import type {
  PolicyInfo,
  PolicyInfoRuleResultInfo,
  PolicyInfoRuleResultInfoViolationInfo,
} from './api/v1/obt/common/policy_info';
import type { LoyaltyInfo } from './api/v1/obt/common/traveler_personal_info';
import type { PaymentInfo } from './api/v1/obt/common/user';
import type { NonProfileUserInfo, UserOrgId } from './api/v1/obt/common/user_org_id';
import type { BookingPaymentDetails } from './api/v1/obt/pnr/payment';
import type { CheckPnrStatusResponse } from './api/v1/obt/pnr/pnr';
import type { ConstValue } from './api/v1/obt/policy/condition';
import type { EntityAnswer } from './api/v1/obt/policy/user_defined_entity';
import type { EnrollAirlineLoyaltyRequest, EnrollAirlineLoyaltyResponse } from './api/v1/obt/profile/profile_services';
import type { ThirdPartySource } from './api/v1/obt/supplier/third_party_info';
import type { AirCreatePnrResponse } from './api/v1/obt/trip/air_create_pnr_response';
import type { AllowedFopRule, BookingPaymentSource, PaymentRule, PaymentSourceType } from './api/v2/obt/model';
import { CategoryTypeEnum } from './api/v2/obt/model/category';
import type { ExistingLeg } from './api/v2/obt/model/existing-leg';
import type { Leg1 } from './api/v2/obt/model/leg1';
import type { QantasTravelFundMetadataWrapper } from './api/v2/obt/model/qantas-travel-fund-metadata-wrapper';
import type { SpecialServiceRequest } from './api/v2/obt/model/special-service-request';
import type { SpecialServiceRequestsResponse } from './api/v2/obt/model/special-service-requests-response';
import type { IAirSuggestion, IAutocompleteSuggestion } from './autocomplete';
import type { BookingFlow, SharedSearchParams, TripIdUrlParam } from './common';
import type { CustomFieldsEntityAnswerType } from './customFields';
import type { SsrOsiFormValues } from './storage';
import type { INationalDoc, IPassport, IPhoneNumber, ITraveler, IUserOrgId } from './traveler';
import type { WaiverCodeInputValues } from './waiverCodes';

export type { ExistingLeg } from './api/v2/obt/model/existing-leg';
export type { Filter as IFilterV2 } from './api/v2/obt/model/filter';
export type { NewLeg } from './api/v2/obt/model/new-leg';

export { ApplicableUnusedCreditReason as IApplicableUnusedCreditReasonEnum } from './api/v1/obt/air/air_ancillaries';
export { PassengerTypeEnum as IAirPassengerTypeEnum } from './api/v1/obt/air/air_common';
export type { PassengerType as IAirPassengerType, PassengerAge } from './api/v1/obt/air/air_common';
export type {
  AirRevalidateItineraryRequestTravelerInfo,
  AirUnusedCreditRedemptionRequest,
} from './api/v1/obt/air/air_revalidate_itinerary_request';
export { SortOptionSortOrderEnum } from './api/v1/obt/air/air_search_request';
export type { AirSearchRequestPaxInfo } from './api/v1/obt/air/air_search_request';
export {
  Location as AirSeatMapLocation,
  FacilityType,
  RowSectionRowType,
  SeatSectionSeatLimitation,
  SeatSectionSeatLocation,
  SeatSectionSeatType,
} from './api/v1/obt/air/air_seat_map_response';
export type { UnusedCreditInfo } from './api/v1/obt/air/unused_credits';
export { Predicate as PolicyViolationInfoPredicate } from './api/v1/obt/common/policy_info';
export { PreDefinedQuestionPreCheckoutQuestionTypes } from './api/v1/obt/policy/user_defined_entity';

export type ICabin = Cabin;
export type IAmenity = Amenity;
export type AmenityProps = keyof Amenity;
export type UtaProps = keyof Uta;
export type UtaBaggageProps = keyof UtaBaggage;
export type IMealType = MealType;
export type IUpa = Upa;
export type IFilter = Filter;
export type IDataFlight = DataFlight;
export type IDataLeg = DataLeg;
export const RateOptionTicketTypeEnum = RateOptionTicketType;
export type IRateOptionTicketType = RateOptionTicketType;

export type IFilterAllianceFilter = FilterAllianceFilter;
export type IFilterTimeRangeFilter = FilterTimeRangeFilter;
export type IFilterTimeRangeFilterTimeRange = FilterTimeRangeFilterTimeRange;
export type IAlliance = Alliance;
export type IFilterCabinFilter = FilterCabinFilter;
export type IAirRevalidateItineraryResponse = AirRevalidateItineraryResponse;
export type IAirRevalidateItineraryRequest = AirRevalidateItineraryRequest;
export type IAirCreatePnrResponse = AirCreatePnrResponse;
export type IPreSearchAnswer = EntityAnswer;
export type IAirAncillariesResponse = AirAncillariesResponse;
export type IAirResponseRateOption = RateOption;
export type IAirAncillariesResponseReqdParamsForCheckoutParameter =
  AirAncillariesResponseReqdParamsForCheckoutParameter;
export type ICheckPnrStatusResponse = CheckPnrStatusResponse;
export type IFlightNumber = FlightNumber;
export type IAirSeatMapResponse = AirSeatMapResponse;
export type IAirSeatMapRequest = AirSeatMapRequest;
export type IAirSeatMapRequestLoyaltyInfo = AirSeatMapRequestLoyaltyInfo;
export type IUta = Uta;
export type IUtaBaggage = UtaBaggage;
export type IUtaPolicy = UtaPolicy;
export type IPolicyInfo = PolicyInfo;
export type IColumnSection = ColumnSection;
export type IPolicyInfoRuleResultInfoViolationInfo = PolicyInfoRuleResultInfoViolationInfo;
export type IConstValue = ConstValue;
export type IPolicyInfoRuleResultInfo = PolicyInfoRuleResultInfo;
export type IFareCategory = FareCategory;
export type IAvailableFareCategoryViewType = MetadataFareCategoryView.NGS | MetadataFareCategoryView.CABIN;
export type IFilterFareCategoryFilter = FilterFareCategoryFilter;
export type ILoyaltyInfo = LoyaltyInfo;
export type IEnrollAirlineLoyaltyRequest = EnrollAirlineLoyaltyRequest;
export type IEnrollAirlineLoyaltyResponse = EnrollAirlineLoyaltyResponse;

export type IAirSpecialServiceRequestsResponse = SpecialServiceRequestsResponse;
export type ISpecialServiceRequest = SpecialServiceRequest;
export const SpecialServiceRequestCategoryEnum = CategoryTypeEnum;

export { SeatSectionSeatFacility };

export type IFlightRestrictions = MetadataFlightRestrictions;
export const IFlightRestrictionsEnum = MetadataFlightRestrictionsRestriction;

export {
  FareCategoryCabinViewFareCategory,
  FareCategoryNGSFareCategory,
  FareTypeEnum,
  FilterChangeableFilter,
  MetadataFareCategoryView,
  UtaPolicyAssessmentType,
  UtaPolicyValueSeat,
};

export interface IFlightCodeDetails {
  marketing: IFlightNumber;
  operating: IFlightNumber;
  operatingAirlineName: string;
}

export interface ILegAirports {
  arrivalAirportCode: string;
  departureAirportCode: string;
}

export interface IGetLegSummaryProps {
  itinIndex: number;
  legNumber: number;
}

export interface IGetEnhancedFareInfosForPreviousLegsProps {
  itinIndex: number;
  legNumber: number;
}

export interface IGetAllFaresForRateOptions {
  rateOptions: RateOption[];
  itinIndex: number;
  legNumber: number;
  legIndex: number;
}

export interface IGetItneraryFareInfoForRateOption {
  rateOptions: RateOption[];
  legNumber: number;
  legIndex: number;
}

export interface IGetLegDetailsProps {
  itinIndex: number;
  legNumber: number;
}

export interface IFlightLegSummaryInput {
  rateOption: RateOption;
  legIndex: number;
  legNumber: number;
  itinerary: Itinerary;
}

export interface ItinerarySourceInfo {
  source: ThirdPartySource;
  label: string;
}

export interface IItineraryIndexInfo {
  closestMatchItinIndex: number;
  restItineraryIndices: number[];
}

export interface IFlightLegSummary extends IFlightLegSummaryForLegIndex, IFlightLegSummaryForItinerary {
  totalFare: MoneyUtil;
  totalPaxNum: number;
  /** @deprecated */
  policyViolationMessage: boolean;
}
export interface IFlightLegSummaryForLegIndex {
  arrivalDateTime?: string;
  departureDateTime?: string;
  airports: { code: string; name: string }[];
  airlines: IFlightCodeDetails[];
  aircrafts: string[];
  layovers: number[];
  legDuration: string;
  governmentApprovalFlights: FlightNumber[];
}
export interface IFlightLegSummaryForItinerary {
  cabinType: string;
  brandName: string;
  fareType: string;
  bookingCodes: string[];
  preferences: Preference[];
}

export interface ICurrentSelectedItineraryInfo {
  departureTime: string;
  departureTimePeriod: string;
  arrivalTime: string;
  arrivalTimePeriod: string;
  firstFlightMarketingAirline: string;
  marketingAirline: string;
  marketingNumber: string;
  departureDate: string;
  departureAirportCity: string;
  arrivalAirportCity: string;
  legDuration: string;
  airlines: IFlightCodeDetails[];
  totalLegs: number;
}

export interface IFlightAmenity {
  key: string;
  displayText: string;
}

export interface ILegUTA {
  key: UtaProps;
  displayText: string | { carryOn?: string; checkedBaggage?: string; additionalCheckedBaggage?: string };
  uta: Uta;
}

export interface IFlightInfo {
  airline: Carrier;
  arrivalAirport: string;
  arrivalAirportCity: string;
  arrivalAirportName: string;
  arrivalAirportTerminal: string;
  arrivalDateTime: string;
  arrivalCountryCode: string;
  departureAirport: string;
  departureAirportCity: string;
  departureAirportName: string;
  departureAirportTerminal: string;
  departureDateTime: string;
  departureCountryCode: string;
  duration: string;
  marketingAirlineLabel: string;
  operatingAirlineLabel: string;
}

export interface IEarlyBirdSegmentInfo {
  key: string;
  flightInfo: IFlightInfo;
  fare: MoneyUtil;
  isSelected: boolean;
  flightIds: AirAncillariesResponseAncillaryAncillaryDetailsFlightId[];
}

export interface IFlightDetail extends IFlightInfo {
  cabinType: string;
  amenities: IFlightAmenity[];
  utas: ILegUTA[];
  upas: Upa[];
  arrivalCountryCode: string;
  departureCountryCode: string;
}

export interface IFlightSegmentDetail {
  segmentDetail: IFlightDetail;
  flightSegmentIndex: number;
}

export interface IFlightLegDetails {
  overnights: boolean[];
  transfers: string[];
  layovers: number[];
  flightDetails: IFlightDetail[];
  operatingAirlinesSubText: string;
}

export interface ILayoverInfo {
  overnight: boolean;
  transfer: string;
  layoverDuration: number;
  layoverAirportCode: string;
  layoverAirportName: string;
}

export enum FlightDetailsInfoType {
  FLIGHT = 'FLIGHT',
  LAYOVER = 'LAYOVER',
}

export enum TravelerInfoFormStatus {
  NONE,
  EDITING,
  SAVING,
}

export { RateOptionTicketType as TicketTypeEnum };

export interface IFlightWithStopsInfo extends IFlightInfo {
  hiddenLayovers: ILayoverInfo[];
}

export interface IFlightWithAmenitiesInfo extends IFlightWithStopsInfo {
  amenities: IFlightAmenity[];
  aircraft: string;
}

export type IFlightLayoverFlightInfo = {
  type: FlightDetailsInfoType.FLIGHT;
  data: IFlightWithAmenitiesInfo;
  key: string;
};

export type IFlightLayoverLayoverInfo = { type: FlightDetailsInfoType.LAYOVER; data: ILayoverInfo; key: string };

export type IFlightLayoverInfo = IFlightLayoverFlightInfo | IFlightLayoverLayoverInfo;

export interface IBreadcrumb {
  departure: string;
  arrival: string;
}

export interface IItineraryCityInfo {
  cityName: string;
}

export type IBaggageOption = { baggageId: string; label: string; price: MoneyUtil; count?: number };
export type IBaggageCheckoutState = { index: number; label: string; baggageId: string; price: MoneyUtil | null };

export interface IFlightCheckoutState extends AirCreatePnrResponse {
  baggage: IBaggageCheckoutState[];
  seats: ICheckoutSeat[];
}

export type IFacilitySectionFacility = FacilitySectionFacility;

export type IFacilitySection = FacilitySection;

export type IFlightSearchAirlinesFilterState = Record<string, boolean>;

export interface IFlightsUpdateSelectedItineraryAction {
  type: string;
  payload: { itinIndex: number; legNumber: number };
}

export interface IFlightTripTypeProps {
  segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[];
  errors: IFlightSearchSegmentState<string>[];
  updateSegments: (segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[]) => void;
  updateErrors: (errors: IFlightSearchSegmentState<string>[]) => void;
  disableAirportPickers: boolean;
  allowedBookingDateRange?: { from: Date; to: Date };
  children?: React.ReactNode;
  defaultPreferredAirports?: IAirSuggestion[];
}

export type IAirAncillariesResponseBaggage = AirAncillariesResponseBaggage;

export interface IItinerarySelectedLeg {
  legIndex: number;
  legNumber: number;
  isLastLeg: boolean;
}

export interface IGetPreferedLabelProps {
  itinIndex: number;
  rateOptionIndex: number;
  legNumber: number;
}

export interface IRateOptionPreferredLabelProps {
  rate: RateOption;
  legNumber: number;
}

export interface ICabinSelectorProps {
  changeCabinType: (cabinType: number) => void;
  cabin: Cabin;
}

export interface Carrier {
  marketing: FlightNumber | undefined;
  operating: FlightNumber | undefined;
  equipment: DataFlightEquipment | undefined;
}

export interface ICabinKeyLabelPair {
  backendKey: ICabin;
  labelKey: string;
}

export type IAirSearchRequest = AirSearchRequest;
export type IAirSearchResponse = FlightsSearchResponse;
export type IAirlineUnusedCredits = FetchTravelerUnusedCreditsResponse;

interface ISearchParams {
  userOrgIds: UserOrgId[];
  filter: IFlightsFilterState[];
  sortOptions: SortOption[];
  corporateInfo?: AirSearchRequestCorporateInfo;
  tripType?: number;
  currLegNumber?: number;
  lastLegRateOptionId: string;
  searchId: string;
  selectedLoyaltyOptions?: IAirlineSelectedLoyalty[];
  pageNumber: number;
  pageSize: number;
}

export interface IWebSearchParams {
  userOrgIds?: UserOrgId[];
  filter: IFlightsFilterState[][];
  sortOptions: SortOption[][];
  corporateInfo?: AirSearchRequestCorporateInfo;
  currLegNumber?: number;
  lastLegRateOptionId: string;
  searchId: string;
  selectedLoyaltyOptions?: IAirlineSelectedLoyalty[];
  pageNumber: number;
  pageSize: number;
}

export interface IGetAirSearchRequestProps {
  searchId: string;
  lastLegRateOptionId: string;
  segments: IFlightSearchSegmentState<IAirSuggestion[]>[];

  pageSize: number;
  pageNumber: number;
  currLegNumber: number;

  filter: IFlightsFilterState[][];
  sortOptions: SortOption[][];

  corporateInfo?: AirSearchRequestCorporateInfo;

  passengers: IAirSearchUrlPaxInfo[];
  passengerUserOrgIds: IUserOrgId[];
  registrarUserOrgId: UserOrgId;
  registrarLegalEntityId: EntityId;
  legCloneInfo?: AirSearchRequestLegSearchParamsLegCloneInfo;
  isSuggestedFlightOptionsEnabled: boolean;
  tripId: string;
  tripIdInfo: TripIdInfo | GroupIdInfo | undefined;
}

export interface IMobileAirSearchParams extends ISearchParams {
  segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[];
  tripId?: string;
  passengers: IAirSearchFormPaxInfo[];
  bookingFlow: BookingFlow;
  nonProfileUserInfo: NonProfileUserInfo;
  /**
   * Clone Info will be decided by current leg index, hence an array
   */
  legCloneInfo?: AirSearchRequestLegSearchParamsLegCloneInfo[];
}

export type IAirSearchUrlParams = ISearchParams & {
  segments: IFlightSearchSegmentState<IAirSuggestion>[];
  tripId?: string;
  bookingFlow?: BookingFlow;
};

export type IWebAirSearchUrlParams = IWebSearchParams & {
  segments: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[];
  passengers?: IAirSearchUrlPaxInfo[];
  tripId?: TripIdUrlParam;
  isPreselectedTrip?: boolean;
  isPersonalTravel?: boolean;
  utm_source?: string;
  registrarId: string;
  /**
   * Clone Info will be decided by current leg index, hence an array
   */
  legCloneInfo?: AirSearchRequestLegSearchParamsLegCloneInfo[];
  /**
   * Flight Numbers are used to render no closest match found alert in search page in a rebook flow
   */
  flightNumbers?: FlightNumber[];
  /**
   * Used to determine whether the user is booking a group trip or regular
   */
  tripIdInfo?: TripIdInfo | GroupIdInfo;
  disableSearchArea?: boolean;
  showSignAmount?: boolean;
};

export type IAirCheckoutUrlParams = IAirSearchUrlParams & {
  seatIndex: number;
};

export type IAirSearchFormUrlParams = {
  segments?: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[];
  tripId?: string;
  filter?: IFlightsFilterState[];
};

export type INewLeg = IFlightSearchSegmentState<IAirSuggestion>;

export type ILeg = Leg1;

export type ILeg1 = ExistingLeg | INewLeg;

export enum LegStatus {
  None,
  Added,
  Updated,
  Removed,
}

export interface ILegState {
  originalLegIndex: number; // -1 in case if a new leg is added
  status: LegStatus;
}

export interface IExchangeParams {
  selectedLegs: Array<IFlightSearchSegmentState<IAirSuggestion> & { originalLegIndex?: number }>;
  legsState: ILegState[];
}

export interface IAirModifySearchUrlParams {
  pnrId: string;
  filter: IFlightsFilterState[][];
  sortOptions: SortOption[][];
  legs: ILeg1[];
  currLegNumber?: number;
  lastLegRateOptionId: string;
  asyncRouteHappy?: boolean;
  searchId: string;
  pageNumber: number;
  pageSize: number;
  exchangeableIndices: number[];
  passengers: IAirSearchUrlPaxInfo[];
}

// TODO: Merge this interface with IAirSearchParams after migrating mobile filter code
export type IWebAirSearchFormUrlParams = SharedSearchParams & {
  segments?: IFlightSearchSegmentState<IAirSuggestion | IAirSuggestion[]>[];
  filter?: IFlightsFilterState[][];
  isPersonalTrip?: boolean;
  selectedTab?: string;
};

export type AirSearchUrlParams = Omit<IWebAirSearchUrlParams, 'userOrgIds'>;

export interface IFlightSearchResultsFormParams extends Omit<AirSearchUrlParams, 'tripId'> {
  currLegNumber: number;
}

export interface IPaxSegmentSeatNumber {
  /* flightIndex across all legs */
  flightIndex: number;
  /* Example: 32F */
  seatNumber: string;
}

export enum LoyaltyValidityEnum {
  NOT_VALIDATED = 'NOT_VALIDATED',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export interface IPaxSelectedLoyalty {
  /**
   * Example:
   * [
   *   { leg: 0,flight: 0,loyalty: {issuedBy:'AA',id:'1234',appliedTo:[]}},
   *   { leg: 0,flight: 1,loyalty: {issuedBy:'AA',id:'1234',appliedTo:[]}},
   *   { leg: 1,flight: 0,loyalty:  {issuedBy:'UA',id:'5678',appliedTo:[]}},
   *   { leg: 1,flight: 1,loyalty: {issuedBy:'UA',id:'5678',appliedTo:[]}}
   * ]
   */
  leg: number;
  flight: number;
  loyalty: ILoyaltyInfo;
  validationStatus: LoyaltyValidityEnum;
}

export interface ISeatmapCurrencyInfo {
  currency: string;
  symbol: string;
}

export interface IAirSearchUrlPaxInfo {
  /** Type of passenger */
  paxType: PassengerType;
  /** Age of passenger. Only required if pax_type is CHILD */
  paxAge?: PassengerAge;

  userId?: string;

  readOnly?: boolean;

  /**
   * Example:
   * [
   *   { flightIndex: 0,seatNumber: '1F' },
   *   { flightIndex: 1,seatNumber: '1E' },
   * ]
   */
  seat?: IPaxSegmentSeatNumber[];
  /**
   * Example:
   * [
   *   { legIndex: 0, baggageIds: ["testBaggageId1"] },
   *   { legIndex: 1, baggageIds: ["testBaggageId4"] }
   * ]
   */
  baggage?: AirRevalidateItineraryRequestTravelerInfoBaggage[];

  /** Passenger's selected Passport Doc Id */
  passport?: IPassport;

  /** Passenger's selected National Doc Id */
  nationalDoc?: INationalDoc;

  /** Passenger's nationality */
  nationality?: string;

  unusedCreditRequests?: AirUnusedCreditRedemptionRequest[];

  ancillary?: AirRevalidateItineraryRequestTravelerInfoOtherAncillary[];
}

export interface IAirSearchFormPaxInfo extends IAirSearchUrlPaxInfo {
  /** Passenger's gender */
  gender: GenderEnum | null;

  /** Passenger's Date of birth */
  dob: string | null;

  /** Store raw phone number from IPhoneNumber interface */
  phone: string | null;

  /** Check if loyalty is auto-populated for the pax */
  isLoyaltyInitialized: boolean;

  /** Passenger's selected loyalty per airline */
  loyalty: IPaxSelectedLoyalty[];

  /** Traveler level pre book questions and answers */
  preBookAnswers: CustomFieldsEntityAnswerType | null;
  /**
   * If true, passengers will not be contacted by email or phone in the case
   * of an operational disruption
   */
  doNotShareEmailAndPhone: boolean;
}

export interface IAirSearchUrlPaxInfoWithUserOrgId extends IAirSearchFormPaxInfo {
  userOrgId: IUserOrgId;
}

export interface IAirSearchUrlPaxInfoWithBookingOwner extends IAirSearchFormPaxInfo {
  bookingOwner: ITraveler;
}

export type IAirSearchUrlPaxInfoWithUserOrgIdOrOwnerInfo =
  | IAirSearchUrlPaxInfoWithBookingOwner
  | IAirSearchUrlPaxInfoWithUserOrgId;

export interface ILegFlightIndexInfo {
  legNumber: number;
  flightNumber: number;
  legIndex: number;
  flightIndex: number;
}

export interface ISelectedPayments {
  paymentSourceId: string;
}

export interface IFlightCheckoutUrlParams {
  segments: IFlightSearchSegmentState<IAirSuggestion>[];
  lastLegRateOptionId: string;
  searchId: string;
  passengers: IAirSearchUrlPaxInfo[];
  tripId?: TripIdUrlParam;
  paymentCardId?: string;
  paymentMethod?: PaymentMethod;
  /**
   * @deprecated
   *
   * This field is now in the IAirSearchUrlPaxInfo as doNotShareEmailAndPhone
   */
  shareEmailAndPhone?: boolean;
  /**
   * @deprecated
   *
   * This field is now deprecated after FE_AIR_SPLIT_PAYMENT is enabled. remove while removing this flag
   */
  paymentSourceIds?: string[];
  paymentSourceTypes?: PaymentSourceType[];
  isHoldBooking?: boolean;
  registrarId: string;
  isPreselectedTrip?: boolean;
  numSelectedPoints?: number;
  visaRequirements?: boolean;
  isSelfRedeemable?: boolean;
  tripIdInfo: TripIdInfo | GroupIdInfo;
  currentSeatSelectionFlightIndex: number;
  promotionCode: PromotionCode;
}

export type IOnSplitInput =
  | { enable: true; firstCardAmount: MoneyUtil; secondCardAmount: MoneyUtil }
  | { enable: false };

export interface ISelectedMopInfo {
  mopId: string;
  componentId: string;
  amount: any; // MoneyUtil
}

interface IAmadeusFormInfo {
  isValid: boolean;
  saveCardForFutureUse: boolean;
  selectedPaymentTab: AmadeusPaymentTabEnum | null;
  mopsList: MopList[];
  qantasPointsMop: ISelectedMopInfo | null;
  travelFundMop: ISelectedMopInfo | null;
  firstCreditCardMop: ISelectedMopInfo | null;
  secondCreditCardMop: ISelectedMopInfo | null;
}

export type IPaymentSourceId = string;

export interface TripIdInfo {
  tripId: string;
  tripBookingFlow: TripBookingFlowEnum.REGULAR;
}

export interface GroupIdInfo {
  groupId: string;
  tripBookingFlow: TripBookingFlowEnum.GROUP;
}

export interface IFlightCheckoutFormParams extends IFlightCheckoutUrlParams {
  emailAddress: {
    isInitialized: boolean;
    value: string | null;
  };
  phoneNumber: {
    isInitialized: boolean;
    value: IPhoneNumber | null;
  };
  amadeusFormInfo: IAmadeusFormInfo;
  airlineFees: Record<IPaymentSourceId, MoneyUtil>;

  ssrOsiInfo: {
    isInitialized: boolean;
    data: SsrOsiFormValues | null;
  };
  /**
   * @deprecated in favor of a safer typed tripId with the addition of group booking
   */
  tripId?: string;
  /**
   * Used to assign trip IDs associated with the traveler in
   * a group booking flow
   */
  tripIdInfo: TripIdInfo | GroupIdInfo;
  passengers: IAirSearchFormPaxInfo[];
  bookingPaymentDetails: BookingPaymentDetails | null;
  checkoutCom3ds2: {
    open: boolean;
    redirectUrl: string | null;
  };
  qcEnabled: boolean;
  waiverCodeValues: WaiverCodeInputValues;
  pnrRemarks: PnrRemark[];
  razorPayAuth: { open: true; paymentAuthUrl: string } | { open: false };
}

export interface IFlightCheckoutFormPaxInfo {
  passengers: IFlightCheckoutFormParams['passengers'];
}

export interface IFlightConfirmHoldCheckoutUrlParams {
  segments: IFlightSearchSegmentState<IAirSuggestion>[];
  passengers: IAirSearchUrlPaxInfo[];
  paymentCardId?: string;
  paymentMethod?: PaymentMethod;
  paymentSourceIds?: string[];
  paymentSourceTypes?: PaymentSourceType[];
  registrarId: string;
  currentSeatSelectionFlightIndex: number;
}

export interface IFlightConfirmHoldCheckoutFormParams extends IFlightConfirmHoldCheckoutUrlParams {
  emailAddress: {
    isInitialized: boolean;
    value: string | null;
  };
  phoneNumber: {
    isInitialized: boolean;
    value: IPhoneNumber | null;
  };
  amadeusFormInfo: IAmadeusFormInfo;
  airlineFees: Record<IPaymentSourceId, MoneyUtil>;
  ssrOsiInfo: {
    isInitialized: boolean;
    data: SsrOsiFormValues | null;
  };
  passengers: IAirSearchFormPaxInfo[];
  checkoutCom3ds2: {
    open: boolean;
    redirectUrl: string | null;
  };
  bookingPaymentDetails: BookingPaymentDetails | null;
  qcEnabled: boolean;
  waiverCodeValues: WaiverCodeInputValues;
  tripId: string;
  pnrId: string;
  pnrRemarks: PnrRemark[];
}

export interface ITravelerTicketInfo {
  ticketAmount: ITicketFare;
  ticketAirports: ITicketAirports[];
  totalAmount: MoneyUtil | null;
}

export interface ITicketFare {
  base: MoneyUtil | null;
  tax: MoneyUtil | null;
}

export interface IFlightsSearchParams {
  segments: AirSearchRequestLeg[];
  sortType?: SortOptionSortBy;
  filter: Filter[];
}

export interface ITimeRangeFilter {
  legIndex: number;
  arrival: { min: string; max: string };
  departure: { min: string; max: string };
}

export interface IFlightsFilterState {
  cabin?: FilterCabinFilter;
  maxNumStops?: number;
  timeRange?: ITimeRangeFilter[];
  alliance?: FilterAllianceFilter;
  policyFilter?: FilterPolicyFilter;
  changeable?: FilterChangeableFilter;
  fareCategoryFilter?: FilterFareCategoryFilter;
  multiTicketFilter?: FilterMultiTicketFilter;
  flightNumber?: FilterFlightNumberFilter;
  unusedCreditFilter?: FilterUnusedCreditFilter;
}

export type FlightsFilterStateKeys = keyof IFlightsFilterState;

export type MultiTicketFilterKeys = keyof FilterMultiTicketFilter;
export type UnusedCreditFilterKeys = keyof FilterUnusedCreditFilter;
export type PolicyFilterKeys = keyof FilterPolicyFilter;

export interface IPassengerSeatInitials {
  selectedSeatNumber: string;
  existingSeatNumber: string;
  passengerInitials: string;
}

export interface ISeat {
  seatNumber: string;
  isAvailable: boolean;
  isPreferred: boolean;
  isWheelchairAccessible: boolean;
  isBassinet: boolean;
  isExtraLegroom: boolean;
  columnInfo: ColumnSectionColumn;
  type: SeatSectionSeatType;
  location: SeatSectionSeatLocation[];
  facilities: SeatSectionSeatFacility[];
  limitations: SeatSectionSeatLimitation[];
  price?: SeatSectionPrice;
  commercialName?: string;
  isLoyaltyLevelRequired: boolean;
}

export interface IRow {
  rowNumber: number;
  seats: ISeat[];
  rowTypes: RowSectionRowType[];
  facilitySections: FacilitySection[];
}

export enum IRowInfoType {
  ROW = 'ROW',
  EXIT = 'EXIT',
  FACILITY = 'FACILITY',
}

export interface IRowInfo {
  rowNumber: number;
  seatGroups: ISeat[][];
  rowType: IRowInfoType;
  facilitySections?: FacilitySectionFacility[][];
}

export interface ICabinSection {
  cabin: Cabin;
  bookingCode: string;
  locations: Location[];
  facilitySections: FacilitySection[];
  columnSections: ColumnSection[];
  rows: IRow[];
}

export interface ICabinSectionMultiDeck {
  upperDeckCabinSections: ICabinSectionRowsWithSeatGroup[];
  lowerDeckCabinSections: ICabinSectionRowsWithSeatGroup[];
  isMultiLevel: boolean;
  mainDeckLocation: Location.UPPER_DECK | Location.LOWER_DECK;
}

export interface ICabinSectionRowsWithSeatGroup extends Omit<ICabinSection, 'rows'> {
  rows: IRowInfo[];
}

export interface IFlightSeatMap {
  wingRows?: Int32Range;
  cabinSections: ICabinSection[];
}

export type IMarketingAirline = string;
export type IOperatingAirline = string;
export type ILoyaltyIssuedBy = string;
export type ILoyaltyId = string;

export interface ILoyaltyInfoAndOperatingAirline {
  loyaltyInfo: ILoyaltyInfo | undefined;
  operating: IOperatingAirline;
}

export type IMetadataAirline = MetadataAirline;

export const isAirSuggestion = (suggestion?: IAutocompleteSuggestion | null): suggestion is IAirSuggestion =>
  (suggestion as IAirSuggestion)?.code !== undefined;

export interface ICheckoutSeat {
  // TODO: Remove index.
  index: number;
  seat: string;
  price?: SeatSectionPrice;
}

export interface IAirlineCodes {
  marketing: string;
  operating: string;
}

export interface IItineraryAirline {
  legIndex: number;
  flightIndex: number;
  airline: IAirlineCodes;
}

export interface IAirlineLoyaltyOptions extends IItineraryAirline {
  loyaltyInfos: LoyaltyInfo[];
}

export interface IAirlineSelectedLoyalty extends IItineraryAirline {
  loyaltyInfo: LoyaltyInfo | undefined;
}

export enum AirCheckoutStep {
  ANCILLARY = 'ANCILLARY',
  CHECKOUT = 'CHECKOUT',
  SEAT_MAP = 'SEAT_MAP',
}

export enum AaStatus {
  NONE = 0,
  SUCCESS = 1,
  ALREADY_EXIST = 2,
  LOYALTY_ERROR = 3,
  UNKNOWN_ERROR = 4,
  CALL_ERROR = 5,
}

export type AaErrors =
  | 'AA_LOYALTY_CUSTOMER_ALREADY_EXISTS'
  | 'AA_LOYALTY_UNKNOWN_ERROR'
  | 'AMERICAN_AIRLINES_REST_CALL_FAILURE'
  | 'UNKNOWN_AMERICAN_AIRLINE_ERROR';

export type AirCheckoutState = {
  currentStep: AirCheckoutStep;
  baggage: { [key: number]: IBaggageCheckoutState };
  seats: { [key: string]: ICheckoutSeat };
  draftSeats: { [key: string]: ICheckoutSeat };
  paymentFees: MoneyUtil;
  seatIndex: number;
  seatMapCurrency: string;
  selectedLoyalty: IAirlineSelectedLoyalty[];
};

export type AirCheckoutSelectedValues = CheckoutSelectedValues & { passport: IPassport | null } & {
  nationalDoc: INationalDoc | null;
};

export type CheckoutSelectedValues = {
  contactNumber: IPhoneNumber | null;
  paymentCard: PaymentInfo | null;
  tripId: string;
};

export interface GetFareInfoForRateOptionId {
  itinIndex: number;
  legNumber: number;
  rateOptionId: string;
}

export interface IStopsInfo {
  numStops: number;
  layoverDurationMinutes: number[];
  layoverAirportCodes: string[];
  hasAirportChange: boolean;
}

export interface IItineraryFareInfo {
  data: RateOption;
  itinIndex: number;
  rateOptionId: string;
  shouldShowLegBasedPricing: boolean;
  totalPaxNum: number;

  totalFare: MoneyUtil;
  totalLegFare: MoneyUtil;

  farePerPax: MoneyUtil;
  legFarePerPax: MoneyUtil;

  itinBaseFarePerPax: MoneyUtil;
  itinTaxFarePerPax: MoneyUtil;
  legBaseFarePerPax: MoneyUtil;
  legTaxFarePerPax: MoneyUtil;

  totalItinBaseFare: MoneyUtil;
  totalItinTaxFare: MoneyUtil;
  totalLegBaseFare: MoneyUtil;
  totalLegTaxFare: MoneyUtil;

  totalCheapestPublishedFare: MoneyUtil;
  totalLegCheapestPublishedFare: MoneyUtil;

  policyInfo?: PolicyInfo;
  policyInfos: PolicyInfo[];
  fareType: FareTypeEnum;
  /**
   * Currently only first passenger will be used, till we have support for multi
   * passenger bookings
   */
  passenger: IItineraryFareInfoFarePassenger[];
  allowedFop: PaymentMethod[];
  preferences: Preference[];
  isQantasRewardsFare: boolean;
}

export interface IItineraryFareInfoEnhanced extends IItineraryFareInfo {
  passenger: IItineraryFareInfoFarePassengerEnhanced[];
}

export type UtaKeys = Omit<keyof Uta, 'baggage'> | keyof UtaBaggage | 'additionalCheckedBag';

interface IUtaInfoBase {
  key: UtaKeys;
}

interface IUtaInfoUnavailable extends IUtaInfoBase {
  isAvailable: false;
}

interface IUtaInfoPolicy extends IUtaInfoBase {
  key: Omit<keyof Uta, 'baggage'>;
  isAvailable: true;
  info: UtaPolicy | UtaPoints;
}

export type IUtaInfo = IUtaInfoUnavailable | IUtaInfoPolicy;

export interface IGetFareUtasReturn {
  visibleUtaInfos: IUtaInfo[];
  pointsUtaInfos: IUtaInfo[];
  specialAmenitiesUtaInfos: IUtaInfo[];
  nonVisibleUtaInfos: IUtaInfo[];
}

export interface IItineraryFareInfoFarePassenger {
  data: {
    rateOption: RateOption;
    legIndex: number;
    legNumber: number;
  };
  /**
   * Used to determine corporate fare
   */
  fareType: FareType;
  /**
   * Used to determine fare type (base, standard, etc.) and subtype (economy,
   * premium economy, etc.)
   */
  fareCategory: FareCategory;
  brandCode: string;
  brandName: string;
  vendorProgramType: VendorProgramType;
  flights: IItineraryFareInfoFareFlight[];
}

export interface IItineraryFareInfoFarePassengerEnhanced extends IItineraryFareInfoFarePassenger {
  flights: IItineraryFareInfoFareFlightEnhanced[];
  utas: Uta[];
}

export interface IItineraryFareInfoFareFlight {
  data: PaxInfoFareComponentLegInfoFlightInfo;
  bookingCode: string;
  cabin: Cabin;
  flight: IFlightInfo;
}

export interface IItineraryFareInfoFareFlightEnhanced extends IItineraryFareInfoFareFlight {
  amenities: IFlightAmenity[];
  upas: Upa[];
}

export interface ILegPolicyInfo {
  policyInfo: IPolicyInfo | undefined;
  flight: ITicketAirports;
  isOutOfPolicy: boolean;
}

export interface IFlightPolicyInfo {
  legPolicyInfo: ILegPolicyInfo[];
  ticketType: IRateOptionTicketType;
  showItineraryRestrictions: boolean;
  refundChangePolicy: IRefundChangePolicy;
}

export enum IFlightMediaInfoType {
  PHOTO,
  VIDEO,
}

export interface IFlightMediaInfo {
  mediaType: IFlightMediaInfoType;
  mediaUrl: string;
  thumbnailUrl: string;
  headline: string;
  description: string;
}

export interface IFlightUtaPolicyInfo {
  key: Omit<UtaProps, 'baggage'> | UtaBaggageProps;
  value: UtaPolicy;
}

export interface IFlightPolicyUtas {
  allFlightUtaPolicyInfos: IFlightUtaPolicyInfo[][];
  visibleFlightUtaPolicyInfos: IFlightUtaPolicyInfo[][];
}

export type CleaningAndSafetyPractices = Pick<
  Amenity,
  'cleaning' | 'mask' | 'temperatureCheck' | 'vaccine' | 'covidTesting' | 'blockedAdjacentSeats'
>;

export enum RevalidatingBookingVgsEnum {
  PRE_REVALIDATE_REQUEST = 'PRE_REVALIDATE_REQUEST',
  REVALIDATING = 'REVALIDATING',
  REVALIDATED = 'REVALIDATED',
  FAILED = 'FAILED',
}

export enum CreatingPnrBookingVgsEnum {
  CREATING_PNR = 'CREATING_PNR',
  PNR_PENDING = 'PNR_PENDING',
  PNR_CREATED = 'PNR_CREATED',
  FAILED = 'FAILED',
}

export enum BookingVgsMessageEnum {
  CVV_ERROR = 'CVV_ERROR',
  REVALIDATING_STATE = 'REVALIDATING_STATE',
  CREATING_PNR_STATE = 'CREATING_PNR_STATE',
  CC_VERIFICATION_URL = 'CC_VERIFICATION_URL',
}

export enum VgsBookingStateEnum {
  DEFAULT = 'DEFAULT',
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export type BookingWebViewMessage =
  | { type: BookingVgsMessageEnum.CVV_ERROR; message: any }
  | {
      type: BookingVgsMessageEnum.REVALIDATING_STATE;
      message: keyof typeof RevalidatingBookingVgsEnum;
      errorString?: string;
    }
  | {
      type: BookingVgsMessageEnum.CREATING_PNR_STATE;
      message: keyof typeof CreatingPnrBookingVgsEnum;
      errorCode?: string;
      payload?: object;
    }
  | { type: BookingVgsMessageEnum.CC_VERIFICATION_URL; message: string };

export interface IGetAirAncillariesRequestInput {
  searchId: string;
  rateOptionId: string;
  passengers: IAirSearchUrlPaxInfoWithUserOrgIdOrOwnerInfo[];
}

export { TicketType } from './api/v1/obt/pnr/pnr';

export interface IPassengerType {
  backendKey: PassengerType;
  label: string;
  subText: string;
  lowerLimit: number;
  upperLimit: number;
}

export type IPaxInfo = Partial<Record<PassengerType, AirSearchRequestPaxInfo[]>>;

export interface IFlightsMultiPaxValidationState {
  isValid: boolean;
  error: string;
}

export interface ISelectedBaggagePerPassenger {
  price: MoneyUtil;
  label: string;
  count?: number;
  baggageId: string;
}

export interface IPerPassengerBaggageOptions {
  paxType: PassengerType;
  baggageOptions: IBaggageOption[];
  selectedBaggage: ISelectedBaggagePerPassenger | null;
}

export interface ILegBaggageOptions {
  legIndex: number;
  /** Whether requires sending luggage choices per passenger or for the whole booking. */
  perPassenger: boolean;
  /**
   * Baggage information for each passenger type. If per_passenger is false, baggage_infos will
   * contain only one element and will apply per booking.
   */
  baggageInfos: IPerPassengerBaggageOptions[];
}

export interface IBaggageOptions extends ILegBaggageOptions {
  /** Leg details,required when group level baggage selection is available */
  metaData: {
    departureAirport: string;
    arrivalAirport: string;
    arrivalAirportCode: string;
    departureAirportCode: string;
  };
}

export interface ILegBaseFare {
  baseFare: MoneyUtil | null;
  flight: ILegAirports[];
}

export interface IFareComponent {
  baseFare: MoneyUtil;
  feesAndTaxes: MoneyUtil;
  seat: MoneyUtil;
  baggage: MoneyUtil;
  eligibleRefund: MoneyUtil;
  flightCreditPricePerPax: MoneyUtil;
  uaPassPlusBaseFare: MoneyUtil;
  uaPassPlusFeesAndTax: MoneyUtil;
  legBaseFare?: ILegBaseFare[];
  penaltyFees: MoneyUtil;
  appliedCreditAirlineCode: string;
  zeroMoney: MoneyUtil;
  otherAncillary: {
    totalPrice: MoneyUtil;
    ancillaries: string[];
  };
}

export interface IPaxFareBreakup {
  paxType: PassengerType;
  travelerName?: string;
  fareComponents: IFareComponent;
  paxTotal: MoneyUtil;
}

export interface IItineraryFareBreakup {
  paxesFareBreakup: IPaxFareBreakup[];
  totalAmount: MoneyUtil;
}

export enum ISeatmapUnavailableReasonEnum {
  Unknown = 'Unknown',
  NoSeatMap = 'NoSeatMap',
  OpenSeating = 'OpenSeating',
  SeatNotGuaranted = 'SeatNotGuaranted',
  SeatBookingNotAllowed = 'SeatBookingNotAllowed',
  PreCheckoutSelectionNotAllowed = 'PreCheckoutSelectionNotAllowed',
  PostCheckoutSelectionNotAllowed = 'PostCheckoutSelectionNotAllowed',
  SeatBookingNotAllowedDueToBrand = 'SeatBookingNotAllowedDueToBrand',
  SeatBookingNotAllowedDueToCodeShare = 'SeatBookingNotAllowedDueToCodeShare',
}

export type ISeatmapAvailability =
  | {
      available: true;
    }
  | {
      available: false;
      unavailableReason: ISeatmapUnavailableReasonEnum;
    };

export interface PassportRequirements {
  isPassportRequired: boolean;
  perPassenger: boolean;
}

export interface NationalityRequirements {
  isNationalityRequired: boolean;
  perPassenger: boolean;
}

export enum SeatMapLegendType {
  AVAILABLE = 0,
  PAID = 1,
  UNAVAILABLE = 2,
  SELECTED = 3,
  CURRENT = 4,
  PREFERRED = 5,
  WHEELCHAIR_ACCESSIBLE = 6,
  PREMIUM = 7,
  BASSINET = 8,
  LAVATORY = 9,
  BAR = 10,
  CLOSET = 11,
  GALLEY = 12,
}

export enum ISeatSelectionWorkflowEnum {
  PreBooking = 'PreBooking',
  PostBooking = 'PostBooking',
}

export interface ISeatMapLegendConfig {
  type: SeatMapLegendType;
  icon: React.ComponentType<any>;
  title: string;
  content: JSX.Element | null;
}

export interface ITicketAirports {
  arrivalAirport: string;
  departureAirport: string;
}

export interface IAirMultipaxVariables {
  employeePlusLeisureAllowed?: boolean;
}

export interface IAirMultipaxSimplifiedVariables {
  enable_multipax_employee: boolean;
  enable_multipax_leisure: boolean;
  enable_multipax_guest: boolean;
}

export type IAirFareAmount = FareAmount;

export interface IRefundChangePolicy {
  isNonChangeableOrRefundable: boolean;
  refundable: boolean;
  changeable: boolean;
}

export enum RestrictionTypes {
  OUT_OF_POLICY = 'OUT_OF_POLICY',
  IS_MULTI_TICKET = 'IS_MULTI_TICKET',
  NON_REFUNDABLE = 'NON_REFUNDABLE',
  NON_CHANGEABLE = 'NON_CHANGEABLE',
  NOT_ALLOWED = 'NOT_ALLOWED',
}

export interface IPerTravellerLoyaltyInput {
  travelerSelections: IAirSearchFormPaxInfo[];
  airlines: IItineraryAirline[];
}

export interface SpecialAndOtherUtasForAllFares {
  allFlightFaresSpecialUtaPolicies: IFlightUtaPolicyInfo[][];
  allFlightFaresOtherUtaPolicies: IFlightUtaPolicyInfo[][];
}

export type IAirRevalidateItineraryRequestOtherServiceInformation =
  AirRevalidateItineraryRequestOtherServiceInformation;
export type IAirRevalidateItineraryRequestTravelerInfoSpecialServiceRequest =
  AirRevalidateItineraryRequestTravelerInfoSpecialServiceRequest;

export type OptionWithCode = {
  value: string;
  code: string;
  label: string;
};

export enum InfantType {
  ON_SEAT = 'ON_SEAT',
  ON_LAP = 'ON_LAP',
}

export interface AdditionalTravelers {
  paxType: string;
  /**
   * Only applicable to children
   */
  paxAge?: PassengerAge;
  /**
   * Only applicable to infants
   */
  infantType?: InfantType;
}

export type SSROption = OptionWithCode & {
  subOptions?: OptionWithCode[];
  selectedSubOption?: SSROption | null;
  hasEditableTextField?: boolean;
  isEditableTextFieldRequired?: boolean;
};

export type SsrOsiFormState = {
  meal: SSROption | null;
  customMeal?: string;
  selectedSSRs: (SSROption | null)[];
  customRequestsDict: Record<string, string>;
  otherSpecialInfos: string[];
};

export type WrapWithId<T> = {
  value: T;
  id: string;
};

export type { IFlightSearchSegmentState };

export interface PointSlidersProps {
  numSelectedPoints?: number;
  totalPoints: number;
  zeroMoney: MoneyUtil;
}

export interface IGetSliderInfoReturnType {
  sliderSelection: number;
  sliderRanks: number;
}

export interface ITravelFundSource {
  metadata: QantasTravelFundMetadataWrapper;
  paymentSource: BookingPaymentSource;
}

export interface IFopRuleAndPaymentRule {
  paymentRule: PaymentRule | undefined;
  fopRule: AllowedFopRule;
}

export interface IGetPointSliderData {
  remainingPointsBalance: number;
  pointsUsed: number;
  cashToBePaid: MoneyUtil;
}

export interface IGetPointSelectorPaymentDetailsReturn {
  paymentSourceIds: string[];
  paymentSourceTypes: PaymentSourceType[];
  numSelectedPoints?: number;
}

export interface IGetSelectedPaymentDetailsProps {
  numSelectedPoints?: number;
  paymentSourceIds?: string[];
}

export enum SeatUpgradeStatus {
  NONE = 0,
  UPGRADE_REQUESTED = 1,
}

export enum SherpaWidgetTypeEnum {
  BOOKING_CONFIRMATION = 'bookingConfirmation',
  MANAGE_MY_BOOKING = 'mmb',
  SEARCH = 'search',
}

export enum UtaInfoTextKey {
  INFO_CANCELLATION = 'INFO_CANCELLATION',
  INFO_CHANGE = 'INFO_CHANGE',
  INFO_STATUS_CREDIT = 'INFO_STATUS_CREDIT',
  INFO_BAGGAGE = 'INFO_BAGGAGE',
  INFO_UNKNOWN = 'INFO_UNKNOWN',
  INFO_SEAT_SELECTION = 'INFO_SEAT_SELECTION',
}

export interface IUseAirSeatmapCheckoutPageProps {
  travelersResponse: ITraveler[];
  searchId: string;
  rateOptionId: string;
  passengers: IAirSearchFormPaxInfo[];
}

export interface IUseAirSeatmapTripPageProps {
  passengers: IAirSearchFormPaxInfo[];
  pnrId: string;
  travelersResponse: ITraveler[];
}

export type IUseAirSeatMapQueryProps = IUseAirSeatmapTripPageProps | IUseAirSeatmapCheckoutPageProps;

export interface IUseAncillaryQueryCheckoutProps {
  travelersResponse: ITraveler[];
  searchId: string;
  rateOptionId: string;
  passengers: IAirSearchFormPaxInfo[];
}

export interface IFareShelfInfo {
  duration: string;
  fareAmount?: FareAmount;
}
export interface FareDetails {
  totalAmount: MoneyUtil;
  qantasPoints: number;
  qantasPointsLabel: string;
  qantasPointsLabelWithTotalAmount: string;
  isQantasRewardsFare: boolean;
  totalAmountString: string;
  totalAmountValueString: string;
  baseAmount: string;
  taxAmount: string;
}

export interface IUseAirSeatmapAncillaryQueryWithRegistrarProps {
  travelersResponse: (ITraveler | undefined)[];
  registrarUserResponse?: ITraveler;
  searchId: string;
  rateOptionId: string;
  passengers: IAirSearchFormPaxInfo[];
}

export interface IGetAncillarySeatRequestParamsReturn {
  requestParams: IGetAirAncillariesRequestInput | undefined;
  isAnyPassengerInvalid: boolean;
}

export enum TripBookingFlowEnum {
  GROUP = 'GROUP',
  REGULAR = 'REGULAR',
}

export interface IPaxTripInfo {
  tripId: string;
  userOrgId: IUserOrgId;
}

export interface IFareInfo {
  totalAmount: MoneyUtil;
  qantasPoints: number;
  shouldShowAmount: boolean;
}

export interface IShouldShowFareChangeAlertProps {
  newRevalidateFare: MoneyUtil;
  oldRevalidateFare: MoneyUtil;
}

export type AirBookingPaymentSetupId = { ancillaryResponseId: string };
export type AirExchangePaymentSetupId = { ancillaryResponseId: string; pnrId: string };
export type AirPostBookingPaymentSetupId = { pnrId: string };

export type RequiredPaxUrlInfo = Pick<IAirSearchUrlPaxInfo, 'paxType' | 'readOnly' | 'paxAge' | 'userId'>;
export interface Fare {
  searchId: string;
  rateOptionId: string;
  segments: IFlightSearchSegmentState<IAirSuggestion>[];
  passengers: RequiredPaxUrlInfo[];
  tripBookingFlow: TripBookingFlowEnum;
}

export interface FareList {
  travelerUserId: string;
  fares: Fare[];
  uuid: string;
}

export type ShareableList = {
  [loggedInUserId: string]: FareList[];
};
