import uniqWith from 'lodash/uniqWith';

interface IBrandObject {
  brandCode: string;
  brandName: string;
}

const hotelBrandsList: IBrandObject[] = [
  {
    brandName: 'Wyndham Hotel Group Brands',
    brandCode: 'WR',
  },
  {
    brandName: 'TravelCLICK',
    brandCode: 'TC',
  },
  {
    brandName: 'City Lodge Hotels',
    brandCode: 'CG',
  },
  {
    brandName: 'Travelodge UK',
    brandCode: 'TG',
  },
  {
    brandName: 'Hilton Hotel Brands',
    brandCode: 'EH',
  },
  {
    brandName: 'Zucchetti Hospitality SRL',
    brandCode: 'OK',
  },
  {
    brandName: 'HRS',
    brandCode: 'HQ',
  },
  {
    brandName: 'Millennium/Copthorne',
    brandCode: 'MU',
  },
  {
    brandName: 'ReservHotel',
    brandCode: 'TA',
  },
  {
    brandName: 'Magnuson Hotels',
    brandCode: 'MS',
  },
  {
    brandName: 'Pegasus Solutions Inc.',
    brandCode: 'AX',
  },
  {
    brandName: 'Marriott Hotel Brands',
    brandCode: 'EM',
  },
  {
    brandName: 'Fastbooking',
    brandCode: 'FG',
  },
  {
    brandName: 'Accor Hotels',
    brandCode: 'RT',
  },
  {
    brandName: 'Choice Hotel Brands',
    brandCode: 'EC',
  },
  {
    brandName: 'GHA Brands',
    brandCode: 'GL',
  },
  {
    brandName: 'Louvre International Brands',
    brandCode: 'LI',
  },
  {
    brandName: 'Best Western Hotels \u0026 Resorts',
    brandCode: 'EW',
  },
  {
    brandName: 'Southern Sun Hotels (Tsogo Sun)',
    brandCode: 'SU',
  },
  {
    brandName: 'Carlson Hospitality Worldwide',
    brandCode: 'CW',
  },
  {
    brandName: 'Omnibees Hotel Distribution',
    brandCode: 'HB',
  },
  {
    brandName: 'Global Hyatt Corp.',
    brandCode: 'HY',
  },
  {
    brandName: 'XN Global Res',
    brandCode: 'XN',
  },
  {
    brandName: 'Great Hotels (GuestCentric)',
    brandCode: 'GW',
  },
  {
    brandName: 'BedsonLine',
    brandCode: '7H',
  },
  {
    brandName: 'Atel Hotels Network',
    brandCode: 'AC',
  },
  {
    brandName: 'Booking.Com',
    brandCode: '7B',
  },
  {
    brandName: 'NH Hotels Brands',
    brandCode: 'NH',
  },
  {
    brandName: 'Nikko Hotels/Hotel JAL',
    brandCode: 'NK',
  },
  {
    brandName: 'Scandic Hotels',
    brandCode: 'SH',
  },
  {
    brandName: 'Red Roof Brands',
    brandCode: 'RR',
  },
  {
    brandName: 'Hetras',
    brandCode: 'RM',
  },
  {
    brandName: 'Maritim',
    brandCode: 'MM',
  },
  {
    brandName: 'Worldhotels',
    brandCode: 'WW',
  },
  {
    brandName: 'Sonder USA Inc',
    brandCode: 'SS',
  },
  {
    brandName: 'G6 Hospitality LLC',
    brandCode: 'MX',
  },
  {
    brandName: 'Vantage Hotels',
    brandCode: 'VH',
  },
  {
    brandName: 'MGM Resorts International',
    brandCode: 'MV',
  },
  {
    brandName: 'Expedia Affiliate Network',
    brandCode: '7E',
  },
  {
    brandName: 'Preferred Brands',
    brandCode: 'PV',
  },
  {
    brandName: 'InterContinental Hotels Group',
    brandCode: '6C',
  },
];

export const hotelBrandsSelectOptions: { value: string; label: string }[] = uniqWith(
  hotelBrandsList.map((hotelBrand) => ({
    value: hotelBrand.brandCode,
    label: hotelBrand.brandName,
  })),
  (curr, next) => curr.label === next.label || curr.value === next.value,
);

export default hotelBrandsList;

export const availableLogoHotelBrands = [
  'AA',
  'CQ',
  'EZ',
  'HO',
  'LG',
  'NN',
  'PZ',
  'SS',
  'VY',
  'AC',
  'CT',
  'FA',
  'HR',
  'LL',
  'NO',
  'QH',
  'SU',
  'WA',
  'AE',
  'CU',
  'FB',
  'HS',
  'LM',
  'NU',
  'QI',
  'SV',
  'WB',
  'AH',
  'CV',
  'FC',
  'HT',
  'LO',
  'NW',
  'QQ',
  'SX',
  'WE',
  'AI',
  'CX',
  'FE',
  'HU',
  'LP',
  'NY',
  'QR',
  'SY',
  'WG',
  'AK',
  'CY',
  'FG',
  'HW',
  'LQ',
  'NZ',
  'QT',
  'SZ',
  'WH',
  'AL',
  'CZ',
  'FH',
  'HX',
  'LR',
  'OA',
  'QU',
  'TD',
  'WI',
  'AM',
  'DA',
  'FI',
  'HY',
  'LU',
  'OB',
  'RA',
  'TF',
  'WK',
  'AO',
  'DC',
  'FN',
  'IA',
  'LV',
  'OC',
  'RC',
  'TG',
  'WO',
  'AP',
  'DE',
  'FP',
  'IC',
  'LW',
  'OE',
  'RD',
  'TH',
  'WS',
  'AR',
  'DH',
  'FS',
  'IG',
  'LX',
  'OH',
  'RE',
  'TI',
  'WT',
  'AT',
  'DI',
  'FX',
  'IH',
  'LZ',
  'OK',
  'RF',
  'TJ',
  'WV',
  'AU',
  'DN',
  'GA',
  'IJ',
  'MA',
  'OL',
  'RG',
  'TL',
  'WX',
  'AZ',
  'DO',
  'GB',
  'IM',
  'MC',
  'OM',
  'RH',
  'TM',
  'WY',
  'Accor Hotels',
  'DP',
  'GD',
  'IN',
  'MD',
  'ON',
  'RI',
  'TO',
  'XE',
  'BC',
  'DR',
  'GE',
  'IQ',
  'ME',
  'OO',
  'RK',
  'TP',
  'XH',
  'BE',
  'DS',
  'GF',
  'IR',
  'MG',
  'OR',
  'RL',
  'TQ',
  'XN',
  'BG',
  'DT',
  'GG',
  'IS',
  'MH',
  'OS',
  'RN',
  'TR',
  'XO',
  'BH',
  'DV',
  'GH',
  'IW',
  'MM',
  'OT',
  'RO',
  'TS',
  'XR',
  'BI',
  'DW',
  'GI',
  'JB',
  'MN',
  'OW',
  'RQ',
  'TW',
  'XV',
  'BK',
  'DX',
  'GK',
  'JD',
  'MO',
  'OX',
  'RU',
  'TX',
  'XW',
  'BN',
  'DZ',
  'GN',
  'JI',
  'MP',
  'OY',
  'RW',
  'UA',
  'YL',
  'BR',
  'EA',
  'GP',
  'JJ',
  'MR',
  'OZ',
  'RX',
  'UB',
  'YO',
  'BU',
  'EB',
  'GQ',
  'JR',
  'MS',
  'PA',
  'RZ',
  'UL',
  'YP',
  'BV',
  'ED',
  'GR',
  'JT',
  'MT',
  'PD',
  'SB',
  'UP',
  'YR',
  'BW',
  'EE',
  'GS',
  'JV',
  'MU',
  'PF',
  'SD',
  'UR',
  'YX',
  'BY',
  'EF',
  'GT',
  'KB',
  'MV',
  'PG',
  'SF',
  'UU',
  'YZ',
  'BZ',
  'EG',
  'GV',
  'KC',
  'MX',
  'PH',
  'SG',
  'VA',
  'ZA',
  'CB',
  'EI',
  'GW',
  'KI',
  'MY',
  'PK',
  'SH',
  'VC',
  'ZX',
  'CC',
  'EJ',
  'HE',
  'KL',
  'MZ',
  'PL',
  'SI',
  'VE',
  'CG',
  'EL',
  'HG',
  'KS',
  'Mask group',
  'PN',
  'SK',
  'VG',
  'CI',
  'EO',
  'HH',
  'KY',
  'NB',
  'PR',
  'SL',
  'VI',
  'CK',
  'EQ',
  'HI',
  'LC',
  'NC',
  'PS',
  'SN',
  'VL',
  'CM',
  'ES',
  'HJ',
  'LD',
  'NH',
  'PT',
  'SO',
  'VN',
  'CN',
  'ET',
  'HL',
  'LE',
  'NI',
  'PU',
  'SP',
  'VT',
  'CP',
  'EU',
  'HM',
  'LF',
  'NK',
  'PY',
  'SR',
  'VX',
];
export const availableLogoHotelParentChains = [
  'AC',
  'CG',
  'EW',
  'FH',
  'GL',
  'GW',
  'HY',
  'LI',
  'ME',
  'MM',
  'MS',
  'MU',
  'MV',
  'MX',
  'NH',
  'NK',
  'OM',
  'PV',
  'RR',
  'RT',
  'SH',
  'SS',
  'SU',
  'TG',
  'VH',
  'WW',
  'YP',
];
