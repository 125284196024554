import { FC, lazy } from 'react';
import { IRouteConfig } from 'src/app/types/routes';
import { IProfileProps } from '../../v1-components/profile/info';

const ProfileInfoTask = () => import('../../v1-components/profile/info');
const ProfileInfo = lazy<FC<IProfileProps>>(ProfileInfoTask);

const ProfileRedirectPage = lazy(() => import('./ProfileRedirectPage'));

export const profileRoutes: IRouteConfig[] = [
  {
    key: 'profile-info',
    path: '/profile/:type(personal|arranger|configuration|work|preferences|loyalty|payment|internal-debug|support-history|notifications)',
    component: ProfileInfo,
    search: '/:travelType(|flight|hotel|car|rail)?',
  },
  {
    key: 'profile-redirect',
    path: '/profile/:userId',
    component: ProfileRedirectPage,
  },
];
