import { useColorScheme } from '@spotnana/blocks/src/utils/hooks';
import { useBlocksTheme } from '@spotnana/blocks/src/utils/styling/useBlocksTheme';

import { getImpersonationToken, useAuth, useLoggedInUserId } from 'obt-common';
import { useFeatureFlag } from 'obt-common/feature-gating/hooks';

import Config from 'obt-common/utils/Config';
import { useEffect } from 'react';

declare global {
  interface Window {
    twilioChatSupport:
      | {
          user_id: string;
          user_name: string;
          user_email_id: string;
          user_language: string;
          traveller_name: string;
          traveller_email_id: string;
          traveller_tier: string;
          org_id: string;
          org_name: string;
          partner_tmc: string;
          partner_tmc_id: string;
        }
      | undefined;
  }
}

export function InitializeTwilioChat() {
  const { getAuthToken } = useAuth();

  // Retrieve logged-in user ID and organization ID
  const loggedInUserId = useLoggedInUserId();
  const { mode: theme } = useColorScheme();
  const { palette } = useBlocksTheme();
  const routeToHumanAgentEnabled = useFeatureFlag('FE_ROUTE_TO_HUMAN_AGENT');
  const endChatByUserEnabled = useFeatureFlag('FE_END_CHAT_BY_USER');
  useEffect(() => {
    async function setupTwilioChat() {
      const companyId = loggedInUserId?.tmcBasicInfo?.contractingTmc?.id?.id ?? '';
      const orgId = loggedInUserId.organizationId?.id || '';
      await import('@spotnana/twilio-chat-app');

      // Initialize Twilio web chat with the necessary parameters
      window.Twilio.initWebchat({
        enableEndChatByUser: endChatByUserEnabled,
        enableRouteToHumanAgent: routeToHumanAgentEnabled,
        shouldShowMinimizeBtn: true,
        shouldHideOtherTravelerReservation: false,
        getAuthToken,
        getImpersonationToken,
        orgId,
        companyId, // this value is needed for x-Tmc-Id
        serverUrl: Config.VITE_API_BASE_URL || 'http://localhost:3001',
        webUrl: Config.VITE_WEB_URL || 'http://localhost:3000',
        theme: { isLight: theme === 'light', primaryColor: palette.bg.brand },
      });
    }
    setupTwilioChat();
  }, [getAuthToken, loggedInUserId, theme, palette, routeToHumanAgentEnabled, endChatByUserEnabled]);

  return <></>;
}
