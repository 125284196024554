import { css } from '@emotion/react';
import { flex, margins, themed } from '@spotnana/blocks/src/utils';

export const button_container = css`
  ${flex.init}
  ${flex.justify.center}

  > div {
    width: 49%;
  }
`;

export const country_select = css`
  ${margins.y.m1point5}

  .BlocksSelect-Popover {
    max-height: 400px;
  }
`;

// Use zIndex.snackbar + 1 to make sure the drawer is on top of other modals and components
export const mobile_drawer = themed(
  ({ zIndex }) => css`
    z-index: ${zIndex.snackbar + 1};
  `,
);
