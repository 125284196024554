/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * type of air item
 * @export
 * @enum {string}
 */

export const AirItemType = {
    Flight: 'FLIGHT',
    Ancillary: 'ANCILLARY'
} as const;

export type AirItemType = typeof AirItemType[keyof typeof AirItemType];



