import { useBrexBudgetInfoQuery, useBrexBudgetInfoWithoutCardQuery, CUSTOM_FIELD_TYPES } from 'obt-common';
import { useEffect } from 'react';
import { useEmbedStore, useEmitEmbedErrors } from '../components/EmbedContext';

const useBrexBudgetInfo = () => {
  const { customFieldType, customFieldValue } = useEmbedStore().embedParams;

  const reportError = useEmitEmbedErrors({ source: 'useBrexBudgetInfo' });

  const queryData = useBrexBudgetInfoQuery(
    {
      brexJwtToken: customFieldValue,
    },
    {
      enabled: customFieldType === CUSTOM_FIELD_TYPES.BREX_TOKEN,
    },
  );

  useEffect(() => {
    if (queryData.isError) {
      reportError({ error: queryData.error, context: { token: customFieldValue } });
    }
  }, [customFieldValue, queryData, reportError]);

  return queryData;
};

const useBrexBudgetInfoWithoutCard = () => {
  const { customFieldType, customFieldValue } = useEmbedStore().embedParams;

  const reportError = useEmitEmbedErrors({ source: 'useBrexBudgetInfo' });

  const queryData = useBrexBudgetInfoWithoutCardQuery(
    {
      brexJwtToken: customFieldValue,
    },
    {
      enabled: customFieldType === CUSTOM_FIELD_TYPES.BREX_TOKEN,
      onError: (error) => reportError({ error, context: { token: customFieldValue } }),
    },
  );

  useEffect(() => {
    if (queryData.isError) {
      reportError({ error: queryData.error, context: { token: customFieldValue } });
    }
  }, [customFieldValue, queryData, reportError]);

  return queryData;
};

export default useBrexBudgetInfo;

export { useBrexBudgetInfoWithoutCard };
