import type { IItineraryFareInfo } from '../../../types';
import type { RateOption } from '../../../types/api/v1/obt/air/air_search_response';
import { getTotalCheapestPublishedFareForRateOption } from './getTotalCheapestPublishedFareForRateOption';
import { getTotalLegCheapestPublishedFareForRateOption } from './getTotalLegCheapestPublishedFareForRateOption';
import { getFareTypeForPaxInfo } from './getFareTypeForPaxInfo';
import {
  getLegFarePerPaxForPaxInfo,
  getLegBaseFarePerPaxForPaxInfo,
  getLegTaxFarePerPaxForPaxInfo,
} from './getLegFarePerPaxForPaxInfo';
import { getLegPreferencesForPaxInfo } from './getLegPreferencesForPaxInfo';
import {
  getPrimaryTravelerFareForPaxInfo,
  getBasePrimaryTravelerFareForPaxInfo,
  getTaxPrimaryTravelerFareForPaxInfo,
} from './getPrimaryTravelerFareForPaxInfo';
import {
  getTotalFareForFareAmount,
  getBaseFareForFareAmount,
  getTaxFareForFareAmount,
} from './getTotalFareForFareAmount';
import {
  getTotalLegFareForPaxInfo,
  getBaseLegFareForPaxInfo,
  getTaxLegFareForPaxInfo,
} from './getTotalLegFareForPaxInfo';
import { getTotalPaxNumForPaxInfo } from './getTotalPaxNumForPaxInfo';

type PartialItineraryFareInfo = Omit<
  IItineraryFareInfo,
  'shouldShowLegBasedPricing' | 'passenger' | 'isQantasRewardsFare'
>;

export function getPartialItineraryFareInfoFromRateOption(
  rateOption: RateOption,
  legNumber: number,
): PartialItineraryFareInfo {
  const { rateOptionId, totalFare, paxInfo, policyInfo, allowedFop, policyInfos } = rateOption;
  return {
    data: rateOption,
    // This is used only for suggested flights, which doesn't have any
    // itinIndex. This can be updated on handling Select button for suggested
    // flights.
    itinIndex: -1,
    rateOptionId,

    totalPaxNum: getTotalPaxNumForPaxInfo(paxInfo),

    farePerPax: getPrimaryTravelerFareForPaxInfo(totalFare, paxInfo),
    itinBaseFarePerPax: getBasePrimaryTravelerFareForPaxInfo(totalFare, paxInfo),
    itinTaxFarePerPax: getTaxPrimaryTravelerFareForPaxInfo(totalFare, paxInfo),

    legFarePerPax: getLegFarePerPaxForPaxInfo(paxInfo),
    legBaseFarePerPax: getLegBaseFarePerPaxForPaxInfo(paxInfo),
    legTaxFarePerPax: getLegTaxFarePerPaxForPaxInfo(paxInfo),

    totalFare: getTotalFareForFareAmount(totalFare),
    totalItinBaseFare: getBaseFareForFareAmount(totalFare),
    totalItinTaxFare: getTaxFareForFareAmount(totalFare),

    totalLegFare: getTotalLegFareForPaxInfo(paxInfo),
    totalLegBaseFare: getBaseLegFareForPaxInfo(paxInfo),
    totalLegTaxFare: getTaxLegFareForPaxInfo(paxInfo),

    totalCheapestPublishedFare: getTotalCheapestPublishedFareForRateOption(rateOption),
    totalLegCheapestPublishedFare: getTotalLegCheapestPublishedFareForRateOption(rateOption),

    fareType: getFareTypeForPaxInfo(paxInfo),
    preferences: getLegPreferencesForPaxInfo(paxInfo, legNumber),
    policyInfo,
    policyInfos,
    allowedFop,
  };
}
