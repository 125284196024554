import first from 'lodash/first';
import type { Alliance, MealType } from '../../types/api/v1/obt/air/air_common';
import {
  AllianceEnum,
  CabinEnum,
  FareCategoryCabinViewFareCategory,
  FareCategoryNGSFareCategory,
  MealTypeEnum,
  PassengerTypeEnum,
} from '../../types/api/v1/obt/air/air_common';
import type { FilterCabinFilter } from '../../types/api/v1/obt/air/air_search_request';
import {
  FilterCabinFilterTypeEnum,
  FilterChangeableFilter,
  SortOptionSortByEnum,
  SortOptionSortOrderEnum,
} from '../../types/api/v1/obt/air/air_search_request';

import type { Amenity } from '../../types/api/v1/obt/air/routehappy';
import type { IAirSuggestion } from '../../types/autocomplete';
import type { IKeyLabelPair, IKeyLabelPairWithMetaInfo } from '../../types/common';
import { FlightBookingType } from '../../types/common';
import type {
  ICabinKeyLabelPair,
  IFlightSearchAirlinesFilterState,
  IFlightsFilterState,
  IFlightsMultiPaxValidationState,
  IPassengerType,
  IPaxInfo,
  ISeat,
  ITimeRangeFilter,
  SSROption,
} from '../../types/flight';
import { AaStatus } from '../../types/flight';
import { ISeatAmenitiesPrefEnum } from '../../types/traveler';
import { FarePrefFareTypeEnum } from '../../types/api/v1/obt/common/air_preference';
import { ThirdPartySourceEnum } from '../../types/api/v1/obt/supplier/third_party_info';
import { ColumnSectionColumnPosition } from '../../types/api/v1/obt/air/air_seat_map_response';
import type { ICommonI18nMessage } from '../../translations/defineMessage';
import { defineCommonMessage, defineMessage } from '../../translations/defineMessage';
import { ExchangeNotSupportedReason } from '../../types/api/v2/obt/model/exchange-not-supported-reason';
import { AirCancellationNotSupportedReason } from '../../types/api/v2/obt/model/air-cancellation-not-supported-reason';
import { PassengerType } from '../../types/api/v2/obt/model/passenger-type';

export { PassengerTypeEnum } from '../../types/api/v1/obt/air/air_common';
export type { PassengerType as AirPassengerType } from '../../types/api/v1/obt/air/air_common';

export { specialUtasKeys } from './specialUtaKeys';

export const airTranslationKeys = {
  DELAYED_INVOICE_PAYMENT_MESSAGE: 'DELAYED_INVOICE_PAYMENT_MESSAGE',
  CLEANING_AND_SAFETY_PRACTICES: 'CLEANING_AND_SAFETY_PRACTICES',
  SHOW_LESS: 'SHOW_LESS',
  AMENITIES: 'AMENITIES',
  BASIC: 'BASIC',
  ECONOMY: 'ECONOMY',
  PREMIUM_ECONOMY: 'PREMIUM_ECONOMY',
  BUSINESS: 'BUSINESS',
  FIRST: 'FIRST',
  ECONOMY_PLUS: 'ECONOMY_PLUS',
  FLIGHT_HIGHEST_CABIN_CLASS_OVERNIGHT_NONE: 'FLIGHT_HIGHEST_CABIN_CLASS_OVERNIGHT_NONE',
  AIR_SEARCH_FORM_TRIP_TYPE_ONE_WAY: 'AIR_SEARCH_FORM_TRIP_TYPE_ONE_WAY',
  AIR_SEARCH_FORM_TRIP_TYPE_ROUND_WAY: 'AIR_SEARCH_FORM_TRIP_TYPE_ROUND_WAY',
  AIR_SEARCH_FORM_TRIP_TYPE_MULTI_CITY: 'AIR_SEARCH_FORM_TRIP_TYPE_MULTI_CITY',
  SEARCH_FLIGHTS: 'SEARCH_FLIGHTS',
  WHERE_FROM: 'WHERE_FROM',
  WHERE_TO: 'WHERE_TO',
  FROM: 'FROM',
  TO: 'TO',
  DEPARTURE: 'DEPARTURE',
  ARRIVAL: 'ARRIVAL',
  RETURN: 'RETURN',
  ADD_FLIGHT: 'ADD_FLIGHT',
  RELEVANCE: 'RELEVANCE',
  PRICE: 'PRICE',
  PRICE_BASE: 'PRICE_BASE',
  PRICE_STANDARD: 'PRICE_STANDARD',
  PRICE_ENHANCED: 'PRICE_ENHANCED',
  PRICE_PREMIUM: 'PRICE_PREMIUM',
  PRICE_LUXURY: 'PRICE_LUXURY',
  PRICE_ULTRA_LUXURY: 'PRICE_ULTRA_LUXURY',
  PRICE_BASIC: 'PRICE_BASIC',
  PRICE_ECONOMY: 'PRICE_ECONOMY',
  PRICE_BUSINESS: 'PRICE_BUSINESS',
  PRICE_ECONOMY_PLUS: 'PRICE_ECONOMY_PLUS',
  PRICE_FIRST: 'PRICE_FIRST',
  DEPARTURE_TIME: 'DEPARTURE_TIME',
  ARRIVAL_TIME: 'ARRIVAL_TIME',
  CO2_EMISSION: 'CO2_EMISSION',
  NON_STOP: 'NON_STOP',
  ONE_STOP_OR_LESS: 'ONE_STOP_OR_LESS',
  TWO_STOPS_OR_LESS: 'TWO_STOPS_OR_LESS',
  NO_PREFERENCE: 'NO_PREFERENCE',
  ALL_OPTIONS: 'ALL_OPTIONS',
  SUMMARY: 'SUMMARY',
  CABIN: 'CABIN',
  ALLIANCE: 'ALLIANCE',
  ALLIANCES: 'ALLIANCES',
  AIRLINES: 'AIRLINES',
  STOPS: 'STOPS',
  SHOW_ITINERARY_VIEW: 'SHOW_ITINERARY_VIEW',
  DETAILS: 'DETAILS',
  SELECT: 'SELECT',
  DURATION: 'DURATION',
  LAYOVER: 'LAYOVER',
  SORT_BY: 'SORT_BY',
  BOOK_FLIGHT: 'BOOK_FLIGHT',
  REVALIDATING_FLIGHT_PRICE: 'REVALIDATING_FLIGHT_PRICE',
  REVALIDATING_PRICE: 'REVALIDATING_PRICE',
  BOOKING: 'BOOKING',
  BOOKING_FLIGHT: 'BOOKING_FLIGHT',
  MEAL_PREFERENCE: 'MEAL_PREFERENCE',
  TRAVELER_INFO: 'TRAVELER_INFO',
  FLIGHT_SUMMARY: 'FLIGHT_SUMMARY',
  CONFIRMATION: 'CONFIRMATION',
  LOADING_MESSAGE: 'LOADING_MESSAGE',
  CONFIRM_AND_PAY: 'CONFIRM_AND_PAY',
  TRAVEL_SUMMARY: 'TRAVEL_SUMMARY',
  PAYMENT: 'PAYMENT',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  PASSENGER_IMPORTANT_INFO: 'PASSENGER_IMPORTANT_INFO',
  FILTER_INFO: 'FILTER_INFO',
  LLF_TICKET_PRICE: 'LLF_TICKET_PRICE',
  YOUR_TICKET_PRICE: 'YOUR_TICKET_PRICE',
  SELECT_TICKET_REASON: 'SELECT_TICKET_REASON',
  BASE: 'BASE',
  STANDARD: 'STANDARD',
  ENHANCED: 'ENHANCED',
  PREMIUM: 'PREMIUM',
  LUXURY: 'LUXURY',
  ULTRA_LUXURY: 'ULTRA_LUXURY',
  CORPORATE_RATE: 'CORPORATE_RATE',
  VIEW_ALL_PHOTOS: 'VIEW_ALL_PHOTOS',
  SHOW_MORE: 'SHOW_MORE',
  TOTAL_DURATION: 'TOTAL_DURATION',
  TRAVEL_TIME: 'TRAVEL_TIME',
  OVERNIGHT: 'OVERNIGHT',
  OVERNIGHT_LAYOVER: 'OVERNIGHT_LAYOVER',
  POLICY_RULES: 'POLICY_RULES',
  THIS_BOOKING: 'THIS_BOOKING',
  OUT_OF_POLICY: 'OUT_OF_POLICY',
  REFUNDABILITY: 'REFUNDABILITY',
  NO_CHANGEABLE_FILTER: 'NO_CHANGEABLE_FILTER',
  REFUNDABLE_WITH_PENALTY: 'REFUNDABLE_WITH_PENALTY',
  REFUNDABLE_WITHOUT_PENALTY: 'REFUNDABLE_WITHOUT_PENALTY',
  COVID_WARNING: 'COVID_WARNING',
  IMPORTANT_UPDATE: 'IMPORTANT_UPDATE',
  IMPORTANT_UPDATE_CONTENT: 'IMPORTANT_UPDATE_CONTENT',
  SEE_RESTRICTIONS: 'SEE_RESTRICTIONS',
  SELECT_SEAT: 'SELECT_SEAT',
  SELECTED_SEAT: 'SELECTED_SEAT',
  ADDITIONAL_INFORMATION: 'ADDITIONAL_INFORMATION',
  BAGGAGE: 'BAGGAGE',
  ADD_BAGGAGE: 'ADD_BAGGAGE',
  CARRY_ON_BAGGAGE: 'CARRY_ON_BAGGAGE',
  CHECKED_BAGGAGE: 'CHECKED_BAGGAGE',
  NO_DATA_AVAILABLE: 'NO_DATA_AVAILABLE',
  TIMES: 'TIMES',
  DEPARTS: 'DEPARTS',
  ARRIVES: 'ARRIVES',
  FARES: 'FARES',
  EARLIEST_DEPARTURE: 'EARLIEST_DEPARTURE',
  EARLIEST_ARRIVAL: 'EARLIEST_ARRIVAL',
  LATEST_DEPARTURE: 'LATEST_DEPARTURE',
  LATEST_ARRIVAL: 'LATEST_ARRIVAL',
  REFUND_TO_ORIGINAL_CARD: 'REFUND_TO_ORIGINAL_CARD',
  ELIGIBLE_REFUND: 'ELIGIBLE_REFUND',
  EMISSION_INFO_PART1: 'EMISSION_INFO_PART1',
  EMISSION_INFO_PART2: 'EMISSION_INFO_PART2',
  EXTRA_FEES_INFO: 'EXTRA_FEES_INFO',
  EMISSION_UNIT: 'EMISSION_UNIT',
  EMISSIONS: 'EMISSIONS',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  UNITED_PASSPLUS_RATE: 'UNITED_PASSPLUS_RATE',
  FLIGHT_LEVEL_UATP_INFO: 'FLIGHT_LEVEL_UATP_INFO',
  UNITED_PASSPLUS_FLIGHT: 'UNITED_PASSPLUS_FLIGHT',
  UNITED_PASSPLUS_FEES_AND_TAXES: 'UNITED_PASSPLUS_FEES_AND_TAXES',
  FEES_AND_TAXES: 'FEES_AND_TAXES',
  SEATS_SELECTION: 'SEATS_SELECTION',
  TYPE_OF_PAYMENT: 'TYPE_OF_PAYMENT',
  FLIGHT: 'FLIGHT',
  FLIGHT_CREDITS: 'FLIGHT_CREDITS',
  FLIGHTS: 'FLIGHTS',
  NEW_TICKET_COST: 'NEW_TICKET_COST',
  ORIGINAL_TICKET_COST: 'ORIGINAL_TICKET_COST',
  REFUND_CREDIT: 'REFUND_CREDIT',
  REFUND_CREDIT_INFO_MESSAGE: 'REFUND_CREDIT_INFO_MESSAGE',
  TOTAL: 'TOTAL',
  SEATS: 'SEATS',
  CURRENT_FLIGHT: 'CURRENT_FLIGHT',
  UPDATE_SEARCH: 'UPDATE_SEARCH',
  PERFORM_NEW_SEARCH: 'PERFORM_NEW_SEARCH',
  FARE_EXPIRATION_SUBTEXT: 'FARE_EXPIRATION_SUBTEXT',
  FARE_EXPIRED: 'FARE_EXPIRED',
  SELECTED_CARD: 'SELECTED_CARD',
  EXCHANGING_FLIGHT: 'EXCHANGING_FLIGHT',
  TEST_BOOKING: 'TEST_BOOKING',
  CHANGE_FEES: 'CHANGE_FEES',
  RATE: 'RATE',
  ADULTS: 'ADULTS',
  CHILDREN: 'CHILDREN',
  INFANTS_ON_SEAT: 'INFANTS_ON_SEAT',
  INFANTS_ON_LAP: 'INFANTS_ON_LAP',
  YOUNGER_THAN_TWO: 'YOUNGER_THAN_TWO',
  FLIGHTS_MULTI_PAX_CHILDREN_SELECT_AGE: 'FLIGHTS_MULTI_PAX_CHILDREN_SELECT_AGE',
  ADULT: 'ADULT',
  CHILD: 'CHILD',
  INFANT: 'INFANT',
  INFANTS: 'INFANTS',
  INFANT_ON_LAP: 'INFANT_ON_LAP',
  YOUTH: 'YOUTH',
  SENIOR: 'SENIOR',
  TOTAL_AMOUNT: 'TOTAL_AMOUNT',
  CABIN_CLASS_DETAIL_BASIC_TITLE: 'CABIN_CLASS_DETAIL_BASIC_TITLE',
  CABIN_CLASS_DETAIL_BASIC_CONTENT: 'CABIN_CLASS_DETAIL_BASIC_CONTENT',
  CABIN_CLASS_DETAIL_ECONOMY_TITLE: 'CABIN_CLASS_DETAIL_ECONOMY_TITLE',
  CABIN_CLASS_DETAIL_ECONOMY_CONTENT: 'CABIN_CLASS_DETAIL_ECONOMY_CONTENT',
  CABIN_CLASS_DETAIL_ECONOMY_PLUS_TITLE: 'CABIN_CLASS_DETAIL_ECONOMY_PLUS_TITLE',
  CABIN_CLASS_DETAIL_ECONOMY_PLUS_CONTENT: 'CABIN_CLASS_DETAIL_ECONOMY_PLUS_CONTENT',
  CABIN_CLASS_DETAIL_PREMIUM_TITLE: 'CABIN_CLASS_DETAIL_PREMIUM_TITLE',
  CABIN_CLASS_DETAIL_PREMIUM_CONTENT: 'CABIN_CLASS_DETAIL_PREMIUM_CONTENT',
  CABIN_CLASS_DETAIL_BUSINESS_TITLE: 'CABIN_CLASS_DETAIL_BUSINESS_TITLE',
  CABIN_CLASS_DETAIL_BUSINESS_CONTENT: 'CABIN_CLASS_DETAIL_BUSINESS_CONTENT',
  CABIN_CLASS_DETAIL_FIRST_TITLE: 'CABIN_CLASS_DETAIL_FIRST_TITLE',
  CABIN_CLASS_DETAIL_FIRST_CONTENT: 'CABIN_CLASS_DETAIL_FIRST_CONTENT',
  CABIN_CLASS_DETAILS_CTA: 'CABIN_CLASS_DETAILS_CTA',
  CABIN_CLASS_DETAILS_TITLE: 'CABIN_CLASS_DETAILS_TITLE',
  NON_REFUNDABLE: 'NON_REFUNDABLE',
  REFUNDABLE: 'REFUNDABLE',
  CHANGES_NOT_ALLOWED: 'CHANGES_NOT_ALLOWED',
  FREE_CHANGE: 'FREE_CHANGE',
  CHANGE_FOR_FEE: 'CHANGE_FOR_FEE',
  NON_CANCELLABLE_BOOKING: 'NON_CANCELLABLE_BOOKING',
  RELATIVE_INFORMATION: 'RELATIVE_INFORMATION',
  SELECT_RELATIVE: 'SELECT_RELATIVE',

  // Mixed Cabin
  MIXED_CABIN: 'MIXED_CABIN',
  MIXED_CABIN_INFO_HEADING: 'MIXED_CABIN_INFO_HEADING',
  MIXED_CABIN_INFO_MESSAGE: 'MIXED_CABIN_INFO_MESSAGE',
  PERSONAL_TRIP_OOP_LABEL: 'PERSONAL_TRIP_OOP_LABEL',
  NEXT: 'NEXT',
  SAVE: 'SAVE',
  SAVING: 'SAVING',
  EACH_FLIGHT_IS_SEPARATE_TICKET: 'EACH_FLIGHT_IS_SEPARATE_TICKET',
  EACH_TICKET_CHARGED_SEPARATELY: 'EACH_TICKET_CHARGED_SEPARATELY',
  CANCELLATION_FEE_OF_INDIVIDUAL_TICKET: 'CANCELLATION_FEE_OF_INDIVIDUAL_TICKET',
  THINGS_YOU_SHOULD_KNOW: 'THINGS_YOU_SHOULD_KNOW',
  SEPARATE_TICKETS: 'SEPARATE_TICKETS',
  OUT_OF_POLICY_ONE_MORE: 'OUT_OF_POLICY_ONE_MORE',
  BACK_TO_SEAT_SELECTION: 'BACK_TO_SEAT_SELECTION',
  CHECKOUT: 'CHECKOUT',
  CHANGED_NEW_SEAT: 'CHANGED_NEW_SEAT',
  FREE: 'FREE',
  CONFIRMING_SEAT: 'CONFIRMING_SEAT',
  NONE: 'NONE',
  AIRPORT_CHANGE: 'AIRPORT_CHANGE',
  OPEN_IN_GOOGLE_MAPS: 'OPEN_IN_GOOGLE_MAPS',
};

export const airlines = {
  SINGAPORE_AIRLINES: 'Singapore Airlines',
  AIR_NEW_ZEALAND: 'Air New Zealand',
  QANTAS: 'Qantas',
  QATAR_AIRWAYS: 'Qatar Airways',
  VIRGIN_AUSTRALIA: 'Virgin Australia',
  EMIRATES: 'Emirates',
  ALL_NIPPON_AIRWAYS: 'All Nippon Airways',
  EVA_AIR: 'EVA Air',
  CATHAY_PACIFIC: 'Cathay Pacific',
  JAPAN_AIRLINES: 'Japan Airlines',
  SINGAPORE_AIRLINES_B: 'Singapore Airlines B',
  AIR_NEW_ZEALAND_B: 'Air New Zealand B',
  QANTAS_B: 'Qantas B',
  QATAR_AIRWAYS_B: 'Qatar Airways B',
  VIRGIN_AUSTRALIA_B: 'Virgin Australia B',
  EMIRATES_B: 'Emirates B',
  ALL_NIPPON_AIRWAYS_B: 'All Nippon Airways B',
  EVA_AIR_B: 'EVA Air B',
  CATHAY_PACIFIC_B: 'Cathay Pacific B',
  JAPAN_AIRLINES_B: 'Japan Airlines B',
  SINGAPORE_AIRLINES_C: 'Singapore Airlines C',
  AIR_NEW_ZEALAND_C: 'Air New Zealand C',
  QANTAS_C: 'Qantas C',
  QATAR_AIRWAYS_C: 'Qatar Airways C',
  VIRGIN_AUSTRALIA_C: 'Virgin Australia C',
  EMIRATES_C: 'Emirates C',
  ALL_NIPPON_AIRWAYS_C: 'All Nippon Airways C',
  EVA_AIR_C: 'EVA Air C',
  CATHAY_PACIFIC_C: 'Cathay Pacific C',
  JAPAN_AIRLINES_C: 'Japan Airlines C',
};

export const FLIGHTS_MULTI_PAX_TOTAL_PAX_UPPER_LIMIT = 6;

export const passengerType: IPassengerType[] = [
  { backendKey: PassengerTypeEnum.ADULT, label: airTranslationKeys.ADULTS, subText: '', lowerLimit: 1, upperLimit: 6 },
  {
    backendKey: PassengerTypeEnum.CHILD,
    label: airTranslationKeys.CHILDREN,
    subText: airTranslationKeys.FLIGHTS_MULTI_PAX_CHILDREN_SELECT_AGE,
    lowerLimit: 0,
    upperLimit: 5,
  },
  {
    backendKey: PassengerTypeEnum.INFANT,
    label: airTranslationKeys.INFANTS_ON_SEAT,
    subText: airTranslationKeys.YOUNGER_THAN_TWO,
    lowerLimit: 0,
    upperLimit: 5,
  },
  {
    backendKey: PassengerTypeEnum.INFANT_ON_LAP,
    label: airTranslationKeys.INFANTS_ON_LAP,
    subText: airTranslationKeys.YOUNGER_THAN_TWO,
    lowerLimit: 0,
    upperLimit: 5,
  },
];

// TODO: rename this component back when it will be applied on web
export const passengerTypesWithInfantTypeMerged = (isInfantOnSeat: boolean): IPassengerType[] => [
  {
    backendKey: PassengerTypeEnum.ADULT,
    label: airTranslationKeys.ADULTS,
    subText: '',
    lowerLimit: 1,
    upperLimit: 6,
  },
  {
    backendKey: PassengerTypeEnum.CHILD,
    label: airTranslationKeys.CHILDREN,
    subText: airTranslationKeys.FLIGHTS_MULTI_PAX_CHILDREN_SELECT_AGE,
    lowerLimit: 0,
    upperLimit: 5,
  },
  isInfantOnSeat
    ? {
        backendKey: PassengerTypeEnum.INFANT,
        label: airTranslationKeys.INFANTS,
        subText: airTranslationKeys.YOUNGER_THAN_TWO,
        lowerLimit: 0,
        upperLimit: 5,
      }
    : {
        backendKey: PassengerTypeEnum.INFANT_ON_LAP,
        label: airTranslationKeys.INFANTS,
        subText: airTranslationKeys.YOUNGER_THAN_TWO,
        lowerLimit: 0,
        upperLimit: 5,
      },
];

export const defaultPaxInfo: IPaxInfo = {
  [PassengerTypeEnum.ADULT]: [{ paxType: PassengerTypeEnum.ADULT, paxNum: 1 }],
  [PassengerTypeEnum.CHILD]: [],
  [PassengerTypeEnum.INFANT]: [],
  [PassengerTypeEnum.INFANT_ON_LAP]: [],
};

export const defaultMultiPaxErrorState: IFlightsMultiPaxValidationState = {
  isValid: true,
  error: '',
};

export const alliances: { backendKey: Alliance; label: string }[] = [
  { backendKey: AllianceEnum.ONEWORLD, label: 'OneWorld' },
  { backendKey: AllianceEnum.SKYTEAM, label: 'SkyTeam' },
  { backendKey: AllianceEnum.STAR_ALLIANCE, label: 'Star Alliance' },
  { backendKey: AllianceEnum.VANILLA_ALLIANCE, label: 'Vanilla Alliance' },
  { backendKey: AllianceEnum.U_FLY_ALLIANCE, label: 'U Fly Alliance' },
  { backendKey: AllianceEnum.VALUE_ALLIANCE, label: 'Value Alliance' },
];

export const mealPreferences: { backendKey: MealType; label: string }[] = [
  { backendKey: MealTypeEnum.UNKNOWN_MEAL, label: 'No preference' },
  { backendKey: MealTypeEnum.AVML, label: 'Asian Vegetarian Meal' },
  { backendKey: MealTypeEnum.BLML, label: 'Bland Meal' },
  { backendKey: MealTypeEnum.CHML, label: 'Child Meal' },
  { backendKey: MealTypeEnum.DBML, label: 'Diabetic Meal' },
  { backendKey: MealTypeEnum.FPML, label: 'Fruit Platter' },
  { backendKey: MealTypeEnum.GFML, label: 'Gluten Intolerant' },
  { backendKey: MealTypeEnum.HNML, label: 'Hindu Meal' },
  { backendKey: MealTypeEnum.KSML, label: 'Kosher' },
  { backendKey: MealTypeEnum.LCML, label: 'Low Calorie' },
  { backendKey: MealTypeEnum.LFML, label: 'Low Fat Meal' },
  { backendKey: MealTypeEnum.LSML, label: 'Low Salt Meal' },
  { backendKey: MealTypeEnum.MOML, label: 'Muslim Meal' },
  { backendKey: MealTypeEnum.NLML, label: 'Low Lactose Meal' },
  { backendKey: MealTypeEnum.NSML, label: 'No Meal Required' },
  { backendKey: MealTypeEnum.PFML, label: 'Peanut Free Meal' },
  { backendKey: MealTypeEnum.RVML, label: 'Veg Raw Meal' },
  { backendKey: MealTypeEnum.SFML, label: 'Seafood Meal' },
  { backendKey: MealTypeEnum.SPML, label: 'Special Meal Request' },
  { backendKey: MealTypeEnum.VGML, label: 'Veg Vegan Meal' },
  { backendKey: MealTypeEnum.VLML, label: 'Veg Jain Meal' },
  { backendKey: MealTypeEnum.VOML, label: 'Veg Oriental Meal' },
];

export const cabinTypes: ICabinKeyLabelPair[] = [
  { backendKey: CabinEnum.ECONOMY, labelKey: defineMessage('Economy') },
  { backendKey: CabinEnum.PREMIUM_ECONOMY, labelKey: defineMessage('Premium Economy') },
  { backendKey: CabinEnum.BUSINESS, labelKey: defineMessage('Business') },
  { backendKey: CabinEnum.FIRST, labelKey: defineMessage('First') },
];

export const refundabilityTypes: IKeyLabelPair[] = [
  {
    backendKey: FilterChangeableFilter.NO_CHANGEABLE_FILTER,
    labelKey: airTranslationKeys.NO_CHANGEABLE_FILTER.toString(),
  },
  {
    backendKey: FilterChangeableFilter.REFUNDABLE_WITH_PENALTY,
    labelKey: defineMessage('Refundable (Extra Fee)'),
  },
  {
    backendKey: FilterChangeableFilter.REFUNDABLE_WITHOUT_PENALTY,
    labelKey: defineMessage('Refundable (Free)'),
  },
];

export enum StopTypesEnum {
  NON_STOP = 0,
  ONE_STOP_OR_LESS = 1,
  TWO_STOPS_OR_MORE = 2,
  NO_PREFERENCE = -1,
}

export const maxNumStopsTypes: IKeyLabelPair[] = [
  { backendKey: StopTypesEnum.NON_STOP, labelKey: airTranslationKeys.NON_STOP },
  { backendKey: StopTypesEnum.ONE_STOP_OR_LESS, labelKey: airTranslationKeys.ONE_STOP_OR_LESS },
  { backendKey: StopTypesEnum.TWO_STOPS_OR_MORE, labelKey: airTranslationKeys.TWO_STOPS_OR_LESS },
  { backendKey: StopTypesEnum.NO_PREFERENCE, labelKey: airTranslationKeys.NO_PREFERENCE },
];

export const numStopsType = {
  nonStop: {
    value: 0,
    label: defineMessage('Non-Stop'),
  },
  oneStepOrLess: {
    value: 1,
    label: defineMessage('1 Stop or Less'),
  },
  twoStopsOrLess: {
    value: 2,
    label: defineMessage('2 Stops or Less'),
  },
  noPreference: {
    value: -1,
    label: defineMessage('No Preference'),
  },
};

export const searchForm = {
  AIR_SEARCH_FORM_TRIP_TYPE_ONE_WAY: 1,
  AIR_SEARCH_FORM_TRIP_TYPE_ROUND_WAY: 0,
  AIR_SEARCH_FORM_TRIP_TYPE_MULTI_CITY: 2,
};

export const FlightBookingTypeToNumberTripTypeMap = {
  [FlightBookingType.ONE_WAY]: searchForm.AIR_SEARCH_FORM_TRIP_TYPE_ONE_WAY,
  [FlightBookingType.ROUND_TRIP]: searchForm.AIR_SEARCH_FORM_TRIP_TYPE_ROUND_WAY,
  [FlightBookingType.MULTI_CITY]: searchForm.AIR_SEARCH_FORM_TRIP_TYPE_MULTI_CITY,
};

export const flightsSortTypeOptionsMobile: IKeyLabelPairWithMetaInfo[] = [
  {
    backendKey: SortOptionSortByEnum.UNRECOGNIZED,
    labelKey: airTranslationKeys.RELEVANCE,
  },
  {
    backendKey: SortOptionSortByEnum.DURATION,
    labelKey: airTranslationKeys.DURATION,
  },
  {
    backendKey: SortOptionSortByEnum.PRICE,
    labelKey: airTranslationKeys.PRICE,
  },
  {
    backendKey: SortOptionSortByEnum.DEPARTURE_TIME,
    sortOrder: SortOptionSortOrderEnum.ASCENDING,
    labelKey: airTranslationKeys.EARLIEST_DEPARTURE,
  },
  {
    backendKey: SortOptionSortByEnum.DEPARTURE_TIME,
    sortOrder: SortOptionSortOrderEnum.DESCENDING,
    labelKey: airTranslationKeys.LATEST_DEPARTURE,
  },
  {
    backendKey: SortOptionSortByEnum.ARRIVAL_TIME,
    sortOrder: SortOptionSortOrderEnum.ASCENDING,
    labelKey: airTranslationKeys.EARLIEST_ARRIVAL,
  },
  {
    backendKey: SortOptionSortByEnum.ARRIVAL_TIME,
    sortOrder: SortOptionSortOrderEnum.DESCENDING,
    labelKey: airTranslationKeys.LATEST_ARRIVAL,
  },
  { backendKey: SortOptionSortByEnum.CO2_EMISSION, labelKey: airTranslationKeys.CO2_EMISSION },
];

export const flightsSortTypeOptions: IKeyLabelPairWithMetaInfo[] = [
  { backendKey: SortOptionSortByEnum.UNRECOGNIZED, labelKey: airTranslationKeys.RELEVANCE },
  { backendKey: SortOptionSortByEnum.PRICE, labelKey: airTranslationKeys.PRICE },
  { backendKey: SortOptionSortByEnum.DEPARTURE_TIME, labelKey: airTranslationKeys.DEPARTURE_TIME },
  { backendKey: SortOptionSortByEnum.ARRIVAL_TIME, labelKey: airTranslationKeys.ARRIVAL_TIME },
];

// Adding shelfNumber in metaInfo because the backendKey now in the array is not unique anymore
// Combining the backendKey with the shelfNumber will ensure uniqueness for price related sort options
export const ngsViewSortTypeOptions = [
  { backendKey: SortOptionSortByEnum.UNRECOGNIZED, labelKey: airTranslationKeys.RELEVANCE },
  { backendKey: SortOptionSortByEnum.DURATION, labelKey: airTranslationKeys.DURATION },
  { backendKey: SortOptionSortByEnum.PRICE, labelKey: airTranslationKeys.PRICE_BASE, metaInfo: { shelfNumber: 1 } },
  { backendKey: SortOptionSortByEnum.PRICE, labelKey: airTranslationKeys.PRICE_STANDARD, metaInfo: { shelfNumber: 2 } },
  { backendKey: SortOptionSortByEnum.PRICE, labelKey: airTranslationKeys.PRICE_ENHANCED, metaInfo: { shelfNumber: 3 } },
  {
    backendKey: SortOptionSortByEnum.PRICE,
    labelKey: airTranslationKeys.PRICE_PREMIUM,
    metaInfo: { shelfNumber: 4 },
  },
  { backendKey: SortOptionSortByEnum.PRICE, labelKey: airTranslationKeys.PRICE_LUXURY, metaInfo: { shelfNumber: 5 } },
  {
    backendKey: SortOptionSortByEnum.PRICE,
    labelKey: airTranslationKeys.PRICE_ULTRA_LUXURY,
    metaInfo: { shelfNumber: 6 },
  },
  { backendKey: SortOptionSortByEnum.DEPARTURE_TIME, labelKey: airTranslationKeys.DEPARTURE_TIME },
  { backendKey: SortOptionSortByEnum.ARRIVAL_TIME, labelKey: airTranslationKeys.ARRIVAL_TIME },
  { backendKey: SortOptionSortByEnum.CO2_EMISSION, labelKey: airTranslationKeys.CO2_EMISSION },
];

export const cabinViewSortTypeOptions = [
  { backendKey: SortOptionSortByEnum.UNRECOGNIZED, labelKey: airTranslationKeys.RELEVANCE },
  { backendKey: SortOptionSortByEnum.DURATION, labelKey: airTranslationKeys.DURATION },
  {
    backendKey: SortOptionSortByEnum.PRICE,
    labelKey: airTranslationKeys.PRICE_BASIC,
    metaInfo: { shelfNumber: 1 },
  },
  {
    backendKey: SortOptionSortByEnum.PRICE,
    labelKey: airTranslationKeys.PRICE_ECONOMY,
    metaInfo: { shelfNumber: 2 },
  },
  {
    backendKey: SortOptionSortByEnum.PRICE,
    labelKey: airTranslationKeys.PRICE_PREMIUM,
    metaInfo: { shelfNumber: 3 },
  },
  {
    backendKey: SortOptionSortByEnum.PRICE,
    labelKey: airTranslationKeys.PRICE_BUSINESS,
    metaInfo: { shelfNumber: 4 },
  },
  {
    backendKey: SortOptionSortByEnum.PRICE,
    labelKey: airTranslationKeys.PRICE_FIRST,
    metaInfo: { shelfNumber: 5 },
  },
  { backendKey: SortOptionSortByEnum.DEPARTURE_TIME, labelKey: airTranslationKeys.DEPARTURE_TIME },
  { backendKey: SortOptionSortByEnum.ARRIVAL_TIME, labelKey: airTranslationKeys.ARRIVAL_TIME },
  { backendKey: SortOptionSortByEnum.CO2_EMISSION, labelKey: airTranslationKeys.CO2_EMISSION },
];

export const tripTypeOptions: IKeyLabelPair[] = [
  {
    backendKey: searchForm.AIR_SEARCH_FORM_TRIP_TYPE_ONE_WAY,
    labelKey: airTranslationKeys.AIR_SEARCH_FORM_TRIP_TYPE_ONE_WAY,
  },
  {
    backendKey: searchForm.AIR_SEARCH_FORM_TRIP_TYPE_ROUND_WAY,
    labelKey: airTranslationKeys.AIR_SEARCH_FORM_TRIP_TYPE_ROUND_WAY,
  },
  {
    backendKey: searchForm.AIR_SEARCH_FORM_TRIP_TYPE_MULTI_CITY,
    labelKey: airTranslationKeys.AIR_SEARCH_FORM_TRIP_TYPE_MULTI_CITY,
  },
];

export const amenityTypes = {
  UNKNOWN_TYPE: 'UNKNOWN_TYPE',
  SEAT: 'SEAT',
  WIFI: 'WIFI',
  POWER: 'POWER',
  ENTERTAINMENT: 'ENTERTAINMENT',
  BEVERAGE: 'BEVERAGE',
  AIRCRAFT: 'AIRCRAFT',
  LAYOUT: 'LAYOUT',
  FRESHFOOD: 'FRESHFOOD',
};

export const seatAmenities = [
  { label: defineMessage('WIFI'), backendKey: ISeatAmenitiesPrefEnum.WIFI, icon: 'wifi' },
  { label: defineMessage('Flatbed'), backendKey: ISeatAmenitiesPrefEnum.FLAT_BED, icon: 'flatbed' },
  { label: defineMessage('In seat power'), backendKey: ISeatAmenitiesPrefEnum.IN_SEAT_POWER, icon: 'power' },
];

export const fareTypes = [
  { label: defineMessage('Changeable'), backendKey: FarePrefFareTypeEnum.CHANGEABLE, icon: 'changeable' },
  { label: defineMessage('Refundable'), backendKey: FarePrefFareTypeEnum.REFUNDABLE, icon: 'refundable' },
];

export const getDisplayTextAmenity = (amenity: Amenity): string => {
  if (amenity.aircraft) return amenity.aircraft.displayText;
  if (amenity.beverage) return amenity.beverage.displayText;
  if (amenity.entertainment) return amenity.entertainment.displayText;
  if (amenity.freshFood) return amenity.freshFood.displayText;
  if (amenity.layout) return amenity.layout.displayText;
  if (amenity.power) return amenity.power.displayText;
  if (amenity.seat) return amenity.seat.displayText;
  if (amenity.wifi) return amenity.wifi.displayText;
  return '';
};

export const utaTypes = {
  baggage: 'baggage',
  cancellation: 'cancellation',
  advanceChange: 'advanceChange',
  sameDayChange: 'sameDayChange',
  seatSelection: 'seatSelection',
  upgradeEligibility: 'upgradeEligibility',
  checkInPriority: 'checkInPriority',
  boardingPriority: 'boardingPriority',
  loungeAccess: 'loungeAccess',
  seatType: 'seatType',
  overheadBag: 'overheadBag',
  security: 'security',
};

export const serverFormat = {
  TIME: 'hh:mm:ss',
  DATE: 'YYYY-MM-DD',
};

export const defaultAirlinesFilterState: IFlightSearchAirlinesFilterState = Object.values(airlines).reduce(
  (acc: Record<string, boolean>, value: string) => ({ ...acc, [value]: false }),
  {},
);
const defaultCabin: FilterCabinFilter = {
  cabin: first(cabinTypes)?.backendKey ?? 0,
  type: FilterCabinFilterTypeEnum.AT_LEAST_ONE,
};
const defaultMaxStops: IFlightsFilterState['maxNumStops'] = -1;

export const defaultTimeRange = ['00:00', '00:00'];
export const emptySeat: ISeat = {
  seatNumber: '',
  columnInfo: { columnNumber: '', position: ColumnSectionColumnPosition.UNKNOWN_COLUMN_POSITION },
  facilities: [],
  isAvailable: false,
  isPreferred: false,
  isWheelchairAccessible: false,
  isBassinet: false,
  isExtraLegroom: false,
  limitations: [],
  location: [],
  price: {},
  type: 1,
  isLoyaltyLevelRequired: false,
};
export const firstLegDefaultTimeRange: ITimeRangeFilter = {
  legIndex: 0,
  departure: { min: defaultTimeRange[0], max: defaultTimeRange[1] },
  arrival: { min: defaultTimeRange[0], max: defaultTimeRange[1] },
};

export const defaultFlightFilters: Required<IFlightsFilterState> = {
  cabin: defaultCabin,
  timeRange: [firstLegDefaultTimeRange],
  maxNumStops: defaultMaxStops,
  alliance: { alliances: [], airlines: [] },
  policyFilter: { onlyInPolicy: false },
  changeable: FilterChangeableFilter.NO_CHANGEABLE_FILTER,
  fareCategoryFilter: { fareCategories: [] },
  multiTicketFilter: { hideMultiTicket: true },
  flightNumber: { flightNumber: undefined },
  unusedCreditFilter: { hideWithoutCredits: false },
};

export const defaultFlightsSortType = SortOptionSortByEnum.UNRECOGNIZED;

// TODO: Redundant const,can directly use Enum
export const thirdPartySource = ThirdPartySourceEnum;

export const emptySegmentLocation: IAirSuggestion = {
  type: null,
  code: '',
  name: '',
};

export const MAX_ALLOWED_FLIGHT_SEARCH_AIRPORTS = 5;
export const MAX_ALLOWED_FLIGHT_SEARCH_CITIES = 1;

export const flightsMaxSegments = 6;

export const defaultAirSearchParams = {
  currLegNumber: 0,
  lastLegRateOptionId: '',
  searchId: '',
  pageNumber: 1,
  emailAddress: undefined,
  phoneNumber: undefined,
  currentSeatSelectionFlightIndex: -1,
  numSelectedPoints: undefined,
};

export const availableNgsFareCategories = [
  FareCategoryNGSFareCategory.BASE,
  FareCategoryNGSFareCategory.STANDARD,
  FareCategoryNGSFareCategory.ENHANCED,
  FareCategoryNGSFareCategory.PREMIUM,
  FareCategoryNGSFareCategory.LUXURY,
  FareCategoryNGSFareCategory.ULTRA_LUXURY,
];

export const availableCabinFareCategories = [
  FareCategoryCabinViewFareCategory.BASIC,
  FareCategoryCabinViewFareCategory.ECONOMY,
  FareCategoryCabinViewFareCategory.PREMIUM_ECONOMY,
  FareCategoryCabinViewFareCategory.BUSINESS,
  FareCategoryCabinViewFareCategory.FIRST,
];

export const paxTranslationKeys = {
  [`${PassengerTypeEnum.ADULT}`]: airTranslationKeys.ADULT,
  [`${PassengerTypeEnum.CHILD}`]: airTranslationKeys.CHILD,
  [`${PassengerTypeEnum.INFANT}`]: airTranslationKeys.INFANT,
  [`${PassengerTypeEnum.INFANT_ON_LAP}`]: airTranslationKeys.INFANT_ON_LAP,
  [`${PassengerTypeEnum.YOUTH}`]: airTranslationKeys.YOUTH,
  [`${PassengerTypeEnum.SENIOR}`]: airTranslationKeys.SENIOR,
  [`${PassengerTypeEnum.UNKNOWN_PASSENGER_TYPE}`]: '',
};

export const paxTypeWithTranslations: Record<PassengerType, string> = {
  [PassengerType.UnknownPassengerType]: '',
  [PassengerType.Adult]: defineMessage('Adult'),
  [PassengerType.Child]: defineMessage('Child'),
  [PassengerType.Infant]: defineMessage('Infant'),
  [PassengerType.InfantOnLap]: defineMessage('Infant on lap'),
  [PassengerType.Youth]: defineMessage('Youth'),
  [PassengerType.Senior]: defineMessage('Senior'),
};

export const paxGroupTranslationKeys = {
  ...paxTranslationKeys,
  [`${PassengerTypeEnum.INFANT_ON_LAP}`]: airTranslationKeys.INFANT,
};

export const safetyAndCleaningPracticesOptions = [
  'cleaning',
  'mask',
  'temperatureCheck',
  'vaccine',
  'covidTesting',
  'blockedAdjacentSeats',
];

export const covidRestrictionsWebPageUrl =
  'https://www.iatatravelcentre.com/international-travel-document-news/1580226297.htm';

/** @deprecated */
export const airlinesWithNoSeatSelectionOption: Record<string, string> = {
  WN: 'WN',
  EK: 'EK',
};

export const airlineWithoutSeatSelection = ['WN', 'BR'];
export const airlineWithoutPreCheckoutSeatSelection = ['EK'];

export const allNonSeatSelectionAirlines = [...airlineWithoutSeatSelection, ...airlineWithoutPreCheckoutSeatSelection];

export const fareExpiredErrorCode = 'ITINERARY_FARE_EXPIRED';
export const flightUnavailableErrorCode = 'FLIGHT_UNAVAILABLE';
export const bookingRemarksErrorCode = 'BOOKING_REMARKS_ERROR';
export const bookingFinalizationError = 'BOOKING_FINALIZATION_ERROR';
export const flightInvalidLoyaltyErrorCode = 'INVALID_LOYALTY_INFO';
export const bookingFailedErrorCode = 'BOOKING_FAILED';
export const unableToModifyErrorCode = 'UNABLE_TO_MODIFY';
export const bookingPaymentFailedErrorCode = 'BOOKING_PAYMENT_FAILED';
export const requestResponseNotFound = 'REQUEST_RESPONSE_NOT_FOUND';
export const failedToFetchLoyaltyInfo = 'FAILED_TO_FETCH_LOYALTY_INFO';
export const qbrPassengerNameTooLong = 'QBR_PASSENGER_NAME_TOO_LONG';
export const minPassengerAgeAaAutoEnroll = 18;
export const maxAddressLengthAaAutoEnroll = 30;

export const contactSupportErrors = [bookingRemarksErrorCode, bookingFinalizationError];

export const flightTaxCodeTypes = [
  'XT',
  'AY',
  'XF',
  'ZP',
  'XA',
  'YQ',
  'YC',
  'XY',
  'US',
  'GB',
  'UB',
  'ROE',
  'NUC',
  'OB',
];

export const defaultFlightsFilters: IFlightsFilterState[] = [
  {
    cabin: {
      cabin: 1,
      type: 2,
    },
  },
  {
    timeRange: [
      {
        legIndex: 0,
        departure: {
          min: '00:00',
          max: '00:00',
        },
        arrival: {
          min: '00:00',
          max: '00:00',
        },
      },
    ],
  },
  {
    policyFilter: {
      onlyInPolicy: false,
    },
  },
  {
    changeable: 0,
  },
];

export const aaErrorStatusMap: Record<string, AaStatus> = {
  AA_LOYALTY_CUSTOMER_ALREADY_EXISTS: AaStatus.ALREADY_EXIST,
  AA_LOYALTY_UNKNOWN_ERROR: AaStatus.LOYALTY_ERROR,
  AMERICAN_AIRLINES_REST_CALL_FAILURE: AaStatus.CALL_ERROR,
  UNKNOWN_AMERICAN_AIRLINE_ERROR: AaStatus.UNKNOWN_ERROR,
};

const NO_PREFERENCE_CATEGORY = 'NO_PREFERENCE_CATEGORY';
const NO_PREFERENCE_OPTION: SSROption = {
  value: NO_PREFERENCE_CATEGORY,
  code: '',
  label: 'No preference',
};
export const SSR_OSI_CONFIG = {
  CUSTOM_MEAL_CHAR_LIMIT: 80,
  CUSTOM_SSR_CHAR_LIMIT: 80,
  OSI_CHAR_LIMIT: 80,
  WHEELCHAIR_CATEGORY: 'WHEELCHAIR_CATEGORY',
  NO_PREFERENCE_CATEGORY,
  NO_PREFERENCE_OPTION,
  SAVE_DEBOUNCE_INTERVAL_MS: 1500,
};

export const FLIGHTS_TRAVELER_INFO_WARNING = defineCommonMessage(
  "The name entered below must exactly match the name on the traveler's government-issued ID that they will use for the trip (with the exception of special characters and accents).",
);

export const exchangeViaSupportBannerTextsForTraveler: Record<ExchangeNotSupportedReason, ICommonI18nMessage<{}>> = {
  [ExchangeNotSupportedReason.ApprovalPending]: defineCommonMessage(
    'This flight can not be exchanged until it has been approved.',
  ),
  [ExchangeNotSupportedReason.ApprovalDenied]: defineCommonMessage(
    'This flight was not approved by your organization and therefore can not be exchanged.',
  ),
  [ExchangeNotSupportedReason.ExchangeNotSupportedReasonUnknown]: defineCommonMessage(
    'We are unable to exchange your flight online as some required information about this ticket is missing. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [ExchangeNotSupportedReason.FlightCheckedIn]: defineCommonMessage(
    'You are currently checked in for this flight.  If you wish to make a change, please contact the carrier or check their website to change your status.  Once you are no longer checked-in, changes can be made. ',
  ),
  [ExchangeNotSupportedReason.NonExchangeable]: defineCommonMessage(
    'This ticket is non-exchangeable. Please contact the airline for assistance.',
  ),
  [ExchangeNotSupportedReason.AutomatedExchangeNotSupported]: defineCommonMessage(
    'This airline does not support online exchanges. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [ExchangeNotSupportedReason.MultiPaxExchangeNotSupported]: defineCommonMessage(
    'We do not yet support online exchanges for multiple passengers. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [ExchangeNotSupportedReason.ExchangeNotSupportedForMarkupFares]: defineCommonMessage(
    'This ticket is a special fare that is only available to Spotnana and we are unable to exchange it online. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [ExchangeNotSupportedReason.RewardsExchangeNotEnabled]: defineCommonMessage(
    'We are unable to exchange your flight online as it was purchased using points. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [ExchangeNotSupportedReason.FareRulesUnknown]: defineCommonMessage(
    'We are unable to exchange your flight online as some required information about this ticket is missing. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [ExchangeNotSupportedReason.AllFlightsUsed]: defineCommonMessage(
    'This ticket can not be exchanged as the flight has already departed. Please contact the airline for assistance.',
  ),
  [ExchangeNotSupportedReason.MissingPq]: defineCommonMessage(
    'We are unable to exchange your flight online as some required information about this ticket is missing. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [ExchangeNotSupportedReason.AirlineTicket]: defineCommonMessage(
    'This ticket can not be exchanged your flight online as it is an airline ticket. Please contact the airline directly for assistance.',
  ),
  [ExchangeNotSupportedReason.AirlineControl]: defineCommonMessage(
    'This ticket can not be exchanged your flight online as it is under the airlines control which can happen if modifications have been made outside of the Spotnana system. Please contact the airline directly for assistance.',
  ),
  [ExchangeNotSupportedReason.SourceNotImplemented]: defineCommonMessage(
    'We do not yet support online exchanges for tickets from this carrier. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [ExchangeNotSupportedReason.ScheduleChangePending]: defineCommonMessage(
    'The airline has made a change to your scheduled itinerary and this must be resolved before an exchange can be made. If you do not wish to accept this schedule change, please contact an agent and we will be happy to assist you.',
  ),
};

export const exchangeViaSupportBannerTextsForAgent: Record<ExchangeNotSupportedReason, ICommonI18nMessage<{}>> = {
  [ExchangeNotSupportedReason.ApprovalPending]: defineCommonMessage(
    'Ticket is pending approval from this travelers approver. Until it has been approved it can not be exchanged. ',
  ),
  [ExchangeNotSupportedReason.ApprovalDenied]: defineCommonMessage(
    'Ticket was denied by the travelers approver and therefore can not be exchanged. ',
  ),
  [ExchangeNotSupportedReason.ExchangeNotSupportedReasonUnknown]: defineCommonMessage(
    'We are unable to exchange this flight online for an unknown reason, please exchange using native tools and report the issue to the air team.',
  ),
  [ExchangeNotSupportedReason.FlightCheckedIn]: defineCommonMessage(
    'Flight is already checked in, please direct traveler to the airline website.',
  ),
  [ExchangeNotSupportedReason.NonExchangeable]: defineCommonMessage(
    'Ticket is non-exchangeable, please direct traveler to the airline website.',
  ),
  [ExchangeNotSupportedReason.AutomatedExchangeNotSupported]: defineCommonMessage(
    'Automated exchange via API is not supported for this ticket, please exchange using native tools.',
  ),
  [ExchangeNotSupportedReason.MultiPaxExchangeNotSupported]: defineCommonMessage(
    'Automated exchange for multi-pax is not supported, please exchange using native tools.',
  ),
  [ExchangeNotSupportedReason.ExchangeNotSupportedForMarkupFares]: defineCommonMessage(
    'This is a markup fare and can only be exchanged using native tools. ',
  ),
  [ExchangeNotSupportedReason.RewardsExchangeNotEnabled]: defineCommonMessage(
    'Automated exchange for rewards is not supported, please exchange using native tools. ',
  ),
  [ExchangeNotSupportedReason.FareRulesUnknown]: defineCommonMessage(
    'The fare rules of this ticket are unknown, please exchange using native tools.',
  ),
  [ExchangeNotSupportedReason.AllFlightsUsed]: defineCommonMessage(
    'Ticket is already flown, please direct traveler to the airline website.',
  ),
  [ExchangeNotSupportedReason.MissingPq]: defineCommonMessage(
    'The PQ for this ticket is missing, please exchange using native tools. ',
  ),
  [ExchangeNotSupportedReason.AirlineTicket]: defineCommonMessage(
    'Ticket is under airline control, please direct traveler to the airline website.',
  ),
  [ExchangeNotSupportedReason.AirlineControl]: defineCommonMessage(
    'Ticket is under airline control, please direct traveler to the airline website.',
  ),
  [ExchangeNotSupportedReason.SourceNotImplemented]: defineCommonMessage(
    "We don't support exchanges for this content source yet, please exchange using native tools.",
  ),
  [ExchangeNotSupportedReason.ScheduleChangePending]: defineCommonMessage(
    'Ticket is pending a schedule change, please assist traveler in accepting this change or finding an alternative flight. ',
  ),
};

export const cancelViaSupportBannerTextsForTraveler: Record<AirCancellationNotSupportedReason, string> = {
  [AirCancellationNotSupportedReason.PartialLegFlown]: defineMessage(
    'We are unable to cancel your flight online as some segments have already been flown. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [AirCancellationNotSupportedReason.ExchangedTicket]: defineMessage(
    'We are unable to cancel your flight online as it has previously been exchanged. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [AirCancellationNotSupportedReason.AutomatedExchangeNotSupported]: defineMessage(
    'This airline does not support online cancellations for credit. Please submit a cancellation request and our agents will process this for you in the next few hours.',
  ),
  [AirCancellationNotSupportedReason.AutomatedRefundNotSupported]: defineMessage(
    'This airline does not support online cancellations. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [AirCancellationNotSupportedReason.NonRefundableButExchangeable]: defineMessage(
    'We are unable to refund your flight online as it is not supported by this airline. Please submit a cancellation request and our agents will process this for you in the next few hours.',
  ),
  [AirCancellationNotSupportedReason.NonRefundableNonExchangeable]: defineMessage(
    'This ticket is non-refundable and not eligible for credits. Please contact the airline for assistance.',
  ),
  [AirCancellationNotSupportedReason.FareRulesUnknown]: defineMessage(
    'We are unable to cancel your flight online as some required information about this ticket is missing. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [AirCancellationNotSupportedReason.FlightCheckedIn]: defineMessage(
    'This ticket can not be canceled as you are already checked in. Please contact the airline for assistance. ',
  ),
  [AirCancellationNotSupportedReason.FareBreakupUnknown]: defineMessage(
    'We are unable to cancel your flight online as we have not received an accurate breakdown of your refund from the airline. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [AirCancellationNotSupportedReason.CurrentTimeCloseToFlightDeparture]: defineMessage(
    'This flight is too close to departure to be canceled online. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [AirCancellationNotSupportedReason.AllFlightsUsed]: defineMessage(
    'This ticket can not be canceled as the flight has already departed. Please contact the airline for assistance. ',
  ),
  [AirCancellationNotSupportedReason.NonVoidableAncillaryTicket]: defineMessage(
    'We are unable to cancel your flight online as you have purchased an ancillary. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
  [AirCancellationNotSupportedReason.SupplierCancellationNotImplemented]: defineMessage(
    'We do not yet support online cancellation for tickets from this carrier. Please contact a Spotnana agent who will be happy to assist you.',
  ),
  [AirCancellationNotSupportedReason.OutsideBooking]: defineMessage(
    'We are unable to cancel your flight online as the booking was made or updated outside of the Spotnana booking tool. Please contact a Spotnana agent who will be happy to assist you.',
  ),
  [AirCancellationNotSupportedReason.UpgradeRequested]: defineMessage(
    'We are unable to cancel your flight online as you have a pending upgrade. Please contact a Spotnana agent and we will be happy to assist you.',
  ),
};

export const cancelViaSupportBannerTextsForAgent: Record<AirCancellationNotSupportedReason, string> = {
  [AirCancellationNotSupportedReason.PartialLegFlown]: defineMessage(
    'Ticket is partially flown, please cancel using native tools.',
  ),
  [AirCancellationNotSupportedReason.ExchangedTicket]: defineMessage(
    'Ticket has been previously exchanged, please cancel using native tools.',
  ),
  [AirCancellationNotSupportedReason.AutomatedExchangeNotSupported]: defineMessage(
    "Automated exchange not supported so credit can't be generated, please cancel using native tools.",
  ),
  [AirCancellationNotSupportedReason.AutomatedRefundNotSupported]: defineMessage(
    'Automated refund not supported, please cancel using native tools.',
  ),
  [AirCancellationNotSupportedReason.NonRefundableButExchangeable]: defineMessage(
    'This airline does not support online cancellations for credit. Please submit a cancellation request and our agents will process this for you in the next few hours.',
  ),
  [AirCancellationNotSupportedReason.NonRefundableNonExchangeable]: defineMessage(
    'We are unable to cancel this flight for credit though exchange is possible, please cancel using native tools.',
  ),
  [AirCancellationNotSupportedReason.FareRulesUnknown]: defineMessage(
    'Fare rules unknown, please cancel using native tools.',
  ),
  [AirCancellationNotSupportedReason.FlightCheckedIn]: defineMessage(
    'Passenger checked in, please direct traveler to the airline website.',
  ),
  [AirCancellationNotSupportedReason.FareBreakupUnknown]: defineMessage(
    'Fare breakup for this ticket is unknown, please cancel using native tools.',
  ),
  [AirCancellationNotSupportedReason.CurrentTimeCloseToFlightDeparture]: defineMessage(
    'Flight too close to departure so we were unable to generate a refund request, please cancel using native tools.',
  ),
  [AirCancellationNotSupportedReason.AllFlightsUsed]: defineMessage(
    'Ticket is flown, please direct traveler to the airline website.',
  ),
  [AirCancellationNotSupportedReason.NonVoidableAncillaryTicket]: defineMessage(
    'This ticket has a non-voidable ancillary associated with it which is blocking our automated refund, please cancel using native tools.',
  ),
  [AirCancellationNotSupportedReason.SupplierCancellationNotImplemented]: defineMessage(
    "We don't yet support online cancellation for this channel, please cancel at the source.",
  ),
  [AirCancellationNotSupportedReason.OutsideBooking]: defineMessage('Outside booking, please cancel at the source.'),
  [AirCancellationNotSupportedReason.UpgradeRequested]: defineMessage(
    'There is a pending upgrade blocking our automated cancellation, please cancel using native tools.',
  ),
};

export const CARBON_EMISSION_DELTA_THRESHOLD = 5;
