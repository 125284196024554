import isEmpty from 'lodash/isEmpty';
import type { Action } from '../../../types/api/v1/obt/policy/action';
import type { PolicyInfo, PolicyInfoRuleResultInfo } from '../../../types/api/v1/obt/common/policy_info';

export type ActionType = keyof Action;

export function isActionTypeInRuleResultsInfos(
  ruleResultInfos: PolicyInfoRuleResultInfo[],
  actionType: ActionType,
): boolean {
  return ruleResultInfos.some((ruleResultInfo) => {
    return ruleResultInfo.actions.some((action) => action[actionType] !== undefined);
  });
}

/**
 * TODO: Verify with Mayank if policyInfos is an array of PolicyInfo objects that has same length as number of legs
 * or if we can just 0 index into it. If it's the latter, we can remove the legNumber parameter.
 * Currently only receiving one policyInfo object regardless of number of legs
 * */
export function isFareUpgradeAvailableForPolicyInfo(policyInfos: PolicyInfo[], legNumber = 0): boolean {
  if (isEmpty(policyInfos)) {
    return false;
  }
  const { ruleResultInfos } = policyInfos[legNumber];
  return isActionTypeInRuleResultsInfos(ruleResultInfos, 'splitPayment');
}
